import './HowCanWeHelpPage.scss';
import Button from '../../Components/Button/Button';
import PageHead from '../../Components/misc/PageHead';
import ContactForm from '../../Components/ContactForm/ContactForm';
import React, { useState, useRef, useCallback, useEffect } from 'react';
import ImageContainer from '../../Components/ImageContainers/ImageContainer';
import LoadingContainer from '../../Components/LoadingContainers/LoadingContainer';
import { useLoadingRunContext, useToggleLoadingRunContext } from '../../Context/loadingContext';
import { initialiseSmoothScroll, uninitialisedSmoothScroll } from '../../Utilities/Functions/handleSmoothScroll';
import { useSearchParams } from "react-router-dom";

const whatsAppIcon: string = require('../../Images/whatsApp_icon.svg').default;
const emailIcon: string = require('../../Images/email_icon.svg').default;
const locationIcon: string = require('../../Images/location_icon.svg').default;
const pageTitle: string = 'Get in touch | addmustard | Brighton & London';
const pageDescription: string =
  'Contact us to discuss how we can help you join-up brand, marketing and technology and deliver growth for your entrepreneurial business.';
const pageCanonicalHref: string = 'https://www.addmustard.com/how-can-we-help/';
const pageThemeColor: string = '#13121C';
const pageRobots: string = 'all';

interface elementCoordinates {
  top: number;
  left: number;
  bottom: number;
  right: number;
}

const getElementCoordinates: (element: HTMLElement) => elementCoordinates = element => {
  const elementTop: number = window.scrollY + element.getBoundingClientRect().top;
  const elementBottom: number = elementTop + element.clientHeight;
  const elementLeft: number = window.scrollX + element.getBoundingClientRect().left;
  const elementRight: number = elementLeft + element.clientWidth;
  const elementCoordinates: elementCoordinates = {
    top: elementTop,
    left: elementLeft,
    bottom: elementBottom,
    right: elementRight,
  };
  return elementCoordinates;
};

const HowCanWeHelpPage: React.FC = () => {
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [pageOpacity, setPageOpacity] = useState<number>(0);
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false);
  const [emailOpen, setEmailOpen] = useState<boolean>(false);
  const [locationsOpen, setLocationsOpen] = useState<boolean>(false);
  const [formContainerHeight, setFormContainerHeight] = useState<number>(0);
  const [locationDropdownHeight, setLocationDropdownHeight] = useState<number>(0);

  const formScrollTarget: React.MutableRefObject<any> = useRef(null);
  const locationDropdownRef: React.MutableRefObject<any> = useRef(null);

  const loadingRun: boolean = useLoadingRunContext();
  const toggleLoadingRun: () => void = useToggleLoadingRunContext();

  const handleFormContainerHeight: () => void = useCallback(() => {
    const form: any = formScrollTarget.current;
    const innerElement: any = form.children[0] || null;
    const formHeight: number = !emailOpen && innerElement ? innerElement.clientHeight : 0;

    setFormContainerHeight(formHeight);
  }, [emailOpen]);

  const handleLocationDropdownHeight: () => void = () => {
    const locationDropdown: any = locationDropdownRef.current;
    const innerElement: any = locationDropdown.children[0] || null;
    const locationDropdownHeight: number = !locationsOpen && innerElement ? innerElement.clientHeight : 0;

    setLocationDropdownHeight(locationDropdownHeight);
  };

  const scrollToForm: () => void = () => {
    const form: any = formScrollTarget.current;
    const formPositionX: elementCoordinates = getElementCoordinates(form);

    window.scrollBy({
      top: formPositionX.top * 1.2,
      left: 0,
      behavior: 'smooth',
    });
  };

  const handleEmailButtonClick: () => void = useCallback(() => {
    if (!emailOpen) scrollToForm();
    handleFormContainerHeight();
    setEmailOpen(!emailOpen);
    setLocationsOpen(false);
    setLocationDropdownHeight(0);
  }, [emailOpen, handleFormContainerHeight]);

  const handleLocationButtonClick: () => void = () => {
    handleLocationDropdownHeight();
    setLocationsOpen(!locationsOpen);
    setEmailOpen(false);
    setFormContainerHeight(0);
  };

  const contactSectionImage: any = '../images/howCanWeHelpImage.webp';
  const contactButtons: Array<any> = [
    {
      buttonHasTilt: true,
      buttonShowTiltOn: 'always',
      buttonTiltColor: '#FFFFFF',
      buttonTiltMaxRotation: 3,
      buttonTiltMaxSkew: 3,
      buttonCustomClass: 'clrWhite contact-whatsapp',
      buttonLinkType: 'external',
      buttonLinkHref: 'https://wa.me/447457404143',
      buttonContent: 'WhatsApp',
      buttonIcon: whatsAppIcon,
      buttonOpensInNewTab: true,
    },
    {
      buttonHasTilt: true,
      buttonShowTiltOn: 'always',
      buttonTiltColor: '#FFFFFF',
      buttonTiltMaxRotation: 3,
      buttonTiltMaxSkew: 3,
      buttonCustomClass: 'clrWhite',
      buttonLinkType: 'function',
      buttonLinkHref: '',
      buttonContent: 'Email',
      buttonIcon: emailIcon,
      buttonLinkFunction: () => {
        handleEmailButtonClick();
      },
    },
    {
      buttonHasTilt: true,
      buttonShowTiltOn: 'always',
      buttonTiltColor: '#FFFFFF',
      buttonTiltMaxRotation: 3,
      buttonTiltMaxSkew: 3,
      buttonCustomClass: 'clrWhite',
      buttonLinkType: 'function',
      buttonLinkHref: '',
      buttonContent: 'Location',
      buttonIcon: locationIcon,
      buttonLinkFunction: () => {
        handleLocationButtonClick();
      },
    },
  ];

  var contactSectionClassName: string = 'ContactSection';
  contactSectionClassName += emailOpen ? ' emailOpen' : '';
  contactSectionClassName += locationsOpen ? ' locationOpen' : '';

  const [searchParams, setSearchParams] = useSearchParams();
  const openEmail = searchParams.get("openEmail");
  
  useEffect(() => {
    const setPage: () => void = () => {
      setPageLoaded(true);
      setPageOpacity(1);
      window.scrollTo({ top: 0, left: 0, behavior: 'auto' });    
    
      if(openEmail == 'true'){
          // alert('TESt');
          if (!emailOpen){
            scrollToForm();
            handleFormContainerHeight();
            setEmailOpen(!emailOpen);
            setLocationsOpen(false);
            setLocationDropdownHeight(0);
          }
      }

    };

    if (loadingRun) {
      setPage();
      return;
    }

    var stateCheck = setInterval(() => {
      if (document.readyState === 'complete') {
        clearInterval(stateCheck);
        setPage();
        setTimeout(() => {
          setLoadingComplete(true);
          toggleLoadingRun();
        }, 300);
      }
    }, 1700);

    return () => {};
  }, [loadingRun, toggleLoadingRun]);

  useEffect(() => {
    const stickyHeader = document.getElementById('stickyHeader');

    if (!stickyHeader) return;

    stickyHeader.classList.remove('active');
  }, []);

  useEffect(() => {
    const smoothScroller = initialiseSmoothScroll();

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);

    return () => {
      if (smoothScroller) uninitialisedSmoothScroll(smoothScroller);
    };
  }, []);




  return (
    <>
      <PageHead
        canonical={pageCanonicalHref}
        description={pageDescription}
        robots={pageRobots}
        themeColor={pageThemeColor}
        title={pageTitle}
      />

      {loadingComplete || loadingRun ? <></> : <LoadingContainer loadingComplete={pageLoaded} />}

      <div className="pageBackground HowCanWeHelpPage"></div>

      <div id="HowCanWeHelpPage" style={{ opacity: pageOpacity, transition: 'opacity 300ms ease' }}>
        <div className="outer">
          <div className="inner">
            <div
              id="howCanWeHelpVariableSpacerOne"
              className="Spacer"
              style={{ height: '250px', position: 'relative' }}
            >
              <div
                className="inner"
                style={{
                  height: '110%',
                  width: '300vw',
                  position: 'absolute',
                  top: 0,
                  left: '-100vw',
                  backgroundColor: '#13121C',
                  maxWidth: 'unset',
                }}
              />
            </div>

            <section className={contactSectionClassName}>
              <div className="inner">
                <div className="left">
                  <div className="inner">
                    <ImageContainer data={{ imageSource: contactSectionImage, imageIsLazy: true }} />

                    <h1>
                      <Button
                        data={{
                          buttonHasTilt: true,
                          buttonShowTiltOn: 'always',
                          buttonTiltColor: '#FAD762',
                          buttonTiltMaxRotation: 4,
                          buttonTiltMaxSkew: 4,
                          buttonCustomClass: 'font-h1',
                          buttonLinkType: 'none',
                          buttonContent: 'How can we',
                        }}
                      />

                      <Button
                        data={{
                          buttonHasTilt: true,
                          buttonShowTiltOn: 'always',
                          buttonTiltColor: '#FAD762',
                          buttonTiltMaxRotation: 4,
                          buttonTiltMaxSkew: 4,
                          buttonCustomClass: 'font-h1',
                          buttonLinkType: 'none',
                          buttonContent: 'help?',
                        }}
                      />
                    </h1>
                  </div>
                </div>

                <div className="right">
                  <div className="outer">
                    <div className="inner">
                      {contactButtons.map((item: any, key: number) => {
                        if (key === 2) {
                          return (
                            <div key={key}>
                              <Button data={item} />

                              <div
                                ref={locationDropdownRef}
                                id="locationDropDown"
                                className={locationsOpen ? 'locations active' : 'locations'}
                                style={{ height: `${locationDropdownHeight}px`, overflow: 'hidden' }}
                              >
                                <ul>
                                  <li>
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href="https://www.google.com/maps/place/addmustard+Brighton/@50.8236889,-0.1422804,17z/data=!3m2!4b1!5s0x487bd54190bd8689:0x12fb742847d22356!4m6!3m5!1s0x4875850ac3a94dab:0xbc03888417118491!8m2!3d50.8236855!4d-0.1397055!16s%2Fg%2F11b7qt9dr4"
                                    >
                                      Brighton
                                    </a>
                                  </li>

                                  <li>
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href="https://www.google.com/maps/place/addmustard+London/@51.5123479,-0.1367066,17z/data=!3m1!4b1!4m6!3m5!1s0x487605b3be77ea1f:0x2c44a9dc3ae90d0f!8m2!3d51.5123446!4d-0.1341317!16s%2Fg%2F11rvjkf_7k"
                                    >
                                      Soho
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          );
                        }
                        return (
                          <div key={key}>
                            <Button data={item} />
                          </div>
                        );
                      })}
                    </div>

                    <div
                      ref={formScrollTarget}
                      id="contactFormTarget"
                      style={{ height: `${formContainerHeight}px`, overflow: 'hidden' }}
                    >
                      <ContactForm />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="Spacer" style={{ height: '0px', position: 'relative' }}>
              <div
                className="inner"
                style={{
                  height: '110%',
                  width: '300vw',
                  position: 'absolute',
                  top: 0,
                  left: '-100vw',
                  backgroundColor: '#13121C',
                  maxWidth: 'unset',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowCanWeHelpPage;
