import Button from '../Button/Button';
import React, { useState, useRef } from 'react';
import LoadingButton from '../Button/LoadingButton';
import sendContactFormEmail from '../../Utilities/Functions/sendContactFormEmail';
import { sendContactFormEmailProps } from '../../Utilities/Functions/sendContactFormEmail';
import {
  getIsValidName,
  getIsValidEmail,
  getIsValidNumber,
  getIsValidCompany,
  getIsValidComment,
} from '../../Utilities/Functions/formValidationFunctions';
import SubmitButton from '../Button/SubmitButton';

const ContactForm: React.FC = () => {
  const initialCommentValue: string =
    'Tell us a little about you. And your business. And how we can help, please.';

  const [fullNameValue, setFullNameValue] = useState<string>('');
  const [fullNameHasError, setFullNameHasError] = useState<boolean>(false);
  const [emailValue, setEmailValue] = useState<string>('');
  const [emailHasError, setEmailHasError] = useState<boolean>(false);
  const [phoneValue, setPhoneValue] = useState<string>('');
  const [phoneHasError, setPhoneHasError] = useState<boolean>(false);
  const [companyValue, setCompanyValue] = useState<string>('');
  const [companyHasError, setCompanyHasError] = useState<boolean>(false);
  const [commentValue, setCommentValue] = useState<string>(initialCommentValue);
  const [commentHasError, setCommentHasError] = useState<boolean>(false);
  const [hearValue, setHearValue] = useState<string>('');
  const [hearHasError, setHearHasError] = useState<boolean>(false);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [formSent, setFormSent] = useState<boolean>(false);
  const [formSentSuccessful, setFormSentSuccessful] = useState<boolean>(false);
  const [commentInitialValue, setCommentInitialValue] = useState<boolean>(true);
  const nameInputRef: React.MutableRefObject<any> = useRef(null);
  const emailInputRef: React.MutableRefObject<any> = useRef(null);
  const phoneInputRef: React.MutableRefObject<any> = useRef(null);
  const companyInputRef: React.MutableRefObject<any> = useRef(null);
  const commentInputRef: React.MutableRefObject<any> = useRef(null);
  const hearInputRef: React.MutableRefObject<any> = useRef(null);
  const formRef: React.MutableRefObject<any> = useRef(null);

  const clearDefaultComment: () => void = () => {
    if (commentInputRef.current.value === initialCommentValue) {
      setCommentValue('');
    }
  };
  const validationSuccess: () => void = () => {
    setFormLoading(false);
    setFormSent(true);
    setFormSentSuccessful(true);
  };
  const validationError: () => void = () => {
    setFormLoading(false);
    setFormSent(true);
    setFormSentSuccessful(false);
  };
  const validateForm: () => void = () => {
    setFormLoading(true);

    const isValidName: boolean = getIsValidName(fullNameValue);
    const isValidEmail: boolean = getIsValidEmail(emailValue);
    const isValidPhone: boolean = getIsValidNumber(phoneValue);
    const isValidCompany: boolean = getIsValidCompany(companyValue);
    const isValidComment: boolean = getIsValidComment(commentValue, initialCommentValue);
    var errorOccurred: boolean = false;

    if (!isValidName) {
      setFullNameHasError(true);
      errorOccurred = true;
    }

    if (!isValidEmail) {
      setEmailHasError(true);
      errorOccurred = true;
    }

    if (!isValidPhone) {
      setPhoneHasError(true);
      errorOccurred = true;
    }

    if (!isValidCompany) {
      setCompanyHasError(true);
      errorOccurred = true;
    }

    if (!isValidComment) {
      setCommentHasError(true);
      errorOccurred = true;
    }

    if (errorOccurred) {
      resetForm();
    }

    if (isValidName && isValidEmail && isValidPhone && isValidCompany && isValidComment) {
      const sendContactFormEmailData: sendContactFormEmailProps = {
        form: formRef.current,
        successCallback: validationSuccess,
        errorCallback: validationError,
      };

      sendContactFormEmail(sendContactFormEmailData);
    }

    setFormLoading(false);
  };
  const resetForm: () => void = () => {
    setFormLoading(false);
    setFormSent(false);
    setFormSentSuccessful(false);
  };

  return (
    <div className="ContactForm">
      <p>Test</p>
      {formSent ? (
        formSentSuccessful ? (
          <div className="successMessage">
            <div className="inner">
              <Button
                data={{
                  buttonHasTilt: true,
                  buttonShowTiltOn: 'always',
                  buttonTiltColor: '#FAD762',
                  buttonTiltMaxRotation: 2,
                  buttonTiltMaxSkew: 2,
                  buttonCustomClass: 'font-h6 clrBlack cursor-default',
                  buttonLinkType: 'none',
                  buttonContent: 'Thanks for reaching out.',
                }}
              />

              <p className="clrWhite p-small">
                An addmustard expert will be in touch very soon. Lucky you!
                <br />
                <br />
                In the meantime, feel free to check out our latest (and greatest) articles on{' '}
                <a className={'clrMustard p-small'} href={'https://www.addmustard.com/blog/'}>
                  our blog
                </a>
                .
              </p>
            </div>
          </div>
        ) : (
          <div className="errorMessage">
            <div className="inner">
              <p className="font-h6 clrWhite">
                Helpful error message:{' '}
                <Button
                  data={{
                    buttonHasTilt: true,
                    buttonShowTiltOn: 'always',
                    buttonTiltColor: '#FAD762',
                    buttonTiltMaxRotation: 2,
                    buttonTiltMaxSkew: 2,
                    buttonCustomClass: 'font-h6 clrBlack cursor-default',
                    buttonLinkType: 'none',
                    buttonContent: 'something went wrong',
                  }}
                />
              </p>

              <p className="clrWhite p-small">
                Apparently, it’s all your fault (actually, it’s not).
                <br />
                <br />
                <span
                  className="clrMustard cursor-pointer p-small"
                  onClick={() => {
                    resetForm();
                  }}
                >
                  Please try again
                </span>{' '}
                and hopefully the connection will be better. And so will your day.
              </p>
            </div>
          </div>
        )
      ) : (
        <></>
      )}

      <form
        ref={formRef}
        id="contactForm"
        style={formSent ? { display: 'none' } : {}}
        onSubmit={(event: any) => {
          event.preventDefault();
          validateForm();
        }}
      >
        <div id="fullName">
          <input
            ref={nameInputRef}
            className={fullNameHasError ? 'error' : ''}
            name="fullName"
            type="text"
            placeholder="Full name"
            value={fullNameValue}
            onChange={() => {
              setFullNameValue(nameInputRef.current.value);
              setFullNameHasError(false);
            }}
          />

          <span className="inputError font-xSmall">Full name field required</span>
        </div>

        <div id="email">
          <input
            ref={emailInputRef}
            className={emailHasError ? 'error' : ''}
            name="email"
            type="email"
            placeholder="Email"
            value={emailValue}
            onChange={() => {
              setEmailValue(emailInputRef.current.value);
              setEmailHasError(false);
            }}
          />

          <span className="inputError font-xSmall">Email field required</span>
        </div>

        <div id="phone">
          <input
            ref={phoneInputRef}
            className={phoneHasError ? 'error' : ''}
            name="phone"
            type="tel"
            placeholder="Phone"
            value={phoneValue}
            onChange={() => {
              setPhoneValue(phoneInputRef.current.value);
              setPhoneHasError(false);
            }}
          />

          <span className="inputError font-xSmall">Phone field required</span>
        </div>

        <div id="company">
          <input
            ref={companyInputRef}
            className={companyHasError ? 'error' : ''}
            name="company"
            type="text"
            placeholder="Company name"
            value={companyValue}
            onChange={() => {
              setCompanyValue(companyInputRef.current.value);
              setCompanyHasError(false);
            }}
          />

          <span className="inputError font-xSmall">Company name field required</span>
        </div>

        <div id="comment">
          <textarea
            ref={commentInputRef}
            className={`${commentHasError ? 'error' : ''} ${commentInitialValue ? 'initial' : ''}`}
            name="comment"
            value={commentValue}
            onChange={() => {
              setCommentValue(commentInputRef.current.value);
              setCommentHasError(false);
              setCommentInitialValue(false);
            }}
            onFocus={() => {
              clearDefaultComment();
            }}
          ></textarea>

          <span className="inputError font-xSmall">Overview field required</span>
        </div>

        <div id="hear">
          <input
            ref={hearInputRef}
            className={hearHasError ? 'error' : ''}
            name="hear"
            type="text"
            placeholder="How did you hear about us?"
            value={hearValue}
            onChange={() => {
              setHearValue(hearInputRef.current.value);
              setHearHasError(false);
            }}
          />
        </div>

        {formLoading ? (
          <LoadingButton
            data={{
              buttonHasTilt: true,
              buttonShowTiltOn: 'always',
              buttonTiltColor: '#FAD762',
              buttonTiltMaxRotation: 2,
              buttonTiltMaxSkew: 2,
            }}
          />
        ) : (
          <SubmitButton
            data={{
              buttonHasTilt: true,
              buttonShowTiltOn: 'always',
              buttonTiltColor: '#FAD762',
              buttonTiltMaxRotation: 2,
              buttonTiltMaxSkew: 2,
              buttonCustomClass: '',
              buttonContent: 'Submit',
            }}
          />
        )}
      </form>
    </div>
  );
};

export default ContactForm;
