import { useEffect, useState } from 'react';
import Button from '../../../Components/Button/Button';
import ImageContainer from '../../../Components/ImageContainers/ImageContainer';

interface SectionGridItem {
  imageSrc: string;
  title: string;
  link: string;
}
export interface ServiceGridSectionData {
  title: string;
  content: string;
  gridItems: SectionGridItem[];
  className: string;
}

const GridSection: React.FC<ServiceGridSectionData> = ({ title, content, gridItems, className }) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('load', () => {
      if (window.innerWidth <= 1080) {
        setIsMobile(true);
      }
    });
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 1080) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    });

    return () => {
      window.removeEventListener('load', () => {});
      window.removeEventListener('resize', () => {});
    };
  }, []);

  return (
    <section className={`gridSection ${isActive ? 'active' : ''} ${className}`}>
      <div className="outer">
        <div className="inner">
          <div className="intro">
            <h2 data-speed="1.1" data-lag="0.2">
              <Button
                data={{
                  buttonHasTilt: true,
                  buttonShowTiltOn: 'always',
                  buttonTiltColor: '#13121C',
                  buttonTiltMaxRotation: 4,
                  buttonTiltMaxSkew: 4,
                  buttonCustomClass: 'font-h1 clrWhite',
                  buttonLinkType: 'none',
                  buttonContent: title,
                }}
              />
            </h2>

            <p className="p-small clrBlack" data-speed="1.2" data-lag="0.1">
              {content}
            </p>
          </div>

          <div className="gridContainer">
            <div className="inner">
              {gridItems.map((item: SectionGridItem, key: number) => {
                return (
                  <div key={key} className="item" data-speed={`1.${key % 2}`} data-lag="0.1">
                    <div className="background">
                      <ImageContainer
                        data={{
                          imageSource: item.imageSrc,
                        }}
                      />
                      <div className="overlay" style={{ backgroundColor: 'rgba(0,0,0,0.3)' }} />
                    </div>

                    <a className="linkWrapper" href={item.link}>
                      {item.title}
                    </a>

                    <div className="inner">
                      <p className="font-h4 clrWhite">{item.title}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {isMobile && gridItems.length > 4 ? (
            <Button
              data={{
                buttonHasTilt: true,
                buttonShowTiltOn: 'always',
                buttonTiltColor: '#13121C',
                buttonTiltMaxRotation: 4,
                buttonTiltMaxSkew: 4,
                buttonCustomClass: 'font-h4 clrWhite',
                buttonLinkType: 'function',
                buttonContent: `${isActive ? 'View Less' : 'View More'}`,
                buttonLinkFunction: () => {
                  setIsActive(!isActive);
                },
              }}
            />
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default GridSection;
