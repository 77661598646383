interface SpacerProps {
  minHeight: number;
  maxHeight: number;
  desiredHeight: number;
  backgroundColor?: string;
}

const Spacer: React.FC<SpacerProps> = ({ minHeight, maxHeight, desiredHeight, backgroundColor }) => {
  return (
    <div
      className="Spacer"
      style={{ height: `clamp(${minHeight}px, ${desiredHeight}vw, ${maxHeight}px)`, position: 'relative' }}
    >
      <div
        className="inner"
        style={{
          height: '110%',
          width: '300vw',
          position: 'absolute',
          top: 0,
          left: '-100vw',
          backgroundColor: backgroundColor || '',
          maxWidth: 'unset',
        }}
      />
    </div>
  );
};

export default Spacer;
