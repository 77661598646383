import axios from 'axios';
import './ServicePage.scss';
import USPSection from './Sections/USPSection';
import GridSection from './Sections/GridSection';
import React, { useState, useEffect } from 'react';
import IntroSection from './Sections/IntroSection';
import Spacer from '../../Components/Spacer/Spacer';
import PageHead from '../../Components/misc/PageHead';
import OurWorkSection from './Sections/OurWorkSection';
import JoinItUpSection from './Sections/JoinItUpSection';
import { ServiceGridSectionData } from './Sections/GridSection';
import ClientCarouselSection from './Sections/ClientCarouselSection';
import PartnerCarouselSection from './Sections/PartnerCarouselSection';
import LoadingContainer from '../../Components/LoadingContainers/LoadingContainer';
import { useLoadingRunContext, useToggleLoadingRunContext } from '../../Context/loadingContext';
import { initialiseSmoothScroll, uninitialisedSmoothScroll } from '../../Utilities/Functions/handleSmoothScroll';

const pageCanonicalHref: string = 'https://www.addmustard.com/technology/';
const pageTitle: string = 'Award-winning Technology Agency for Entrepreneurs | addmustard';
const pageDescription: string =
  'World-class technology solutions. All joined up by what matters: innovation, performance & consistency.';
const pageThemeColor: string = '#84a9b5';
const pageRobots: string = 'all';
const gridSectionData: ServiceGridSectionData = {
  title: 'How we do it',
  content:
    'Great technology can solve problems. It can attract, convert and retain customers. It can streamline processes. It can grow margins and increase ROI. It is a prerequisite for an exceptional brand experience. Our expert technology team are adept problem solvers, spanning strategy, design, engineering and development. Working across programming languages to create an agile framework, across front and back office. We turn world-class ideas into tangible experiences for your audience.',
  gridItems: [
    {
      imageSrc: '../images/technology-uxDesign.webp',
      link: `/technology/ux-design/`,
      title: 'UX Design',
    },
    {
      imageSrc: '../images/technology-development.webp',
      link: `/technology/technology-development/`,
      title: 'Technology Development',
    },
  ],
  className: 'technology',
};

const ServiceTechnology: React.FC = () => {
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false);
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [pageOpacity, setPageOpacity] = useState<number>(0);
  const [postDetails, setPostDetails] = useState<any>({});
  const toggleLoadingRun: () => void = useToggleLoadingRunContext();
  const loadingRun: boolean = useLoadingRunContext();

  useEffect(() => {
    const setPage: () => void = () => {
      setPageLoaded(true);
      setPageOpacity(1);
      window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    };

    if (loadingRun) {
      setPage();
      return;
    }

    var stateCheck = setInterval(() => {
      if (document.readyState === 'complete') {
        clearInterval(stateCheck);
        setPage();
        setTimeout(() => {
          setLoadingComplete(true);
          toggleLoadingRun();
        }, 300);
      }
    }, 1700);

    return () => {};
  }, [loadingRun, toggleLoadingRun]);

  useEffect(() => {
    axios.get('https://www.addmustard.com/wp-json/acf/v3/options/technology').then(response => {
      const postDetails = response.data.acf.post_details;

      setPostDetails(postDetails);
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 500);
    });
  }, []);

  useEffect(() => {
    const smoothScroller = initialiseSmoothScroll();

    return () => {
      if (smoothScroller) uninitialisedSmoothScroll(smoothScroller);
    };
  }, []);

  useEffect(() => {
    const stickyHeader = document.getElementById('stickyHeader');

    if (!stickyHeader) return;

    stickyHeader.classList.remove('active');
  }, []);

  return (
    <>
      <PageHead
        canonical={pageCanonicalHref}
        description={pageDescription}
        robots={pageRobots}
        themeColor={pageThemeColor}
        title={pageTitle}
      />

      {loadingComplete || loadingRun ? <></> : <LoadingContainer loadingComplete={pageLoaded} />}

      <div className="pageBackground" style={{ backgroundColor: '#84a9b5' }}></div>

      <div
        id="ServicePage"
        style={{
          opacity: pageOpacity,
          transition: 'opacity 300ms ease',
          backgroundColor: '#84a9b5',
        }}
      >
        <div className="outer">
          <div className="inner">
            <Spacer minHeight={220} maxHeight={300} desiredHeight={30} backgroundColor="#84a9b5" />

            <IntroSection
              image={{
                imageSrc: postDetails?.intro?.image || '',
                imageAlt: postDetails?.intro?.imageAlt || '',
                isLazy: false,
              }}
              title={postDetails?.intro?.title || ''}
              content={postDetails?.intro?.copy || ''}
              viewAllButton={{
                copy: 'View work',
                link: 'https://www.addmustard.com/our-work/',
                function: () => {
                  const windowHeight: number = window.innerHeight;
                  const ourWorkSectionTop: number =
                    document.querySelector('#our-work')?.getBoundingClientRect().top || 0;
                  try {
                    window.scrollTo({
                      top: ourWorkSectionTop,
                      behavior: 'smooth',
                    });
                  } catch (error) {
                    console.log(error);
                  }
                },
              }}
              quote={postDetails?.intro?.quote || ''}
              quoteAuthor={postDetails?.intro?.quoteAuthor || ''}
            />

            <Spacer minHeight={100} maxHeight={350} desiredHeight={30} backgroundColor="#84a9b5" />

            <USPSection backgroundImageSrc={postDetails?.usp?.image || ''} USPs={postDetails?.usp?.items || []} />

            <Spacer minHeight={150} maxHeight={200} desiredHeight={30} backgroundColor="#84a9b5" />

            <GridSection
              className={gridSectionData.className}
              content={gridSectionData.content}
              gridItems={gridSectionData.gridItems}
              title={gridSectionData.title}
            />

            <Spacer minHeight={100} maxHeight={200} desiredHeight={30} backgroundColor="#84a9b5" />

            <JoinItUpSection
              backgroundImageSrc={postDetails?.services_overview?.image || '../images/joinItUp.webp'}
              title={postDetails?.services_overview?.title || ''}
              titleBackgroundColor="#84a9b5"
              content={postDetails?.services_overview?.copy || ''}
              buttons={[
                {
                  content: 'Brand',
                  link: `/brand/`,
                  tiltColor: '#F0BEC9',
                  isActive: false,
                },
                {
                  content: 'Marketing',
                  link: `/marketing/`,
                  tiltColor: '#F27250',
                  isActive: false,
                },
                {
                  content: 'Technology',
                  link: `/technology/`,
                  tiltColor: '#84A9B5',
                  isActive: true,
                },
              ]}
            />

            <ClientCarouselSection
              clientItems={postDetails?.client_carousel?.items || []}
              titleBackgroundColor="#84a9b5"
            />

            <PartnerCarouselSection
              partnerItems={postDetails?.partner_carousel?.items || []}
              titleBackgroundColor="#84a9b5"
            />

            <Spacer minHeight={50} maxHeight={150} desiredHeight={30} backgroundColor="#84a9b5" />

            <div id="our-work" />
            <OurWorkSection backgroundColor="#84a9b5" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceTechnology;
