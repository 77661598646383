import gsap from 'gsap';
import './HomePage.scss';
import axios from 'axios';
import BlogSection from './Sections/BlogSection';
import React, { useState, useEffect, useMemo } from 'react';
import AboutSection from './Sections/AboutSection';
import IntroSection from './Sections/IntroSection';
import PageHead from '../../Components/misc/PageHead';
import OurWorkSection from './Sections/OurWorkSection';
import TestimonialSection from './Sections/TestimonialSection';
import AutoScrollCarousel from '../../Components/Carousels/AutoScrollCarousel';
import LoadingContainer from '../../Components/LoadingContainers/LoadingContainer';
import { useLoadingRunContext, useToggleLoadingRunContext } from '../../Context/loadingContext';
import { initialiseSmoothScroll, uninitialisedSmoothScroll } from '../../Utilities/Functions/handleSmoothScroll';

const pageTitle: string = 'Brand, Marketing, Technology for Entrepreneurs | addmustard';
const pageDescription: string =
  'We are a brand, marketing and technology agency that works exclusively with entrepreneurial businesses to drive profitable growth.';
const pageCanonicalHref: string = 'https://www.addmustard.com/';
const pageThemeColor: string = '#fad762';
const pageRobots: string = 'all';

const HomePage: React.FC = () => {
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false);
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [pageOpacity, setPageOpacity] = useState<number>(0);
  const [postDetails, setPostDetails] = useState<any>({});
  const loadingRun: boolean = useLoadingRunContext();
  const toggleLoadingRun: () => void = useToggleLoadingRunContext();
  // const autoCarousel = useMemo(() => {
  //   return <AutoScrollCarousel carouselItems={postDetails ? postDetails.client_carousel.items : []} />;
  // }, [postDetails]);

  useEffect(() => {
    const setPage: () => void = () => {
      setPageLoaded(true);
      setPageOpacity(1);
      window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
      gsap.to('.animHeader', { y: '0', duration: 0.5, ease: 'elastic.out(1, 1)', delay: 0 });
      gsap.to('.topSection', { opacity: 1, y: '0', duration: 2, ease: 'elastic.out(1, 1)', delay: 0 });
    };

    if (loadingRun) {
      setPage();
      return;
    }

    var stateCheck = setInterval(() => {
      if (document.readyState === 'complete') {
        clearInterval(stateCheck);
        setPage();
        setTimeout(() => {
          setLoadingComplete(true);
          toggleLoadingRun();
        }, 300);
      }
    }, 1700);

    return () => {};
  }, [loadingRun, toggleLoadingRun]);

  useEffect(() => {
    axios.get('https://www.addmustard.com/wp-json/acf/v3/options/home').then(response => {
      const postDetails = response.data.acf.post_details;

      setPostDetails(postDetails);
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 500);
    });
  }, []);

  useEffect(() => {
    const stickyHeader = document.getElementById('stickyHeader');

    if (!stickyHeader) return;

    stickyHeader.classList.remove('active');
  }, []);

  useEffect(() => {
    const smoothScroller = initialiseSmoothScroll();

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);

    return () => {
      if (smoothScroller) uninitialisedSmoothScroll(smoothScroller);
    };
  }, []);

  return (
    <>
      <PageHead
        canonical={pageCanonicalHref}
        description={pageDescription}
        robots={pageRobots}
        themeColor={pageThemeColor}
        title={pageTitle}
      />

      {loadingComplete || loadingRun ? <></> : <LoadingContainer loadingComplete={pageLoaded} />}

      <div className="pageBackground HomePage"></div>

      <div id="HomePage" style={{ opacity: pageOpacity, transition: 'opacity 500ms ease' }}>
        <div className="outer">
          <div className="inner">
            <div className="Spacer" style={{ height: '50px', position: 'relative' }}>
              <div
                className="inner"
                style={{
                  height: '110%',
                  width: '300vw',
                  position: 'absolute',
                  top: 0,
                  left: '-100vw',
                  backgroundColor: '#FAD762',
                  maxWidth: 'unset',
                }}
              />
            </div>

            <IntroSection />

            <div className="Spacer" style={{ height: '150px', position: 'relative' }}>
              <div
                className="inner"
                style={{
                  height: '110%',
                  width: '300vw',
                  position: 'absolute',
                  top: 0,
                  left: '-100vw',
                  backgroundColor: '#FAD762',
                  maxWidth: 'unset',
                }}
              />
            </div>

            {postDetails && postDetails.client_carousel && postDetails.client_carousel.include ? (
              <AutoScrollCarousel carouselItems={postDetails ? postDetails.client_carousel.items : []} />
            ) : (
              <></>
            )}

            <div className="Spacer mobileSpacer" style={{ height: '150px', position: 'relative', zIndex: '-2' }}>
              <div
                className="inner"
                style={{
                  height: '110%',
                  width: '300vw',
                  position: 'absolute',
                  top: 0,
                  left: '-100vw',
                  backgroundColor: '#FAD762',
                  maxWidth: 'unset',
                }}
              />
            </div>

            <OurWorkSection />

            <div className="Spacer mobileSpacer" style={{ height: '150px', position: 'relative' }}>
              <div
                className="inner"
                style={{
                  height: '110%',
                  width: '300vw',
                  position: 'absolute',
                  top: 0,
                  left: '-100vw',
                  backgroundColor: '#FAD762',
                  maxWidth: 'unset',
                }}
              />
            </div>
            <AboutSection />

            <div className="Spacer" style={{ height: '150px', position: 'relative' }}>
              <div
                className="inner"
                style={{
                  height: '110%',
                  width: '300vw',
                  position: 'absolute',
                  top: 0,
                  left: '-100vw',
                  backgroundColor: '#FAD762',
                  maxWidth: 'unset',
                }}
              />
            </div>

            <TestimonialSection />

            <div className="Spacer mobileSpacer" style={{ height: '200px', position: 'relative' }}>
              <div
                className="inner"
                style={{
                  height: '110%',
                  width: '300vw',
                  position: 'absolute',
                  top: 0,
                  left: '-100vw',
                  backgroundColor: '#FAD762',
                  maxWidth: 'unset',
                }}
              />
            </div>

            <BlogSection />

            <div className="Spacer" style={{ height: '100px', position: 'relative' }}>
              <div
                className="inner"
                style={{
                  height: '110%',
                  width: '300vw',
                  position: 'absolute',
                  top: 0,
                  left: '-100vw',
                  backgroundColor: '#FAD762',
                  maxWidth: 'unset',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;