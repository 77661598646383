import '../Grid.scss';
import Button from '../../Button/Button';
import React, { useCallback } from 'react';
import { iGridBlogCard } from '../GridInterfaces';
import { Link, LinkProps } from 'react-router-dom';
import Tilt from '../../TiltContainer/TiltContainer';
import ImageContainer from '../../ImageContainers/ImageContainer';

const GridBlogCard: React.FC<iGridBlogCard> = (props: iGridBlogCard) => {
  const cardClass: string | undefined = props.data.cardClass;
  const cardTitle: string | undefined = props.data.cardTitle;
  const cardCopy: string | undefined = props.data.cardCopy;
  const cardImage: string | undefined = props.data.cardImage;
  const cardImageAlt: string | undefined = props.data.cardImageAlt;
  const cardLinkCopy: string | undefined = props.data.cardLinkCopy;
  const cardLinkType: string | undefined = props.data.cardLinkType;
  const cardLinkHref: string | LinkProps['to'] = props.data.cardLinkHref || '';
  const cardBackgroundColor: string | undefined = props.data.cardBackgroundColor || '';

  const getTagBackgroundColor: (tag: string) => string = useCallback(tag => {
    var tagBackgroundColor: string = '';

    if (tag === 'Brand') {
      tagBackgroundColor = '#F0BEC9';
    } else if (tag === 'Marketing') {
      tagBackgroundColor = '#F27250';
    } else if (tag === 'Technology') {
      tagBackgroundColor = '#84A9B5';
    }

    return tagBackgroundColor;
  }, []);
  const generateBlogCardContent: () => JSX.Element = useCallback(() => {
    const cardTags: Array<string> = props.data.cardTags || [];

    return (
      <>
        <div className="contentContainer">
          <Tilt data={{ maxRotation: 1, maxSkew: 2, backgroundColor: cardBackgroundColor }} />

          <div className="inner">
            {cardTitle ? <h5 className="clrWhite font-weight-regular">{cardTitle}</h5> : <></>}

            {cardCopy ? <p className="clrWhite">{cardCopy}</p> : <></>}

            {cardTags.length > 0 ? (
              <div className="tagContainer">
                <div className="inner">
                  {cardTags.map((tag: string, key: number) => {
                    return (
                      <div key={key} className="tag">
                        <div className="inner">
                          <Button
                            data={{
                              buttonHasTilt: true,
                              buttonShowTiltOn: 'always',
                              buttonTiltColor: getTagBackgroundColor(tag),
                              buttonTiltMaxRotation: 2,
                              buttonTiltMaxSkew: 2,
                              buttonLinkType: 'none',
                              buttonContent: tag,
                              buttonCustomClass: 'p-small',
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        {cardImage ? (
          <ImageContainer
            data={{ imageSource: cardImage, imageAlt: cardImageAlt, imageHrefType: 'External Link', imageHref: '' }}
          />
        ) : (
          <></>
        )}

        <Button
          data={{
            buttonHasTilt: true,
            buttonShowTiltOn: 'always',
            buttonTiltColor: '#ffffff',
            buttonTiltMaxRotation: 3,
            buttonTiltMaxSkew: 3,
            buttonLinkType: 'external',
            buttonLinkHref: cardLinkHref,
            buttonContent: cardLinkCopy,
          }}
        />
      </>
    );
  }, [
    cardBackgroundColor,
    cardCopy,
    cardImage,
    cardImageAlt,
    cardLinkCopy,
    cardLinkHref,
    props.data.cardTags,
    cardTitle,
    getTagBackgroundColor,
  ]);
  const generateBlogCardInterior: () => JSX.Element = useCallback(() => {
    const innerClassName: string = cardImage ? 'inner withImage' : 'inner';

    if (cardLinkHref) {
      if (cardLinkType === 'Internal Link') {
        return (
          <div className={innerClassName}>
            <Link to={cardLinkHref} />
            {generateBlogCardContent()}
          </div>
        );
      } else if (cardLinkType === 'External Link') {
        return (
          <div className={innerClassName}>
            <a href={cardLinkHref} />
            {generateBlogCardContent()}
          </div>
        );
      }
    }

    return <div className={innerClassName}>{generateBlogCardContent()}</div>;
  }, [cardLinkHref, cardLinkType, generateBlogCardContent, cardImage]);

  const GridBlogCardClass: string = cardClass ? 'GridBlogCard ' + cardClass : 'GridBlogCard';

  return <div className={GridBlogCardClass}>{generateBlogCardInterior()}</div>;
};

export default GridBlogCard;
