import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useEffect, useLayoutEffect } from 'react';
import Button from '../../../Components/Button/Button';
import ImageContainer from '../../../Components/ImageContainers/ImageContainer';

interface ServiceIntroSectionImage {
  imageSrc: string;
  imageAlt?: string;
  isLazy?: boolean;
}
interface viewAllButton {
  copy: string;
  link: string;
  function?: () => void;
}
interface ServiceIntroSectionData {
  image: ServiceIntroSectionImage;
  title: string;
  content: string;
  quote?: string;
  quoteAuthor?: string;
  viewAllButton?: viewAllButton;
}

const IntroSection: React.FC<ServiceIntroSectionData> = ({
  image,
  title,
  content,
  quote,
  quoteAuthor,
  viewAllButton,
}) => {
  const getQuoteContents: () => string = () => {
    try {
      var quoteContent: string = '';

      if (quote) {
        const quoteAsArray: string[] = quote.split(' ');

        quoteAsArray.forEach((word: string, index: number) => {
          quoteContent += `<span class="TiltWrapper" style="background-color: #13121c; color: #fff;">${
            index === quoteAsArray.length - 1 ? word : word + ' '
          }</span>`;
        });
      }

      return quoteContent;
    } catch (error) {
      console.log(error);
      return '';
    }
  };

  useEffect(() => {
    const imageAnimation = gsap.fromTo(
      '.backgroundImageIntroSection',
      { x: -300, y: 300, opacity: 0 },
      { delay: 1.5, duration: 1.5, x: 0, y: 0, opacity: 1 },
    );
    const contentAnimation = gsap.fromTo(
      '.innerContentIntroSection',
      { x: 300, y: 300, opacity: 0 },
      { delay: 1.5, duration: 1.5, x: 0, y: 0, opacity: 1 },
    );

    if (quote && quoteAuthor) {
      var contentAnimationTwo = gsap.to('.opacityContent', {
        opacity: 0.6,
        scrollTrigger: {
          trigger: '.opacityContent',
          start: 'top top',
          end: () => '+=' + document.getElementsByClassName('opacityContent')[0].clientHeight + 'px',
          scrub: 0.2,
          markers: false,
        },
      });
    }

    return () => {
      imageAnimation.kill();
      contentAnimation.kill();
      if (quote && quoteAuthor) contentAnimationTwo.kill();
    };
  }, [quote, quoteAuthor]);

  useLayoutEffect(() => {
    var endPosition: string = '+=' + 0 + 'px';

    if (quote && quoteAuthor) {
      var ratio: number = window.innerHeight / (0.4 * window.innerWidth);
      ratio = ratio > 1 ? 1 + Math.floor(ratio) / 10 : 1;
      endPosition = '+=' + window.innerHeight * ratio + 'px';
    }

    var scrollingTrigger: globalThis.ScrollTrigger = ScrollTrigger.create({
      trigger: '.backgroundImageIntroSection',
      start: 'top 150px',
      end: endPosition,
      pin: true,
      pinSpacing: false,
    });

    return () => {
      scrollingTrigger.kill();
    };
  }, [quote, quoteAuthor]);

  return (
    <section className="introSection">
      <div className="backgroundImage backgroundImageIntroSection">
        <div className="opacityContent">
          <ImageContainer
            data={{
              imageSource: image.imageSrc,
              imageAlt: image.imageAlt,
              imageIsLazy: image.isLazy,
            }}
          />
        </div>
      </div>

      <div className="outer">
        <div className="inner innerContentIntroSection">
          <div>
            <div data-speed="1" data-lag="0.1" className="header">
              <h1 dangerouslySetInnerHTML={{ __html: title }} />
            </div>

            <p className="font-large" dangerouslySetInnerHTML={{ __html: content }} />

            {viewAllButton ? (
              <Button
                data={{
                  buttonHasTilt: true,
                  buttonShowTiltOn: 'always',
                  buttonTiltColor: '#13121C',
                  buttonTiltMaxRotation: 4,
                  buttonTiltMaxSkew: 4,
                  buttonCustomClass: 'clrWhite font-bold',
                  buttonLinkType: 'function',
                  buttonLinkHref: viewAllButton.link,
                  buttonContent: viewAllButton.copy,
                  buttonLinkFunction: viewAllButton.function,
                }}
              />
            ) : (
              <></>
            )}
          </div>

          {quote && quoteAuthor ? (
            <div className="quote-container">
              <div className="inner">
                <p className="quote" data-speed="1" data-lag="0.3">
                  <span className="quoteContent font-xLarge" dangerouslySetInnerHTML={{ __html: getQuoteContents() }} />
                  <span className="author font-large">{`- ${quoteAuthor}`}</span>
                </p>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </section>
  );
};

export default IntroSection;
