export const getIsValidName: (value: string) => boolean = value => {
  const allowedValues: RegExp = new RegExp(
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
  );
  const isValidString: boolean = allowedValues.test(value) && value ? true : false;

  return isValidString;
};

export const getIsValidEmail: (value: string) => boolean = value => {
  const allowedValues: RegExp = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );
  const isValidEmail: boolean = allowedValues.test(value) && value ? true : false;

  return isValidEmail;
};

export const getIsValidNumber: (value: string) => boolean = value => {
  const allowedValues: RegExp = new RegExp(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im);
  const isValidNumber: boolean = allowedValues.test(value) && value ? true : false;

  return isValidNumber;
};

export const getIsValidCompany: (value: string) => boolean = value => {
  const isValidCompany: boolean = value ? true : false;

  return isValidCompany;
};

export const getIsValidComment: (value: string, initialCommentValue: string) => boolean = (
  value,
  initialCommentValue,
) => {
  const isValidComment: boolean = value && value !== initialCommentValue ? true : false;

  return isValidComment;
};
