import './Grid.scss';
import Button from '../Button/Button';
import { iGrid } from './GridInterfaces';
import React, { useCallback, Children, useState, useMemo } from 'react';

const Grid: React.FC<iGrid> = (props: iGrid) => {
  const childrenCount: number = Children.count(props.children);
  const gridClass: string | undefined = props.data.gridClass;
  const gridLoadCount: number = props.data.gridLoadCount || 100;
  const gridPagination: string | undefined = props.data.gridPagination;
  const gridPaginationCopy: string | undefined = props.data.gridPaginationCopy;
  const gridPaginationLinkHref: string | undefined = props.data.gridPaginationLinkHref;
  const gridPaginationLinkType: 'internal' | 'external' | 'function' | 'none' =
    props.data.gridPaginationLinkType || 'none';

  const [currentActiveItemsCount, setCurrentActiveItemsCount] = useState<number>(gridLoadCount);
  const [loadMoreButtonHidden, setLoadMoreButtonHidden] = useState<boolean>(gridLoadCount > childrenCount);

  const handleLoadMore: () => void = useCallback(() => {
    var newActiveItemCount: number =
      currentActiveItemsCount + gridLoadCount > childrenCount ? childrenCount : currentActiveItemsCount + gridLoadCount;

    if (newActiveItemCount === childrenCount) {
      setLoadMoreButtonHidden(true);
    }

    setCurrentActiveItemsCount(newActiveItemCount);
  }, [childrenCount, currentActiveItemsCount, gridLoadCount]);
  const generatePaginationContainer: () => JSX.Element = useCallback(() => {
    if (gridPagination === 'none') {
      return <></>;
    } else if (gridPagination === 'link') {
      return (
        <div className="paginationContainer link">
          <div className="inner">
            <Button
              data={{
                buttonHasTilt: true,
                buttonShowTiltOn: 'always',
                buttonTiltColor: '#13121C',
                buttonTiltMaxRotation: 3,
                buttonTiltMaxSkew: 3,
                buttonLinkType: gridPaginationLinkType,
                buttonLinkHref: gridPaginationLinkHref,
                buttonContent: gridPaginationCopy,
                buttonCustomClass: 'clrWhite',
              }}
            />
          </div>
        </div>
      );
    } else if (gridPagination === 'loadMore') {
      if (loadMoreButtonHidden) {
        return <></>;
      } else {
        return (
          <div className="paginationContainer loadMore">
            <div className="inner">
              <Button
                data={{
                  buttonHasTilt: true,
                  buttonShowTiltOn: 'always',
                  buttonTiltColor: '#13121C',
                  buttonTiltMaxRotation: 3,
                  buttonTiltMaxSkew: 3,
                  buttonLinkType: 'function',
                  buttonLinkFunction: handleLoadMore,
                  buttonContent: gridPaginationCopy,
                }}
              />
            </div>
          </div>
        );
      }
    } else if (gridPagination === 'paged') {
      return (
        <div className="paginationContainer paged">
          <div className="inner" />
        </div>
      );
    } else {
      return <></>;
    }
  }, [
    gridPagination,
    gridPaginationCopy,
    gridPaginationLinkHref,
    gridPaginationLinkType,
    handleLoadMore,
    loadMoreButtonHidden,
  ]);

  const gridContent: JSX.Element | JSX.Element[] | null | undefined = useMemo(() => {
    return childrenCount > 0 ? (
      Children.map(props.children, (child: React.ReactNode | React.ReactElement, key: number) => {
        const childClass: string = currentActiveItemsCount - 1 >= key ? 'item active' : 'item';

        return (
          <div className={childClass} key={key}>
            {child}
          </div>
        );
      })
    ) : (
      <></>
    );
  }, [childrenCount, props.children, currentActiveItemsCount]);

  const GridClass: string = gridClass ? 'Grid ' + gridClass : 'Grid';

  return (
    <div className={GridClass}>
      <div className="outer">
        <div className="inner">{gridContent}</div>

        {generatePaginationContainer()}
      </div>
    </div>
  );
};

export default Grid;
