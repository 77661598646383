import axios from 'axios';
import './ServicePage.scss';
import USPSection from './Sections/USPSection';
import GridSection from './Sections/GridSection';
import React, { useState, useEffect } from 'react';
import IntroSection from './Sections/IntroSection';
import Spacer from '../../Components/Spacer/Spacer';
import PageHead from '../../Components/misc/PageHead';
import OurWorkSection from './Sections/OurWorkSection';
import JoinItUpSection from './Sections/JoinItUpSection';
import { ServiceGridSectionData } from './Sections/GridSection';
import ClientCarouselSection from './Sections/ClientCarouselSection';
import PartnerCarouselSection from './Sections/PartnerCarouselSection';
import LoadingContainer from '../../Components/LoadingContainers/LoadingContainer';
import { useLoadingRunContext, useToggleLoadingRunContext } from '../../Context/loadingContext';
import { initialiseSmoothScroll, uninitialisedSmoothScroll } from '../../Utilities/Functions/handleSmoothScroll';

const pageCanonicalHref: string = 'https://www.addmustard.com/marketing/';
const pageTitle: string = 'Award-winning Marketing Agency for Entrepreneurs | addmustard';
const pageDescription: string =
  'World-class creative, marketing & technology. Joined up by what matters: numbers, performance & consistency.';
const pageThemeColor: string = '#F27250';
const pageRobots: string = 'all';
const gridSectionData: ServiceGridSectionData = {
  title: 'How we do it',
  content:
    'We get under your customers’ skin. We master how they research, engage, search, shop and stay loyal. How they interact with brands. The messages, imagery and content they like. The media they consume, platforms they use, the podcasts they listen to, the websites they visit, the products they buy. Then we take your brand messages and turbo-charge them across all of those touchpoints. Consistently. In a way that can be tracked and measured. Adapting as your customers adapt. To deliver end-to-end performance and growth.',
  gridItems: [
    {
      imageSrc: '../images/marketing-dataAnalytics.webp',
      link: `/marketing/data-analytics/`,
      title: 'Data & Analytics',
    },
    {
      imageSrc: '../images/marketing-creative.webp',
      link: `/marketing/creative/`,
      title: 'Creative',
    },
    {
      imageSrc: '../images/marketing-conversion-rate-optimisation.webp',
      link: `/marketing/conversion-rate-optimisation/`,
      title: 'Conversion Rate Optimisation',
    },
    {
      imageSrc: '../images/marketing-email.webp',
      link: `/marketing/email/`,
      title: 'Email',
    },
    {
      imageSrc: '../images/marketing-mediaPlanning.webp',
      link: `/marketing/media-planning/`,
      title: 'Media Planning',
    },
    {
      imageSrc: '../images/marketing-organicSearch.webp',
      link: `/marketing/seo/`,
      title: 'SEO',
    },
    {
      imageSrc: '../images/marketing-paidMedia.webp',
      link: `/marketing/paid-media/`,
      title: 'Paid Media',
    },
    {
      imageSrc: '../images/marketing-social.webp',
      link: `/marketing/social-media/`,
      title: 'Social Media',
    },
  ],
  className: 'marketing',
};

const ServiceMarketing: React.FC = () => {
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false);
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [pageOpacity, setPageOpacity] = useState<number>(0);
  const [postDetails, setPostDetails] = useState<any>({});
  const toggleLoadingRun: () => void = useToggleLoadingRunContext();
  const loadingRun: boolean = useLoadingRunContext();

  useEffect(() => {
    const setPage: () => void = () => {
      setPageLoaded(true);
      setPageOpacity(1);
      window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    };

    if (loadingRun) {
      setPage();
      return;
    }

    var stateCheck = setInterval(() => {
      if (document.readyState === 'complete') {
        clearInterval(stateCheck);
        setPage();
        setTimeout(() => {
          setLoadingComplete(true);
          toggleLoadingRun();
        }, 300);
      }
    }, 1700);

    return () => {};
  }, [loadingRun, toggleLoadingRun]);

  useEffect(() => {
    axios.get('https://www.addmustard.com/wp-json/acf/v3/options/marketing').then(response => {
      const postDetails = response.data.acf.post_details;

      setPostDetails(postDetails);
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 500);
    });
  }, []);

  useEffect(() => {
    const stickyHeader = document.getElementById('stickyHeader');

    if (!stickyHeader) return;

    stickyHeader.classList.remove('active');
  }, []);

  useEffect(() => {
    const smoothScroller = initialiseSmoothScroll();

    return () => {
      if (smoothScroller) uninitialisedSmoothScroll(smoothScroller);
    };
  }, []);

  return (
    <>
      <PageHead
        canonical={pageCanonicalHref}
        description={pageDescription}
        robots={pageRobots}
        themeColor={pageThemeColor}
        title={pageTitle}
      />

      {loadingComplete || loadingRun ? <></> : <LoadingContainer loadingComplete={pageLoaded} />}

      <div className="pageBackground" style={{ backgroundColor: '#F27250' }}></div>

      <div
        id="ServicePage"
        style={{
          opacity: pageOpacity,
          transition: 'opacity 300ms ease',
          backgroundColor: '#F27250',
        }}
      >
        <div className="outer">
          <div className="inner">
            <Spacer minHeight={220} maxHeight={300} desiredHeight={30} backgroundColor="#F27250" />

            <IntroSection
              image={{
                imageSrc: postDetails?.intro?.image || '',
                imageAlt: postDetails?.intro?.imageAlt || '',
                isLazy: false,
              }}
              title={postDetails?.intro?.title || ''}
              content={postDetails?.intro?.copy || ''}
              viewAllButton={{
                copy: 'View work',
                link: '#our-work',
                function: () => {
                  try {
                    const windowHeight: number = window.innerHeight;
                    var ourWorkSectionTop: number =
                      document.querySelector('#our-work')?.getBoundingClientRect().top || 0;

                    if (window.innerWidth < 768) ourWorkSectionTop = ourWorkSectionTop + windowHeight * 0.5;
                    window.scrollTo({
                      top: ourWorkSectionTop,
                      behavior: 'smooth',
                    });
                  } catch (error) {
                    console.log(error);
                  }
                },
              }}
              quote={postDetails?.intro?.quote || ''}
              quoteAuthor={postDetails?.intro?.quoteAuthor || ''}
            />

            <Spacer minHeight={100} maxHeight={350} desiredHeight={30} backgroundColor="#F27250" />

            <USPSection backgroundImageSrc={postDetails?.usp?.image || ''} USPs={postDetails?.usp?.items || []} />

            <Spacer minHeight={150} maxHeight={200} desiredHeight={30} backgroundColor="#F27250" />

            <GridSection
              className={gridSectionData.className}
              content={gridSectionData.content}
              gridItems={gridSectionData.gridItems}
              title={gridSectionData.title}
            />

            <Spacer minHeight={100} maxHeight={200} desiredHeight={30} backgroundColor="#F27250" />

            <JoinItUpSection
              backgroundImageSrc={postDetails?.services_overview?.image || '../images/joinItUp.webp'}
              title={postDetails?.services_overview?.title || ''}
              titleBackgroundColor="#F27250"
              content={postDetails?.services_overview?.copy || ''}
              buttons={[
                {
                  content: 'Brand',
                  link: `/brand/`,
                  tiltColor: '#F0BEC9',
                  isActive: false,
                },
                {
                  content: 'Marketing',
                  link: `/marketing/`,
                  tiltColor: '#F27250',
                  isActive: true,
                },
                {
                  content: 'Technology',
                  link: `/technology/`,
                  tiltColor: '#84A9B5',
                  isActive: false,
                },
              ]}
            />

            <ClientCarouselSection
              clientItems={postDetails?.client_carousel?.items || []}
              titleBackgroundColor="#F27250"
            />

            <PartnerCarouselSection
              partnerItems={postDetails?.partner_carousel?.items || []}
              titleBackgroundColor="#F27250"
            />

            <Spacer minHeight={50} maxHeight={150} desiredHeight={30} backgroundColor="#F27250" />

            <div id="our-work" />
            <OurWorkSection backgroundColor="#F27250" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceMarketing;
