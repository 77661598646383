import emailjs from '@emailjs/browser';

export interface sendContactFormEmailProps {
  form: any;
  successCallback: () => void;
  errorCallback: () => void;
}

const sendContactFormEmail: (data: sendContactFormEmailProps) => void = ({ form, successCallback, errorCallback }) => {
  const serviceID: string = 'service_eob3gds';
  const publicKey: string = 'ZwXsa15DovvlpjehR';
  const templateID: string = 'template_7y7bfl3';

  emailjs
    .sendForm(serviceID, templateID, form, publicKey)
    .then(result => {
      successCallback();
    })
    .catch(error => {
      errorCallback();
    });
};

export default sendContactFormEmail;
