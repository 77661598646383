import React, { useRef, useMemo } from 'react';
import Grid from '../../../Components/Grids/Grid';
import { blogPosts } from '../../../Data/blogPosts';
import GridBlogCard from '../../../Components/Grids/Cards/GridBlogCard';
import ImageContainer from '../../../Components/ImageContainers/ImageContainer';

const BlogSection: React.FC = () => {
  const BlogSectionRef: React.MutableRefObject<any> = useRef(null);

  const content: JSX.Element[] = useMemo(() => {
    return blogPosts.map((blogPost: any, key: number) => {
      return (
        <GridBlogCard
          key={key}
          data={{
            cardTitle: blogPost.excerpt,
            cardImage: blogPost.coverImage,
            cardImageAlt: '',
            cardTags: blogPost.relatedDepartments,
            cardLinkCopy: blogPost.linkCopy,
            cardLinkType: blogPost.linkType,
            cardLinkHref: blogPost.linkHref,
            cardBackgroundColor: blogPost.backgroundColor,
          }}
        />
      );
    });
  }, []);

  return (
    <section ref={BlogSectionRef} className="BlogSection">
      <div className="background" />
      <div className="inner">
        <Grid
          data={{
            gridClass: 'grid-3-3-1',
            gridLoadCount: 3,
            gridPagination: 'link',
            gridPaginationCopy: 'Show more',
            gridPaginationLinkHref: 'https://www.addmustard.com/blog/',
            gridPaginationLinkType: 'external',
          }}
        >
          {content}
        </Grid>
      </div>
    </section>
  );
};

export default BlogSection;
