import React, { useMemo } from 'react';
import SlideCarousel from '../../../Components/Carousels/SlideCarousel';
import SlideTestimonialCard from '../../../Components/Carousels/Cards/SlideTestimonialCard';

const testimonials: any[] = [
  {
    excerptTitle:
      'We have built our luxury entrepreneurial business very successfully by working with outstanding partners like addmustard. ',
    excerptCopy:
      'The team has been integral to our success and delivered above and beyond, working with our brand, marketing and technology teams to deliver exceptional results. ',
    author: 'Iain Burton - Chairman & Founder, Aspinal of London',
    backgroundColor: '#FCF3D4',
  },
  {
    excerptTitle:
      'The addmustard team have a perfect blend of skill sets to allow an Entrepreneur to take a concept or basic idea, and build the key pillars ',
    excerptCopy:
      'around Content, Technology and Commercial in order to improve or develop a legitimate business. With a culture of can-do and will-do.',
    author: 'Scott Le Roi - Founder & CEO, Orbzii',
    backgroundColor: '#FCF3D4',
  },
  {
    excerptTitle:
      'addmustard have worked with us across all channels and technology platforms to deliver highly successful digital performance,',
    excerptCopy:
      'improved customer experience and increased efficiency of our digital marketing. They have integrated with our team and have completely aligned themselves with our strategy.',
    author: 'Michael Baulk - Chairman, Marsh & Parsons ',
    backgroundColor: '#FCF3D4',
  },
  {
    excerptTitle:
      'addmustard worked with the board, marketing, technology and brand teams at Mr & Mrs Smith and provided amazing service, ',
    excerptCopy:
      'insight and helped us accelerate a number of critical projects and campaigns to enable us to scale our business.',
    author: 'Julian Diment - Chief Growth Officer, Mr & Mrs Smith',
    backgroundColor: '#FCF3D4',
  },
  {
    excerptTitle:
      'addmustard were instrumental across our campaigns and technology, delivering profitable growth in visibility and traffic, ',
    excerptCopy:
      'and supporting our in-house team to deliver improvements in our reporting, attribution, SEM, paid media and SEO channels.',
    author: 'Alun Williams - Head of easyJet Holidays',
    backgroundColor: '#FCF3D4',
  },
  {
    excerptTitle:
      'addmustard works with us across brand, marketing and technology to help drive growth, consistency and brand performance.',
    excerptCopy:
      'Working with our in-house teams, they are increasing awareness, driving quality leads, improving sales effectiveness and delivering efficiency across our marketing channels.',
    author: 'Barney Taylor - CEO, Focus Group',
    backgroundColor: '#FCF3D4',
  },
];

const TestimonialSection: React.FC = () => {
  const content: JSX.Element[] = useMemo(() => {
    return testimonials.map((testimonial: any, key: number) => {
      return (
        <SlideTestimonialCard
          key={key}
          data={{
            cardTitle: testimonial.excerptTitle,
            cardCopy: testimonial.excerptCopy,
            cardAuthor: testimonial.author,
            cardBackgroundColor: testimonial.backgroundColor,
          }}
        />
      );
    });
  }, []);

  return (
    <section className="TestimonialSection">
      <div className="background" />

      <div className="inner testInner" data-speed="0.8" data-lag="0">
        <SlideCarousel
          data={{
            carouselHasArrows: true,
            carouselHasDots: false,
            carouselViewAllCopy: '',
            carouselViewAllType: 'none',
            carouselViewAllHref: '',
          }}
        >
          {content}
        </SlideCarousel>
      </div>
    </section>
  );
};

export default TestimonialSection;
