import { gsap } from 'gsap';
import React, { useLayoutEffect } from 'react';
import ScrollTrigger from 'gsap/ScrollTrigger';

const AboutSection: React.FC = () => {
  const triggerEnd: string = '+=' + 4 * window.innerHeight + 'px';

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    var scrollingTrigger: globalThis.ScrollTrigger = ScrollTrigger.create({
      trigger: '.AboutSectionStatic',
      start: 'top 0',
      end: triggerEnd,
      pin: true,
      pinSpacing: false,
    });

    var cardOneTrigger: globalThis.ScrollTrigger = ScrollTrigger.create({
      trigger: '.AboutSectionCard-1',
      start: '-50%',
      end: '50%',
      onEnter: () => {
        gsap.to('.AboutSectionStaticBackground', { backgroundColor: '#F0BEC9' });
        gsap.to('.AboutSectionImage-1', { opacity: 1, delay: 0 });
      },
      onEnterBack: () => {
        gsap.to('.AboutSectionStaticBackground', { backgroundColor: '#F0BEC9' });
        gsap.to('.AboutSectionImage-1', { opacity: 1, delay: 0 });
      },
      onLeave: () => {
        gsap.to('.AboutSectionImage-1', { opacity: 0, delay: 0 });
      },
      onLeaveBack: () => {
        gsap.to('.AboutSectionImage-1', { opacity: 0, delay: 0 });
      },
    });

    var cardTwoTrigger: globalThis.ScrollTrigger = ScrollTrigger.create({
      trigger: '.AboutSectionCard-2',
      start: '-50%',
      end: '50%',
      onEnter: () => {
        gsap.to('.AboutSectionStaticBackground', { backgroundColor: '#F27350' });
        gsap.to('.AboutSectionImage-2', { opacity: 1, delay: 0 });
      },
      onEnterBack: () => {
        gsap.to('.AboutSectionStaticBackground', { backgroundColor: '#F27350' });
        gsap.to('.AboutSectionImage-2', { opacity: 1, delay: 0 });
      },
      onLeave: () => {
        gsap.to('.AboutSectionImage-2', { opacity: 0, delay: 0 });
      },
      onLeaveBack: () => {
        gsap.to('.AboutSectionImage-2', { opacity: 0, delay: 0 });
      },
    });

    var cardThreeTrigger: globalThis.ScrollTrigger = ScrollTrigger.create({
      trigger: '.AboutSectionCard-3',
      start: '-50%',
      end: '50%',
      onEnter: () => {
        gsap.to('.AboutSectionStaticBackground', { backgroundColor: '#84A9B5' });
        gsap.to('.AboutSectionImage-3', { opacity: 1, delay: 0 });
      },
      onEnterBack: () => {
        gsap.to('.AboutSectionStaticBackground', { backgroundColor: '#84A9B5' });
        gsap.to('.AboutSectionImage-3', { opacity: 1, delay: 0 });
      },
      onLeave: () => {
        gsap.to('.AboutSectionImage-3', { opacity: 0, delay: 0 });
      },
      onLeaveBack: () => {
        gsap.to('.AboutSectionImage-3', { opacity: 0, delay: 0 });
      },
    });

    var lastCardTrigger = ScrollTrigger.create({
      trigger: '.lastcard',
      start: 'center center',
      end: '100%',
      pin: true,
      pinSpacing: false,
    });

    return () => {
      scrollingTrigger.kill();
      cardOneTrigger.kill();
      cardTwoTrigger.kill();
      cardThreeTrigger.kill();
      lastCardTrigger.kill();
    };
  }, [triggerEnd]);

  return (
    <section className="AboutSection">
      <div className="AboutSectionStatic">
        <div className="AboutSectionStaticBackground" />

        <div className="inner">
          <div className="imageParent">
            <div className="inner">
              <div className="ImageContainer ImageContainer-1">
                <div className="imageCover" />

                <div className="inner AboutSectionImageContainer" />

                <img className="AboutSectionImage-1" src="./images/aboutBrand.webp" alt="" style={{ opacity: 1 }} />

                <img className="AboutSectionImage-2" src="./images/aboutMarketing.webp" alt="" />

                <img className="AboutSectionImage-3" src="./images/aboutTechnology.webp" alt="" />
              </div>
            </div>
          </div>

          <div className="contentContainer" />
        </div>
      </div>

      <div className="inner">
        <div className="TabbedAboutCard AboutSectionCard-1">
          <div className="inner">
            <div className="imageParent" />

            <div className="contentContainer">
              <div className="inner">
                <div className="TiltContainer" data-speed="1" data-lag="0.2">
                  <div className="tilt">
                    <div
                      className="inner"
                      style={{ backgroundColor: 'rgb(255, 255, 255)', transform: 'rotate(1deg) skewX(-1deg)' }}
                    ></div>
                  </div>
                </div>

                <div className="title" data-speed="1" data-lag="0.2">
                  <div className="inner">
                    <div className="Button">
                      <div className="inner">
                        <div
                          className="TiltContainer buttonTilt"
                          style={{ backgroundColor: 'rgb(19, 18, 28)', transform: 'rotate(0deg) skewX(1deg)' }}
                        ></div>

                        <h2 className="font-h1 clrWhite"> Brand</h2>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="copy" data-speed="1" data-lag="0.2">
                  <div className="inner">
                    <p className="p-small">
                      It’s your reason for being and your point of difference. Internally and externally, universally
                      understood. Your brand is much, much more than a logo, slogan and colour palette. A successful
                      brand delivers immediate performance and value. It lowers your acquisition costs. Increases
                      conversion, marketing efficiency, retention and loyalty. When it’s joined up, it can transform
                      your entire business performance.
                    </p>

                    <div className="Button">
                      <div className="inner">
                        <a className="p-small" href={`/brand/`}>
                          {' '}
                          Find out more
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="TabbedAboutCard AboutSectionCard-2">
          <div className="inner">
            <div className="imageParent" />

            <div className="contentContainer">
              <div className="inner">
                <div className="TiltContainer" data-speed="1" data-lag="0.2">
                  <div className="tilt">
                    <div
                      className="inner"
                      style={{ backgroundColor: 'rgb(255, 255, 255)', transform: 'rotate(-1deg) skewX(0deg)' }}
                    ></div>
                  </div>
                </div>

                <div className="title" data-speed="1" data-lag="0.2">
                  <div className="inner">
                    <div className="Button">
                      <div className="inner">
                        <div
                          className="TiltContainer buttonTilt"
                          style={{ backgroundColor: 'rgb(19, 18, 28)', transform: 'rotate(-1deg) skewX(2deg)' }}
                        ></div>

                        <h2 className="font-h1 clrWhite"> Marketing</h2>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="copy" data-speed="1" data-lag="0.2">
                  <div className="inner">
                    <p className="p-small">
                      Our Advertising, Paid Media, SEO, Social, Data and Customer Experience teams join forces to ensure
                      your brand delivers the right message and your message delivers your brand. At the right time, on
                      the right platform, to the right people. We get to know you and your business. Your customers,
                      markets, competitors, and economics. We can then deliver marketing solutions to drive growth. With
                      elegant efficiency.
                    </p>

                    <div className="Button">
                      <div className="inner">
                        <a className="p-small" href={`/marketing/`}>
                          {' '}
                          Find out more
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="TabbedAboutCard AboutSectionCard-3 lastcard">
          <div className="inner">
            <div className="imageParent" />

            <div className="contentContainer">
              <div className="inner">
                <div className="TiltContainer" data-speed="1" data-lag="0.0">
                  <div className="tilt">
                    <div
                      className="inner"
                      style={{ backgroundColor: 'rgb(255, 255, 255)', transform: 'rotate(2deg) skewX(2deg)' }}
                    ></div>
                  </div>
                </div>

                <div className="title" data-speed="1" data-lag="0.0">
                  <div className="inner">
                    <div className="Button">
                      <div className="inner">
                        <div
                          className="TiltContainer buttonTilt"
                          style={{ backgroundColor: 'rgb(19, 18, 28)', transform: 'rotate(0deg) skewX(2deg)' }}
                        ></div>

                        <h2 className="font-h1 clrWhite"> Technology</h2>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="copy" data-speed="1" data-lag="0.0">
                  <div className="inner">
                    <p className="p-small">
                      Our world class design, engineering and technology team will ensure that your marketing not only
                      runs like a well-oiled machine, but that it wows your customers. With an experience that reflects
                      the spirit of your brand and delivers your service to the highest standard. Technology isn’t just
                      a website or an app. It’s the complete digital experience you offer your customers.
                    </p>

                    <div className="Button">
                      <div className="inner">
                        <a className="p-small" href={`/technology/`}>
                          {' '}
                          Find out more
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
