'use client';

import TiltContainer from '../TiltContainer/TiltContainer';
import { Link } from 'react-router-dom';
import { Fragment } from 'react';

interface ThirdLevelItem {
  title: string;
  link: string;
  className?: string;
}

interface SecondLevelItem {
  title: string;
  link: string;
  className?: string;
  children: Array<ThirdLevelItem>;
}

interface NavigationItemProps {
  title: string;
  link: string;
  className?: string;
  children: Array<SecondLevelItem>;
}

const NavigationItem: React.FC<NavigationItemProps> = ({ title, link, className, children }) => {
  return (
    <div className={`navigation-item ${className}`}>
      <div
        className="TopLevel"
        onClick={(event: any) => {
          if (window.innerWidth > 900 && children) return;
          event.preventDefault();
          event.stopPropagation();

          const containingItem: any = event.currentTarget.closest('.item');

          containingItem.classList.toggle('open');
        }}
      >
        <TiltContainer data={{ backgroundColor: '#131313', maxRotation: 2, maxSkew: 2 }} />

        <Link to={link} className={className} rel="noreferrer">
          {title}
        </Link>

        {children && (
          <div className="dropDownArrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
              <path
                d="M13.3489 1.435L7.34095 8.29033C7.16898 8.48656 6.83785 8.48656 6.66405 8.29033L0.656065 1.435C0.432869 1.17936 0.634111 0.804909 0.994517 0.804909L13.0105 0.804909C13.3709 0.804909 13.5721 1.17936 13.3489 1.435Z"
                fill="#13121C"
              />
            </svg>
          </div>
        )}
      </div>

      {children && (
        <div
          className="SecondLevel"
          onMouseLeave={(event: any) => {
            if (window.innerWidth <= 900) return;

            const secondLevelItems = event.currentTarget.querySelectorAll('.SecondLevelItem');
            const thirdLevenItems = event.currentTarget.querySelectorAll('.ThirdLevel');

            for (let i = 0; i < thirdLevenItems.length; i++) {
              thirdLevenItems[i].classList.remove('active');
            }

            for (let i = 0; i < secondLevelItems.length; i++) {
              secondLevelItems[i].classList.remove('active');
            }
          }}
        >
          <TiltContainer data={{ backgroundColor: '#131313', maxRotation: 1, maxSkew: 1 }} />

          <p
            className="mobile-title"
            onClick={(event: any) => {
              event.preventDefault();
              event.stopPropagation();

              const item: any = event.currentTarget.closest('.item');

              if (item) item.classList.remove('open');
            }}
          >
            <div className="dropDownArrow">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
                <path
                  d="M13.3489 1.435L7.34095 8.29033C7.16898 8.48656 6.83785 8.48656 6.66405 8.29033L0.656065 1.435C0.432869 1.17936 0.634111 0.804909 0.994517 0.804909L13.0105 0.804909C13.3709 0.804909 13.5721 1.17936 13.3489 1.435Z"
                  fill="#fad762"
                />
              </svg>
            </div>
            {title}
          </p>

          <div className="secondLevelItems">
            {children.map((secondLevelItem, index) => (
              <div
                key={index}
                className="SecondLevelItem"
                data-index={index}
                onMouseEnter={(event: any) => {
                  const index = event.currentTarget.getAttribute('data-index');
                  const SecondLevel = event.currentTarget.closest('.SecondLevel');
                  const secondLevelItems = SecondLevel.querySelectorAll('.SecondLevelItem');
                  const thirdLevenItems = SecondLevel.querySelectorAll('.ThirdLevel');
                  const targetThirdLevel = SecondLevel.querySelector(`.ThirdLevel[data-index="${index}"]`);

                  for (let i = 0; i < thirdLevenItems.length; i++) {
                    thirdLevenItems[i].classList.remove('active');
                  }

                  for (let i = 0; i < secondLevelItems.length; i++) {
                    secondLevelItems[i].classList.remove('active');
                  }

                  if (targetThirdLevel) targetThirdLevel.classList.add('active');

                  event.currentTarget.classList.add('active');
                }}
                onClick={(event: any) => {
                  if (window.innerWidth > 900 && secondLevelItem.children) return;
                  event.preventDefault();
                  event.stopPropagation();

                  const navigationItem = event.currentTarget.closest('.navigation-item');
                  const index = event.currentTarget.getAttribute('data-index');
                  const SecondLevel = event.currentTarget.closest('.SecondLevel');
                  const secondLevelItems = SecondLevel.getElementsByClassName('SecondLevelItem');
                  const ThirdLevelContainer = SecondLevel.getElementsByClassName('ThirdLevelContainer')[0];
                  const thirdLevenItems = SecondLevel.getElementsByClassName('ThirdLevel');

                  for (var a = 0; a < secondLevelItems.length; a++) {
                    secondLevelItems[a].classList.remove('active');
                  }

                  for (a = 0; a < thirdLevenItems.length; a++) {
                    thirdLevenItems[a].classList.remove('active');
                    if (parseInt(index) === a) thirdLevenItems[a].classList.add('active');
                  }

                  event.currentTarget.classList.add('active');

                  if (ThirdLevelContainer) {
                    ThirdLevelContainer.classList.toggle('open');
                  }
                }}
              >
                <TiltContainer data={{ backgroundColor: '#1f1e27', maxRotation: 1, maxSkew: 1 }} />

                <Link to={secondLevelItem.link} className={`${secondLevelItem.className}`} rel="noreferrer">
                  {secondLevelItem.title}
                </Link>

                {secondLevelItem.children && (
                  <div className="dropDownArrow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
                      <path
                        d="M13.3489 1.435L7.34095 8.29033C7.16898 8.48656 6.83785 8.48656 6.66405 8.29033L0.656065 1.435C0.432869 1.17936 0.634111 0.804909 0.994517 0.804909L13.0105 0.804909C13.3709 0.804909 13.5721 1.17936 13.3489 1.435Z"
                        fill="#fad762"
                      />
                    </svg>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div
            className="ThirdLevelContainer"
            style={{ backgroundImage: 'url(https://www.addmustard.com/images/navigation-image.webp)' }}
          >
            {children.map((secondLevelItem, index) => (
              <Fragment key={index}>
                {secondLevelItem.children && (
                  <div className="ThirdLevel" data-index={index}>
                    <p
                      className={`mobile-title ${secondLevelItem.className || ''}`}
                      onClick={(event: any) => {
                        event.preventDefault();
                        event.stopPropagation();

                        const ThirdLevelContainer: any = event.currentTarget.closest('.ThirdLevelContainer');
                        const items: any[] = ThirdLevelContainer.querySelectorAll('.ThirdLevel');

                        ThirdLevelContainer.classList.remove('open');

                        for (var a = 0; a < items.length; a++) {
                          items[a].classList.remove('active');
                        }
                      }}
                    >
                      {secondLevelItem.title}
                      <div className="dropDownArrow">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
                          <path
                            d="M13.3489 1.435L7.34095 8.29033C7.16898 8.48656 6.83785 8.48656 6.66405 8.29033L0.656065 1.435C0.432869 1.17936 0.634111 0.804909 0.994517 0.804909L13.0105 0.804909C13.3709 0.804909 13.5721 1.17936 13.3489 1.435Z"
                            fill="#fad762"
                          />
                        </svg>
                      </div>
                    </p>

                    {secondLevelItem.children.map((thirdLevelItem, index) => (
                      <Fragment key={index}>
                        {index === 0 && (
                          <Link
                            to={secondLevelItem.link}
                            className={`view-all ${secondLevelItem.className || ''}`}
                            rel="noreferrer"
                          >
                            View All
                          </Link>
                        )}

                        <div className="ThirdLevelItem">
                          <TiltContainer data={{ backgroundColor: '#1f1e27', maxRotation: 1, maxSkew: 1 }} />

                          <Link to={thirdLevelItem.link} className={thirdLevelItem.className} rel="noreferrer">
                            {thirdLevelItem.title}
                          </Link>
                        </div>
                      </Fragment>
                    ))}
                  </div>
                )}
              </Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default NavigationItem;
