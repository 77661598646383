import './Footer.scss';
import React from 'react';
import Button from '../Button/Button';

const TermsConditions: React.FC = () => {
  return (
    <section className="TermsConditions">
      <div className="outer">
        <div className="inner">
          <Button
            data={{
              buttonCustomClass: 'font-xSmall clrWhite font-weight-regular',
              buttonHasTilt: false,
              buttonLinkType: 'external',
              buttonLinkHref: 'https://www.addmustard.com/addmustard-ltd-client-terms-conditions-100119.pdf',
              buttonContent: 'Terms & Conditions',
              buttonOpensInNewTab: true,
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default TermsConditions;
