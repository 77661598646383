import './WhatWeDoPage.scss';
import React, { useState, useEffect } from 'react';
import IntroSection from './Sections/IntroSection';
import AboutSection from './Sections/AboutSection';
import PageHead from '../../Components/misc/PageHead';
import OurWorkSection from './Sections/OurWorkSection';
import LoadingContainer from '../../Components/LoadingContainers/LoadingContainer';
import { useLoadingRunContext, useToggleLoadingRunContext } from '../../Context/loadingContext';
import { initialiseSmoothScroll, uninitialisedSmoothScroll } from '../../Utilities/Functions/handleSmoothScroll';

const pageTitle: string = 'We join up Brand, Marketing & Technology | addmustard';
const pageDescription: string =
  'addmustard supports entrepreneurs with world-class creative, marketing & technology expertise. All joined up by what matters: numbers, performance & consistency.';
const pageCanonical: string = 'https://www.addmustard.com/what-we-do/';
const pageThemeColor: string = '#fad762';
const pageRobots: string = 'all';

const WhatWeDoPage: React.FC = () => {
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [pageOpacity, setPageOpacity] = useState<number>(0);
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false);
  const loadingRun: boolean = useLoadingRunContext();
  const toggleLoadingRun: () => void = useToggleLoadingRunContext();

  useEffect(() => {
    const setPage: () => void = () => {
      setPageLoaded(true);
      setPageOpacity(1);
      window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    };

    if (loadingRun) {
      setPage();
      return;
    }

    var stateCheck = setInterval(() => {
      if (document.readyState === 'complete') {
        clearInterval(stateCheck);
        setPage();
        setTimeout(() => {
          setLoadingComplete(true);
          toggleLoadingRun();
        }, 300);
      }
    }, 1700);

    return () => {};
  }, [loadingRun, toggleLoadingRun]);

  useEffect(() => {
    const stickyHeader = document.getElementById('stickyHeader');

    if (!stickyHeader) return;

    stickyHeader.classList.remove('active');
  }, []);

  useEffect(() => {
    const smoothScroller = initialiseSmoothScroll();

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);

    return () => {
      if (smoothScroller) uninitialisedSmoothScroll(smoothScroller);
    };
  }, []);

  return (
    <>
      <PageHead
        canonical={pageCanonical}
        description={pageDescription}
        robots={pageRobots}
        themeColor={pageThemeColor}
        title={pageTitle}
      />

      {loadingComplete || loadingRun ? <></> : <LoadingContainer loadingComplete={pageLoaded} />}

      <div className="pageBackground WhatWeDoPage"></div>

      <div id="WhatWeDoPage" style={{ opacity: pageOpacity, transition: 'opacity 300ms ease' }}>
        <div className="inner">
          <div className="Spacer" style={{ height: '150px', position: 'relative' }}>
            <div
              className="inner"
              style={{
                height: '110%',
                width: '300vw',
                position: 'absolute',
                top: 0,
                left: '-100vw',
                backgroundColor: '#FAD762',
                maxWidth: 'unset',
              }}
            />
          </div>

          <IntroSection />

          <div className="Spacer" style={{ height: '150px', position: 'relative' }}>
            <div
              className="inner"
              style={{
                height: '110%',
                width: '300vw',
                position: 'absolute',
                top: 0,
                left: '-100vw',
                backgroundColor: '#FAD762',
                maxWidth: 'unset',
              }}
            />
          </div>

          <AboutSection />

          <div className="Spacer" style={{ height: '150px', position: 'relative' }}>
            <div
              className="inner"
              style={{
                height: '110%',
                width: '300vw',
                position: 'absolute',
                top: 0,
                left: '-100vw',
                backgroundColor: '#FAD762',
                maxWidth: 'unset',
              }}
            />
          </div>

          <OurWorkSection />

          <div className="Spacer" style={{ height: '150px', position: 'relative' }}>
            <div
              className="inner"
              style={{
                height: '110%',
                width: '300vw',
                position: 'absolute',
                top: 0,
                left: '-100vw',
                backgroundColor: '#FAD762',
                maxWidth: 'unset',
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default WhatWeDoPage;
