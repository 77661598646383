import './Header.scss';
import Button from '../Button/Button';
import { useLocation } from 'react-router-dom';
import Tilt from '../TiltContainer/TiltContainer';
import NavigationItem from '../Button/NavigationItem';
import ImageContainer from '../ImageContainers/ImageContainer';
import { useLoadingRunContext, useToggleLoadingRunContext } from '../../Context/loadingContext';
import React, { useEffect, useState, useRef, useCallback, useMemo, useLayoutEffect } from 'react';

interface iHeaderNavigationStyle {
  gridTemplateColumns: string;
}

type headerType = 'light' | 'dark' | 'red' | 'pink' | 'blue';

const burgerMenuOpen_icon: string = require('../../Images/burgerMenuOpen_icon.svg').default;
const burgerMenuClose_icon: string = require('../../Images/burgerMenuClose_icon.svg').default;
const burgerMenuOpen_icon_red: string = require('../../Images/burgerMenuOpen_icon_red.svg').default;
const burgerMenuOpen_icon_pink: string = require('../../Images/burgerMenuOpen_icon_pink.svg').default;
const burgerMenuOpen_icon_blue: string = require('../../Images/burgerMenuOpen_icon_blue.svg').default;
const headerLogo: string = require('../../Images/ADM_Logo_Black.svg').default;
const headerLogoDark: string = require('../../Images/ADM_Logo_Mustard.svg').default;
const whatsAppIcon: string = require('../../Images/whatsApp_icon.svg').default;
const emailIcon: string = require('../../Images/email_icon.svg').default;

const navigationButtonData: Array<any> = [
  {
    title: 'What we do',
    link: `/what-we-do/`,
    className: 'what-we-do',
    children: [
      {
        title: 'Brand',
        link: `/brand/`,
        className: 'brand',
        children: [
          {
            title: 'Brand Communication',
            link: `/brand/brand-communication/`,
            className: '',
          },
          {
            title: 'Brand Development',
            link: `/brand/brand-development/`,
            className: '',
          },
          {
            title: 'Brand Planning',
            link: `/brand/brand-planning/`,
            className: '',
          },
        ],
      },
      {
        title: 'Marketing',
        link: `/marketing/`,
        className: 'marketing',
        children: [
          {
            title: 'Data & Analytics',
            link: `/marketing/data-analytics/`,
            className: '',
          },
          {
            title: 'Media Planning',
            link: `/marketing/media-planning/`,
            className: '',
          },
          {
            title: 'Creative',
            link: `/marketing/creative/`,
            className: '',
          },
          {
            title: 'Conversion Rate Optimisation',
            link: `/marketing/conversion-rate-optimisation/`,
            className: '',
          },
          {
            title: 'Paid Media',
            link: `/marketing/paid-media/`,
            className: '',
          },
          { title: 'SEO', link: `/marketing/seo/`, className: '' },
          {
            title: 'Social Media',
            link: `/marketing/social-media/`,
            className: '',
          },
          { title: 'Email', link: `/marketing/email/`, className: '' },
        ],
      },
      {
        title: 'Technology',
        link: `/technology/`,
        className: 'technology',
        children: [
          {
            title: 'Technology Development',
            link: `/technology/technology-development/`,
            className: '',
          },
          {
            title: 'UX Design',
            link: `/technology/ux-design/`,
            className: '',
          },
        ],
      },
    ],
  },
  // {
  //   title: 'Who we are',
  //   link: '/', //`/who-we-are/`,
  //   // children: [
  //   // {
  //   //   title: 'Who we are',
  //   //   link: '/', //`/who-we-are/`,
  //   // },
  //   // {
  //   //   title: 'The team',
  //   //   link: '/', // `/the-team/`,
  //   // },
  //   // {
  //   //   title: 'Join us',
  //   //   link: '/', // `/join-us/`,
  //   // },
  //   // ],
  // },
  {
    title: 'How can we help?',
    link: `/how-can-we-help/`,
  },
];

const blueVersionPathNameRegex: RegExp = /\/technology.*/;
const redVersionPathNameRegex: RegExp = /\/marketing.*/;
const pinkVersionPathNameRegex: RegExp = /\/brand.*/;
const darkVersionPathNameRegex: RegExp = /\/how-can-we-help.*/;

const Header: React.FC = () => {
  const location = useLocation();
  const [currentBurgerMenuIcon, setCurrentBurgerMenuIcon] = useState<string>(burgerMenuOpen_icon);
  const [currentCloseMenuIcon, setCurrentCloseMenuIcon] = useState<string>(burgerMenuClose_icon);
  const [navigationIsOpen, setNavigationIsOpen] = useState<boolean>(false);
  const [burgerMenuIcon, setBurgerMenuIcon] = useState<string>(currentBurgerMenuIcon);
  const [headerType, setHeaderType] = useState<headerType>('light');
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [pageOpacity, setPageOpacity] = useState<number>(0);
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false);
  const [currentWhatsAppIcon, setCurrentWhatsAppIcon] = useState<string>(whatsAppIcon);
  const [currentEmailIcon, setCurrentEmailIcon] = useState<string>(emailIcon);

  const mobileButtonData: Array<any> = useMemo(() => {
    return [
      {
        buttonHasTilt: true,
        buttonShowTiltOn: 'always',
        buttonTiltColor: '#FFFFFF',
        buttonTiltMaxRotation: 3,
        buttonTiltMaxSkew: 3,
        buttonCustomClass: 'clrWhite',
        buttonLinkType: 'external',
        buttonLinkHref: 'https://wa.me/447457404143',
        buttonContent: 'WhatsApp',
        buttonIcon: currentWhatsAppIcon,
        buttonOpensInNewTab: true,
      },
      {
        buttonHasTilt: true,
        buttonShowTiltOn: 'always',
        buttonTiltColor: '#FFFFFF',
        buttonTiltMaxRotation: 3,
        buttonTiltMaxSkew: 3,
        buttonCustomClass: 'clrWhite',
        buttonLinkType: 'external',
        buttonLinkHref: 'mailto: italladdsup@addmustard.com?subject=addmustard | Website Enquiry',
        buttonContent: 'Email',
        buttonIcon: currentEmailIcon,
      },
    ];
  }, [currentEmailIcon, currentWhatsAppIcon]);

  const loadingRun: boolean = useLoadingRunContext();
  const toggleLoadingRun: () => void = useToggleLoadingRunContext();

  const EffectTriggerRef: React.MutableRefObject<boolean> = useRef(false);

  const handleNavigationClose: () => void = useCallback(() => {
    setBurgerMenuIcon(currentCloseMenuIcon);
    setNavigationIsOpen(false);
  }, [currentCloseMenuIcon]);
  const handleHeaderOpacity: () => void = useCallback(() => {
    setPageLoaded(true);
  }, []);

  const burgerTilt: JSX.Element = useMemo(() => {
    return <Tilt data={{ maxRotation: 4, maxSkew: 4, backgroundColor: '#13121C' }} />;
  }, []);

  const logoContent: JSX.Element = useMemo(() => {
    return (
      <div className="logo">
        {headerType === 'dark' ? (
          <>
            <ImageContainer
              data={{
                imageSource: headerLogoDark,
                imageAlt: 'home button',
                imageHrefType: 'internalLink',
                imageHref: '/',
              }}
            />
            <ImageContainer
              data={{
                imageSource: headerLogoDark,
                imageAlt: 'home button',
                imageHrefType: 'internalLink',
                imageHref: '/',
              }}
            />
          </>
        ) : (
          <>
            <ImageContainer
              data={{ imageSource: headerLogo, imageAlt: 'home button', imageHrefType: 'internalLink', imageHref: '/' }}
            />
            <ImageContainer
              data={{
                imageSource: headerLogoDark,
                imageAlt: 'home button',
                imageHrefType: 'internalLink',
                imageHref: '/',
              }}
            />
          </>
        )}
      </div>
    );
  }, [headerType]);

  const mobileCTAButtons: JSX.Element[] = useMemo(() => {
    return mobileButtonData.map((item: any, key: number) => {
      return <Button key={key} data={item} />;
    });
  }, [mobileButtonData]);

  const navigationButtons: JSX.Element[] = useMemo(() => {
    return navigationButtonData.map((item: any, key: number) => {
      const isActivePath: boolean = item.link === location.pathname;

      return (
        <div key={key} className={isActivePath ? 'item active' : 'item'} onClick={handleNavigationClose}>
          <NavigationItem title={item.title} link={item.link} className={item.className} children={item.children} />
        </div>
      );
    });
  }, [handleNavigationClose, location]);

  const navigationContent: JSX.Element = useMemo(() => {
    const childrenCount: number = navigationButtonData.length;
    const navigationClass: string = navigationIsOpen ? 'navigation active' : 'navigation';
    const navigationStyle: iHeaderNavigationStyle = { gridTemplateColumns: 'repeat(' + childrenCount + ', auto)' };

    return (
      <nav className={navigationClass}>
        <div className="inner" style={navigationStyle}>
          {navigationButtons}
        </div>

        <div className="mobileCATContainer">
          <div className="inner">{mobileCTAButtons}</div>
        </div>
      </nav>
    );
  }, [navigationIsOpen, mobileCTAButtons, navigationButtons]);

  const burgerMenuButton: JSX.Element = useMemo(() => {
    const handleNavigationOpenClose: () => void = () => {
      const newBurgerMenuIcon: string = navigationIsOpen ? currentBurgerMenuIcon : currentCloseMenuIcon;
      setBurgerMenuIcon(newBurgerMenuIcon);
      setNavigationIsOpen(!navigationIsOpen);
    };

    return (
      <div
        className={navigationIsOpen ? 'burgerMenuContainer open' : 'burgerMenuContainer'}
        onClick={handleNavigationOpenClose}
      >
        {burgerTilt}

        <div className="inner">
          {navigationIsOpen ? (
            <div className="burgerMenu" style={{ backgroundImage: 'url(' + currentCloseMenuIcon + ')' }} />
          ) : (
            <div className="burgerMenu" style={{ backgroundImage: 'url(' + currentBurgerMenuIcon + ')' }} />
          )}
        </div>
      </div>
    );
  }, [navigationIsOpen, burgerTilt, currentBurgerMenuIcon, currentCloseMenuIcon]);

  useEffect(() => {
    if (EffectTriggerRef.current) return;

    EffectTriggerRef.current = true;
    window.addEventListener('resize', handleNavigationClose);
    window.addEventListener('load', handleHeaderOpacity);

    return () => {
      EffectTriggerRef.current = false;
      window.removeEventListener('resize', handleNavigationClose);
    };
  }, [handleNavigationClose, handleHeaderOpacity]);

  useEffect(() => {
    setNavigationIsOpen(false);
    var newHeaderType: headerType = 'light';

    if (darkVersionPathNameRegex.test(location.pathname)) {
      newHeaderType = 'dark';
    } else if (redVersionPathNameRegex.test(location.pathname)) {
      newHeaderType = 'red';
      setCurrentBurgerMenuIcon(burgerMenuOpen_icon_red);
    } else if (pinkVersionPathNameRegex.test(location.pathname)) {
      newHeaderType = 'pink';
      setCurrentBurgerMenuIcon(burgerMenuOpen_icon_pink);
    } else if (blueVersionPathNameRegex.test(location.pathname)) {
      newHeaderType = 'blue';
      setCurrentBurgerMenuIcon(burgerMenuOpen_icon_blue);
    } else {
      setCurrentBurgerMenuIcon(burgerMenuOpen_icon);
    }

    setHeaderType(newHeaderType);

    function resetStickyBannerPosition() {
      const stickyHeader = document.getElementById('stickyHeader');

      if (!stickyHeader) return;

      const navigation = stickyHeader.getElementsByClassName('navigation')[0];

      if (!navigation) return;

      const items = navigation.getElementsByClassName('item open');
      const ThirdLevelContainers = navigation.getElementsByClassName('ThirdLevelContainer open');

      for (var a = 0; a < items.length; a++) {
        items[a].classList.remove('open');
      }

      for (var b = 0; b < ThirdLevelContainers.length; b++) {
        ThirdLevelContainers[b].classList.remove('open');
      }

      if (window.scrollY > 5) stickyHeader.classList.remove('show');

      stickyHeader.classList.remove('active');

      return;
    }

    resetStickyBannerPosition();
  }, [location, currentCloseMenuIcon]);

  useLayoutEffect(() => {
    const setPage: () => void = () => {
      setPageLoaded(true);
      setPageOpacity(1);
      window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    };

    if (loadingRun) {
      setPage();
      return;
    }

    var stateCheck = setInterval(() => {
      if (document.readyState === 'complete') {
        clearInterval(stateCheck);
        setPage();
        setTimeout(() => {
          setLoadingComplete(true);
          toggleLoadingRun();
        }, 300);
      }
    }, 1700);

    return () => {};
  }, [loadingRun, toggleLoadingRun]);

  return (
    <section className={'Header animHeader ' + headerType}>
      <div className={loadingComplete || loadingRun ? 'outer loaded' : 'outer'}>
        <div className="inner">
          {logoContent}

          {navigationContent}

          {burgerMenuButton}
        </div>
      </div>
    </section>
  );
};

export default Header;
