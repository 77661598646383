import axios from 'axios';
import '../ServicePage.scss';
import USPSection from '../Sections/USPSection';
import IntroSection from '../Sections/IntroSection';
import Spacer from '../../../Components/Spacer/Spacer';
import PageHead from '../../../Components/misc/PageHead';
import JoinItUpSection from '../Sections/JoinItUpSection';
import BasicContentSection from '../Sections/BasicContentSection';
import React, { useState, useEffect } from 'react';
import ClientCarouselSection from '../Sections/ClientCarouselSection';
import PartnerCarouselSection from '../Sections/PartnerCarouselSection';
import LoadingContainer from '../../../Components/LoadingContainers/LoadingContainer';
import { useLoadingRunContext, useToggleLoadingRunContext } from '../../../Context/loadingContext';
import { initialiseSmoothScroll, uninitialisedSmoothScroll } from '../../../Utilities/Functions/handleSmoothScroll';

const pageCanonicalHref: string = 'https://www.addmustard.com/marketing/email/';
const pageTitle: string = 'Email Services for Startups & Entrepreneurs | addmustard';
const pageDescription: string =
  'We support entrepreneurs with world-class email and CRM services. All joined up by what matters: creativity, performance & consistency.';
const pageThemeColor: string = '#F27250';
const pageRobots: string = 'all';

const Email: React.FC = () => {
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [pageOpacity, setPageOpacity] = useState<number>(0);
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false);
  const [postData, setPostData] = useState<any>({});
  const loadingRun: boolean = useLoadingRunContext();
  const toggleLoadingRun: () => void = useToggleLoadingRunContext();

  useEffect(() => {
    const setPage: () => void = () => {
      setPageLoaded(true);
      setPageOpacity(1);
      window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    };

    if (loadingRun) {
      setPage();
      return;
    }

    var stateCheck = setInterval(() => {
      if (document.readyState === 'complete') {
        clearInterval(stateCheck);
        setPage();
        setTimeout(() => {
          setLoadingComplete(true);
          toggleLoadingRun();
        }, 300);
      }
    }, 1700);

    return () => {};
  }, [loadingRun, toggleLoadingRun]);

  useEffect(() => {
    axios.get('https://www.addmustard.com/wp-json/acf/v3/marketing/83144').then(response => {
      const postDetails = response.data.acf.post_details;

      setPostData(postDetails);
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 500);
    });
  }, []);

  useEffect(() => {
    const stickyHeader = document.getElementById('stickyHeader');

    if (!stickyHeader) return;

    stickyHeader.classList.remove('active');
  }, []);

  useEffect(() => {
    const smoothScroller = initialiseSmoothScroll();

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);

    return () => {
      if (smoothScroller) uninitialisedSmoothScroll(smoothScroller);
    };
  }, []);

  return (
    <>
      <PageHead
        canonical={pageCanonicalHref}
        description={pageDescription}
        robots={pageRobots}
        themeColor={pageThemeColor}
        title={pageTitle}
      />

      {loadingComplete || loadingRun ? <></> : <LoadingContainer loadingComplete={pageLoaded} />}

      <div className="pageBackground" style={{ backgroundColor: '#F27250' }}></div>

      <div
        id="ServiceChild"
        style={{
          opacity: pageOpacity,
          transition: 'opacity 300ms ease',
          backgroundColor: '#F27250',
        }}
      >
        <div className="outer">
          <div className="inner">
            <Spacer minHeight={220} maxHeight={300} desiredHeight={30} backgroundColor="#F27250" />

            <IntroSection
              image={{ imageSrc: postData.intro?.image, imageAlt: postData.intro?.imageAlt, isLazy: false }}
              title={postData.intro?.title}
              content={postData.intro?.copy}
              quote={postData?.intro?.quote || ''}
              quoteAuthor={postData?.intro?.quoteAuthor || ''}
            />

            <Spacer minHeight={100} maxHeight={350} desiredHeight={30} backgroundColor="#F27250" />

            <USPSection backgroundImageSrc={postData?.usp?.image || ''} USPs={postData?.usp?.items || []} />

            <Spacer minHeight={150} maxHeight={200} desiredHeight={30} backgroundColor="#F27250" />

            {postData?.content_blocks?.items?.map((contentBlock: any, key: number) => {
              const type: string = contentBlock.type;
              const copy: string = contentBlock.copy;
              const title: string = contentBlock.title;
              const list: any = contentBlock.list;
              const quote: string = contentBlock.quote;

              if (type === 'with_quote') {
                return (
                  <div key={key}>
                    {key > 0 && <Spacer minHeight={50} maxHeight={150} desiredHeight={30} backgroundColor="#F27250" />}

                    <BasicContentSection>
                      <h2 className="font-h1" dangerouslySetInnerHTML={{ __html: title }} />

                      <p className="p-small" dangerouslySetInnerHTML={{ __html: copy }} />
                    </BasicContentSection>

                    <Spacer minHeight={25} maxHeight={50} desiredHeight={30} backgroundColor="#F27250" />

                    <BasicContentSection>
                      <div className="QuoteContainer">
                        <p className="font-h5 italic" dangerouslySetInnerHTML={{ __html: quote }} />
                      </div>
                    </BasicContentSection>
                  </div>
                );
              } else if (type === 'with_list') {
                return (
                  <div key={key}>
                    {key > 0 && <Spacer minHeight={50} maxHeight={150} desiredHeight={30} backgroundColor="#F27250" />}

                    <BasicContentSection>
                      <h2 className="font-h1" dangerouslySetInnerHTML={{ __html: title }} />

                      <p className="p-small" dangerouslySetInnerHTML={{ __html: copy }} />
                    </BasicContentSection>

                    <Spacer minHeight={25} maxHeight={50} desiredHeight={30} backgroundColor="#F27250" />

                    <BasicContentSection>
                      <ul className="ServicesGrid">
                        {list.map((listItem: any, key: number) => {
                          return (
                            <li key={key}>
                              <p className="font-h5">{listItem?.title}</p>
                            </li>
                          );
                        })}
                      </ul>
                    </BasicContentSection>
                  </div>
                );
              } else {
                return (
                  <div key={key}>
                    {key > 0 && <Spacer minHeight={50} maxHeight={150} desiredHeight={30} backgroundColor="#F27250" />}

                    <BasicContentSection>
                      <h2 className="font-h1" dangerouslySetInnerHTML={{ __html: title }} />

                      <p className="p-small" dangerouslySetInnerHTML={{ __html: copy }} />
                    </BasicContentSection>
                  </div>
                );
              }
            })}

            <Spacer minHeight={100} maxHeight={200} desiredHeight={30} backgroundColor="#F27250" />

            <JoinItUpSection
              backgroundImageSrc={postData?.services_overview?.image || '../../images/joinItUp.webp'}
              title={postData?.services_overview?.title || ''}
              titleBackgroundColor="#F27250"
              content={postData?.services_overview?.copy || ''}
              buttons={[
                {
                  content: 'Brand',
                  link: `/brand/`,
                  tiltColor: '#F0BEC9',
                  isActive: false,
                },
                {
                  content: 'Marketing',
                  link: `/marketing/`,
                  tiltColor: '#F27250',
                  isActive: true,
                },
                {
                  content: 'Technology',
                  link: `/technology/`,
                  tiltColor: '#84A9B5',
                  isActive: false,
                },
              ]}
            />

            <ClientCarouselSection
              clientItems={postData?.client_carousel?.items || []}
              titleBackgroundColor="#F27250"
            />
            {postData?.partner_carousel?.items ? (
              <PartnerCarouselSection
                partnerItems={postData?.partner_carousel?.items || []}
                titleBackgroundColor="#F27250"
              />
            ) : (
              <Spacer minHeight={50} maxHeight={150} desiredHeight={30} backgroundColor="#F27250" />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Email;
