import Button from '../Button/Button';
import ImageContainer from '../ImageContainers/ImageContainer';
import React, { useState, useCallback, useRef, useEffect } from 'react';

interface AutoScrollCarouselProps {
  carouselItems: any[];
}

const defaultTransitionTime: number = 60000;
const defaultTransitionTimeMobile: number = 30000;

const AutoScrollCarousel: React.FC<AutoScrollCarouselProps> = ({ carouselItems }) => {
  const [transitionTime, setTransitionTime] = useState<number>(defaultTransitionTime);
  const [carouselPaused, setCarouselPaused] = useState<boolean>(false);
  const [translateCount, setTranslateCount] = useState<number>(1);
  const [dragging, setDragging] = useState<boolean>(false);
  const [translateX, setTranslateX] = useState<number>(0);
  const [dragStart, setDragStart] = useState<number>(0);
  const carouselRef = useRef<HTMLDivElement>(null);
  const getCarouselItems: (repeat: number) => JSX.Element = useCallback(
    (repeat: number) => {
      const carouselInterior: JSX.Element = (
        <div style={{ gridTemplateColumns: `repeat(${carouselItems.length}, auto)` }}>
          {carouselItems.map((item: any, key: number) => {
            return (
              <React.Fragment key={key}>
                {item.image_link ? (
                  <a href={item.image_link} rel="noopener noreferrer" className="linkWrapper">
                    {item.image_alt || ''}
                  </a>
                ) : (
                  <></>
                )}

                <ImageContainer
                  data={{ imageSource: item.item_image || '', imageAlt: item.image_alt || '', imageIsLazy: true }}
                />
              </React.Fragment>
            );
          })}
        </div>
      );
      var repeatArray: number[] = [];

      for (var a: number = 0; a < repeat; a++) {
        repeatArray.push(a);
      }

      return (
        <>
          {repeatArray.map((value: number) => {
            return <React.Fragment key={value}>{carouselInterior}</React.Fragment>;
          })}
        </>
      );
    },
    [carouselItems],
  );
  const getCarouselItemWidth: () => number = useCallback(() => {
    const carouselFirstChild = carouselRef.current?.children[0] as HTMLDivElement;
    const carouselFirstChildWidth = carouselFirstChild.offsetWidth;

    return carouselFirstChildWidth;
  }, []);
  const handleCarouselMouseDown: (event: any) => void = useCallback((event: any) => {
    const carouselInner = carouselRef.current as HTMLDivElement;
    const currentTranslateX = Math.abs(
      parseInt(window.getComputedStyle(carouselInner, null).getPropertyValue('translate')),
    );
    setTranslateX(currentTranslateX);
    setDragging(true);
    setCarouselPaused(true);
    setDragStart(event.clientX || event.touches[0].clientX);
    setTransitionTime(0);
  }, []);
  const handleCarouselMouseUp: () => void = useCallback(() => {
    setDragging(false);
    setCarouselPaused(false);
    setTranslateX(getCarouselItemWidth() * translateCount);
    setTransitionTime(defaultTransitionTime);
    setTranslateCount(translateCount => {
      return translateCount;
    });
  }, [translateCount, getCarouselItemWidth]);
  const handleCarouselMouseMove: (event: any) => void = useCallback(
    (event: any) => {
      if (dragging) {
        const dragEnd = event.clientX || event.touches[0].clientX;
        const dragDistance = 1 * (dragEnd - dragStart);
        setDragStart(dragEnd);

        setTranslateX(translateX => {
          const newTranslateX = translateX - dragDistance;
          return newTranslateX;
        });
      }
    },
    [dragStart, dragging],
  );

  useEffect(() => {
    setTranslateX(getCarouselItemWidth());
  }, [getCarouselItemWidth]);

  useEffect(() => {
    const translateInterval = setInterval(() => {
      if (!carouselPaused) {
        setTranslateX(getCarouselItemWidth() * (translateCount + 1));
        setTranslateCount(translateCount => {
          return translateCount + 1;
        });
      }
    }, defaultTransitionTime);

    return () => {
      clearInterval(translateInterval);
    };
  }, [carouselPaused, getCarouselItemWidth, translateCount]);

  useEffect(() => {
    const changeTransitionTime = () => {
      if (window.innerWidth < 768) {
        setTransitionTime(defaultTransitionTimeMobile);
      } else {
        setTransitionTime(defaultTransitionTime);
      }
    };

    changeTransitionTime();
    window.addEventListener('resize', changeTransitionTime);

    return () => {
      window.removeEventListener('resize', changeTransitionTime);
    };
  }, []);

  return (
    <section className="auto-scroll-client-carousel">
      <div className="background" />

      <div className="inner">
        <h3 className="title font-h1" data-speed="1" data-lag="0.1">
          Where we've{' '}
          <Button
            data={{
              buttonHasTilt: true,
              buttonShowTiltOn: 'always',
              buttonTiltColor: '#fad762',
              buttonTiltMaxRotation: 4,
              buttonTiltMaxSkew: 4,
              buttonCustomClass: 'clrBlack font-h1',
              buttonLinkType: 'none',
              buttonContent: 'added value',
            }}
          />
        </h3>

        <div
          draggable={true}
          className={`carousel ${dragging ? 'dragging' : ''}`}
          onMouseDown={(e: any) => {
            e.preventDefault();
            handleCarouselMouseDown(e);
          }}
          onTouchStart={(e: any) => {
            e.preventDefault();
            handleCarouselMouseDown(e);
          }}
          onMouseUp={() => {
            handleCarouselMouseUp();
          }}
          onMouseLeave={() => {
            handleCarouselMouseUp();
          }}
          onTouchEnd={() => {
            handleCarouselMouseUp();
          }}
          onTouchCancel={() => {
            handleCarouselMouseUp();
          }}
          onMouseMove={(e: any) => {
            handleCarouselMouseMove(e);
          }}
          onTouchMove={(e: any) => {
            handleCarouselMouseMove(e);
          }}
        >
          <div className="background left" />
          <div className="background right" />
          <div
            ref={carouselRef}
            className="inner grid"
            style={{
              gridTemplateColumns: `repeat(${translateCount + 1}, auto)`,
              translate: `-${translateX}px 0`,
              transition: `translate ${transitionTime}ms linear`,
            }}
          >
            {getCarouselItems(translateCount + 1)}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AutoScrollCarousel;
