import './Button.scss';
import React, { useMemo } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import generateRandomNumber from '../../Utilities/Functions/generateRandomNumber';

interface IButton {
  data: {
    buttonHasTilt: boolean;
    buttonShowTiltOn?: 'hover' | 'always';
    buttonTiltColor?: string;
    buttonTiltMaxRotation?: number;
    buttonTiltMaxSkew?: number;
    buttonCustomClass?: string;
    buttonLinkType: 'internal' | 'external' | 'function' | 'none';
    buttonLinkHref?: string;
    buttonLinkFunction?: (event?: any) => void;
    buttonContent?: string;
    buttonIcon?: string;
    buttonOpensInNewTab?: boolean;
  };
}
interface ITiltValues {
  rotation: number;
  skew: number;
}
interface ITiltStyle {
  backgroundColor: string;
  transform: string;
}

const Button: React.FC<IButton> = (props: IButton) => {
  const hasTilt: boolean = props.data.buttonHasTilt || false;
  const showTiltOn: 'hover' | 'always' = props.data.buttonShowTiltOn || 'always';
  const tiltColor: string = props.data.buttonTiltColor || '#13121C';
  const tiltMaxRotation: number = props.data.buttonTiltMaxRotation || 0;
  const tiltMaxSkew: number = props.data.buttonTiltMaxSkew || 0;
  const customClass: string = props.data.buttonCustomClass || '';
  const linkType: 'internal' | 'external' | 'function' | 'none' = props.data.buttonLinkType || 'none';
  const linkHref: string | LinkProps['to'] = props.data.buttonLinkHref || '';
  const linkFunction: (() => void) | undefined = props.data.buttonLinkFunction;
  const content: string = props.data.buttonContent || '';
  const icon: string = props.data.buttonIcon || '';
  const opensInNewTab: boolean = props.data.buttonOpensInNewTab || false;

  const tiltRotation: number = useMemo(() => {
    return generateRandomNumber(-1 * tiltMaxRotation, tiltMaxRotation) * Math.pow(-1, generateRandomNumber(1, 2));
  }, [tiltMaxRotation]);
  const tiltSkew: number = useMemo(() => {
    return generateRandomNumber(-1 * tiltMaxSkew, tiltMaxSkew) * Math.pow(-1, generateRandomNumber(1, 2));
  }, [tiltMaxSkew]);
  const generateTilt: JSX.Element = useMemo(() => {
    const tiltValues: ITiltValues = { rotation: tiltRotation, skew: tiltSkew };
    const tiltStyle: ITiltStyle = {
      backgroundColor: tiltColor,
      transform: 'rotate(' + tiltValues.rotation + 'deg) skewX(' + tiltValues.skew + 'deg)',
    };

    return <div className="TiltContainer" style={tiltStyle} />;
  }, [tiltRotation, tiltSkew, tiltColor]);
  const generateButton: JSX.Element = useMemo(() => {
    const buttonInterior: JSX.Element = (
      <>
        {icon ? <img src={icon} alt="button icon" /> : <></>} {content}
      </>
    );
    const buttonClass: string = linkType === 'none' ? customClass + ' cursor-default' : customClass;

    if (linkType === 'internal') {
      return (
        <Link to={linkHref} className={buttonClass} target={opensInNewTab ? '_blank' : '_self'} rel="noreferrer">
          {buttonInterior}
        </Link>
      );
    }

    if (linkType === 'external') {
      return (
        <a href={linkHref} className={buttonClass} target={opensInNewTab ? '_blank' : '_self'} rel="noreferrer">
          {buttonInterior}
        </a>
      );
    }

    if (linkType === 'function') {
      return (
        <p onClick={linkFunction} className={buttonClass}>
          {buttonInterior}
        </p>
      );
    }

    return <p className={buttonClass}>{buttonInterior}</p>;
  }, [content, customClass, linkFunction, linkHref, linkType, icon, opensInNewTab]);
  const buttonClass: string = useMemo(() => {
    return showTiltOn === 'hover' ? 'Button tiltHover' : 'Button';
  }, [showTiltOn]);

  return (
    <div className={buttonClass}>
      <div className="inner">
        {hasTilt ? generateTilt : <></>}

        {generateButton}
      </div>
    </div>
  );
};

export default Button;
