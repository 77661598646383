import './TiltContainer.scss';
import React, { useMemo } from 'react';
import generateRandomNumber from '../../Utilities/Functions/generateRandomNumber';
import { TiltInterface, TiltStyleInterface, TiltValueInterface } from './TiltContainerInterfaces';

const TiltContainer: React.FC<TiltInterface> = (props: TiltInterface) => {
  const maxRotation: number = props.data.maxRotation;
  const maxSkew: number = props.data.maxSkew;
  const backgroundColor: string = props.data.backgroundColor;

  const tiltValues: TiltValueInterface = useMemo(() => {
    const rotation: number =
      generateRandomNumber(-1 * maxRotation, maxRotation) * Math.pow(-1, generateRandomNumber(1, 2));
    const skew: number = generateRandomNumber(-1 * maxSkew, maxSkew) * Math.pow(-1, generateRandomNumber(1, 2));
    const tiltValues: TiltValueInterface = {
      rotation: rotation,
      skew: skew,
    };

    return tiltValues;
  }, [maxRotation, maxSkew]);

  const tiltStyle: TiltStyleInterface = {
    backgroundColor: backgroundColor,
    transform: 'rotate(' + tiltValues.rotation + 'deg) skewX(' + tiltValues.skew + 'deg)',
  };

  return (
    <div className="TiltContainer">
      <div className="tilt">
        <div className="inner" style={tiltStyle} />
      </div>
    </div>
  );
};

export default TiltContainer;
