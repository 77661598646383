import React, { useRef, useMemo } from 'react';
import Button from '../../../Components/Button/Button';
import { caseStudies } from '../../../Data/caseStudies';
import SlideCarousel from '../../../Components/Carousels/SlideCarousel';
import SlideCarouselBlogCard from '../../../Components/Carousels/Cards/SlideCarouselBlogCard';

const OurWorkSection: React.FC = () => {
  const OurWorkSectionRef: React.MutableRefObject<any> = useRef(null);

  const content: JSX.Element[] = useMemo(() => {
    return caseStudies.map((caseStudy: any, key: number) => {
      return (
        <SlideCarouselBlogCard
          key={key}
          data={{
            cardLogoImage: caseStudy.featureImage,
            cardImage: caseStudy.coverImage,
            cardLinkType: caseStudy.linkType,
            cardLinkHref: caseStudy.linkHref,
            cardBackgroundColor: caseStudy.backgroundColor,
          }}
        >
          {caseStudy.excerpt ? (
            <p
              dangerouslySetInnerHTML={{ __html: caseStudy.excerpt }}
              className="font-h5 clrWhite font-weight-regular"
            />
          ) : (
            <></>
          )}
        </SlideCarouselBlogCard>
      );
    });
  }, []);

  return (
    <section ref={OurWorkSectionRef} className="OurWorkSection">
      <div className="inner">
        <div className="titleContainer">
          <h2>
            Our{' '}
            <Button
              data={{
                buttonHasTilt: true,
                buttonTiltColor: '#13121C',
                buttonTiltMaxRotation: 2,
                buttonTiltMaxSkew: 2,
                buttonCustomClass: 'clrMustard font-h2',
                buttonShowTiltOn: 'always',
                buttonLinkType: 'none',
                buttonContent: 'Work',
              }}
            />
          </h2>
        </div>

        <SlideCarousel
          data={{
            carouselHasArrows: true,
            carouselHasDots: false,
            carouselViewAllCopy: 'View more',
            carouselViewAllType: 'external',
            carouselViewAllHref: 'https://www.addmustard.com/our-work/',
          }}
        >
          {content}
        </SlideCarousel>
      </div>
    </section>
  );
};

export default OurWorkSection;
