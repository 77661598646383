import { Helmet } from 'react-helmet-async';

interface PageHeadProps {
  title: string;
  description: string;
  canonical?: string;
  robots: string;
  themeColor: string;
}

const PageHead: React.FC<PageHeadProps> = ({ title, description, canonical, robots, themeColor }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="robots" content={robots} data-rh="true" />
      <meta name="theme-color" content={themeColor} data-rh="true" />
      {canonical && <link rel="canonical" href={canonical} />}
    </Helmet>
  );
};

export default PageHead;
