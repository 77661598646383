import { gsap } from 'gsap';
import { LinkProps } from 'react-router-dom';
import Button from '../../../Components/Button/Button';
import React, { useRef, useEffect, useMemo } from 'react';
import ImageContainer from '../../../Components/ImageContainers/ImageContainer';
import AnimationWrapper from '../../../Components/misc/AnimationWrapper';

const IntroSection: React.FC = () => {
  const titleTwo: string = 'And then you get us.';
  const copyOne: string =
    'Take technology.<br/>Add marketing nous,<br/>creativity and a healthy<br/>dose of entrepreneurial spirit.<br/>Take away smoke, mirrors<br/>and arrogance.';
  const ctaOneCopy: string = 'How can we help?';
  const ctaOneType: 'internal' | 'external' | 'function' | 'none' = 'external';
  const ctaOneLink: string | LinkProps['to'] = `/how-can-we-help/`;
  const backgroundImageOne: any = './images/introSection_1.webp';
  const backgroundImageTwo: any = './images/introSection_3.webp';

  const introSectionRef: React.MutableRefObject<any> = useRef(null);

  const imageOne: JSX.Element = useMemo(() => {
    return <ImageContainer data={{ imageSource: backgroundImageOne, imageIsLazy: true }} />;
  }, []);
  const imageTwo: JSX.Element = useMemo(() => {
    return <ImageContainer data={{ imageSource: backgroundImageTwo, imageIsLazy: true }} />;
  }, []);

  useEffect(() => {
    var textAnimationThree = gsap.to('.textSectionThree', {
      opacity: 1,
      y: 0,
      ease: 'none',
      scrollTrigger: {
        trigger: '.topSection',
        start: '0px -100px',
        end: '300px',
        scrub: 0,
      },
    });

    var textAnimationFour = gsap.to('.textSectionFour', {
      opacity: 1,
      y: 0,
      ease: 'none',
      scrollTrigger: {
        trigger: '.topSection',
        start: '0px -200px',
        end: '400px',
        scrub: 0,
      },
    });

    var imageAnimationOne = gsap.to('.backgroundImageOne', {
      opacity: 0.3,
      ease: 'none',
      scrollTrigger: {
        trigger: '.topSection',
        start: '000px 100px',
        end: '400px',
        scrub: 0.5,
      },
    });

    return () => {
      textAnimationThree.kill();
      textAnimationFour.kill();
      imageAnimationOne.kill();
    };
  }, []);

  return (
    <section ref={introSectionRef} className="IntroSection">
      <div className="inner">
        <div className="topSection">
          <div className="inner">
            <div className="backgroundImage backgroundImageOne">
              <div className="inner" data-speed="1.4" data-lag="0">
                {imageOne}
              </div>
            </div>

            <div className="contentContainer">
              <div className="inner">
                <div data-speed="1" data-lag="0.3">
                  <h1>
                    <Button
                      data={{
                        buttonHasTilt: true,
                        buttonShowTiltOn: 'always',
                        buttonTiltColor: '#13121C',
                        buttonTiltMaxRotation: 4,
                        buttonTiltMaxSkew: 4,
                        buttonCustomClass: 'clrMustard font-h1',
                        buttonLinkType: 'none',
                        buttonContent: 'Add',
                      }}
                    />
                    <span className="boldMe">
                      {' '}
                      the strategic and creative fire-power of an advertising agency, to the precision and incision of a
                      digital marketing agency, to the flexibility of an in-house team.
                    </span>
                    <span className="boldMe textSectionThree hide-mobile">
                      {' '}
                      Add a unique understanding of the opportunities and pressures of entrepreneurial businesses.
                    </span>
                  </h1>

                  <span className="textSectionFour">
                    <Button
                      data={{
                        buttonHasTilt: true,
                        buttonShowTiltOn: 'always',
                        buttonTiltColor: '#13121C',
                        buttonTiltMaxRotation: 2,
                        buttonTiltMaxSkew: 2,
                        buttonCustomClass: 'clrMustard font-h1 ',
                        buttonLinkType: 'none',
                        buttonLinkHref: '',
                        buttonLinkFunction: () => {},
                        buttonContent: titleTwo,
                        buttonIcon: '',
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottomSection">
          <div className="inner">
            <div className="backgroundImage">
              <div className="inner" data-speed="1.2" data-lag="0">
                {/* <AnimationWrapper> */}
                {imageTwo}
                {/* </AnimationWrapper> */}
              </div>
            </div>

            <div className="contentContainer">
              <div className="inner">
                <p className="p-large" dangerouslySetInnerHTML={{ __html: copyOne }} />

                <Button
                  data={{
                    buttonHasTilt: true,
                    buttonShowTiltOn: 'always',
                    buttonTiltColor: '#13121C',
                    buttonTiltMaxRotation: 2,
                    buttonTiltMaxSkew: 2,
                    buttonCustomClass: 'clrMustard',
                    buttonLinkType: ctaOneType,
                    buttonLinkHref: ctaOneLink,
                    buttonContent: ctaOneCopy,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntroSection;
