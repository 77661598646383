export interface blogPost {
  excerpt: string;
  coverImage: string;
  cardImageAlt: string;
  relatedDepartments: string[];
  linkCopy: string;
  linkType: string;
  linkHref: string;
  backgroundColor: string;
}

export const blogPosts: blogPost[] = [
  {excerpt:"Your website. It’s a bit of an animal",coverImage:"./images/ADM_Blog_Animal.webp",cardImageAlt:"",relatedDepartments:["Technology"],linkCopy:"Read more",linkType:"external",linkHref:"https://www.addmustard.com/blog/your-website-its-a-bit-of-an-animal/",backgroundColor:"#000"},
  {excerpt:"How many TikTok followers would Queen Elizabeth I have had?",coverImage:"./images/ADM_Blog_Tiktok.webp",cardImageAlt:"",relatedDepartments:["Brand"],linkCopy:"Read more",linkType:"external",linkHref:"https://www.addmustard.com/blog/how-many-tiktok-followers-would-queen-elizabeth-i-have-had/",backgroundColor:"#000"},
  {excerpt:"Keep it simple, stupid (KISS)",coverImage:"./images/blog-image-3.webp",cardImageAlt:"",relatedDepartments:["Marketing"],linkCopy:"Read more",linkType:"external",linkHref:"https://www.addmustard.com/blog/keep-it-simple-stupid/",backgroundColor:"#000"},
];
