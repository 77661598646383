export interface caseStudy {
  excerpt: string;
  featureImage: string;
  coverImage: string;
  linkType: string;
  linkHref: string;
  backgroundColor: string;
}

export const caseStudies: caseStudy[] = [
  {
    excerpt:
      "Launch the world's first sustainable football boot brand. Add a<span class='tilt' style='background-color: #84A9B5;'>unique marketing and tech experience.</span> Marvel as awareness and sales flourish.",
    featureImage: '../images/SOK_logo.webp',
    coverImage: '../images/ADM_SOK_caseStudyImage.webp',
    linkType: 'external',
    linkHref: 'https://www.addmustard.com/our-work/sokito/',
    backgroundColor: '#13121C',
  },
  {
    excerpt:
      "Take a luxury, retail brand. <span class='tilt' style='background-color: #F27250;'>Turbocharge it online.</span> Watch sales and margins grow.",
    featureImage: '../images/ASP_logo.webp',
    coverImage: '../images/ASP_blogImage.webp',
    linkType: 'external',
    linkHref: 'https://www.addmustard.com/our-work/aspinal-of-london/',
    backgroundColor: '#13121C',
  },
  {
    excerpt:
      "Launch an industry-first brand platform. Join it up with marketing and tech. <span class='tilt' style='background-color: rgb(240, 190, 201);'>And watch it all come to life.</span>",
    featureImage: '../images/FCG_logo.webp',
    coverImage: '../images/ADM_Site-CS_FCG.webp',
    linkType: 'external',
    linkHref: 'https://www.addmustard.com/our-work/focus-group/',
    backgroundColor: '#13121C',
  },
  {
    excerpt:
      "Take a wonderful brand. <span class='tilt' style='background-color: #84A9B5;'>Add an improved online experience.</span> And get a healthy increase in marketing efficiency and brand value.",
    featureImage: '../images/MAP_logo.webp',
    coverImage: '../images/MAP_blogImage.webp',
    linkType: 'external',
    linkHref: 'https://www.addmustard.com/our-work/marsh-and-parsons/',
    backgroundColor: '#13121C',
  },
  {
    excerpt:
      'Take an award-winning, luxury travel operator. Add industry-leading technology and content. <span class="tilt" style="background-color: #F27250;">Bag a 700% increase in visibility</span>.',
    featureImage: '../images/TLT-logo.webp',
    coverImage: '../images/TLT_blogImage.webp',
    linkType: 'external',
    linkHref: 'https://www.addmustard.com/our-work/tully-luxury-travel/',
    backgroundColor: '#13121C',
  },
  {
    excerpt:
      'Take an innovative fintech business. Add differentiation in a rapidly growing sector. <span class="tilt" style="background-color: rgb(240, 190, 201);">Increase brand value 8-fold<span>.',
    featureImage: '../images/LRX-logo.webp',
    coverImage: '../images/LRX_blogImage.webp',
    linkType: 'external',
    linkHref: 'https://www.addmustard.com/our-work/lerex-technology/',
    backgroundColor: '#13121C',
  },
];
