import React, { useContext, useState } from 'react';

interface iLoadingProvider {
  children: JSX.Element | JSX.Element[];
}

const loadingRunContext: React.Context<boolean> = React.createContext(false);
const toggleLoadingRunContext: React.Context<() => void> = React.createContext(() => {});

export const useLoadingRunContext: () => boolean = () => {
  return useContext(loadingRunContext);
};
export const useToggleLoadingRunContext: () => () => void = () => {
  return useContext(toggleLoadingRunContext);
};

export const LoadingProvider: React.FC<iLoadingProvider> = (props: iLoadingProvider) => {
  const [loadingRun, setLoadingRun] = useState<boolean>(false);

  const toggleLoadingRun: () => void = () => {
    setLoadingRun(!loadingRun);
  };

  return (
    <loadingRunContext.Provider value={loadingRun}>
      <toggleLoadingRunContext.Provider value={toggleLoadingRun}>{props.children}</toggleLoadingRunContext.Provider>
    </loadingRunContext.Provider>
  );
};
