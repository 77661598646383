import './Button.scss';
import React, { useMemo } from 'react';
import generateRandomNumber from '../../Utilities/Functions/generateRandomNumber';

interface ILoadingButton {
  data: {
    buttonHasTilt: boolean;
    buttonShowTiltOn?: 'hover' | 'always';
    buttonTiltColor?: string;
    buttonTiltMaxRotation?: number;
    buttonTiltMaxSkew?: number;
  };
}
interface ITiltValues {
  rotation: number;
  skew: number;
}
interface ITiltStyle {
  backgroundColor: string;
  transform: string;
}

const LoadingButton: React.FC<ILoadingButton> = (props: ILoadingButton) => {
  const hasTilt: boolean = props.data.buttonHasTilt || false;
  const showTiltOn: 'hover' | 'always' = props.data.buttonShowTiltOn || 'always';
  const tiltColor: string = props.data.buttonTiltColor || '#13121C';
  const tiltMaxRotation: number = props.data.buttonTiltMaxRotation || 0;
  const tiltMaxSkew: number = props.data.buttonTiltMaxSkew || 0;

  const tiltRotation: number = useMemo(() => {
    return generateRandomNumber(-1 * tiltMaxRotation, tiltMaxRotation) * Math.pow(-1, generateRandomNumber(1, 2));
  }, [tiltMaxRotation]);
  const tiltSkew: number = useMemo(() => {
    return generateRandomNumber(-1 * tiltMaxSkew, tiltMaxSkew) * Math.pow(-1, generateRandomNumber(1, 2));
  }, [tiltMaxSkew]);
  const generateTilt: JSX.Element = useMemo(() => {
    const tiltValues: ITiltValues = { rotation: tiltRotation, skew: tiltSkew };
    const tiltStyle: ITiltStyle = {
      backgroundColor: tiltColor,
      transform: 'rotate(' + tiltValues.rotation + 'deg) skewX(' + tiltValues.skew + 'deg)',
    };

    return <div className="TiltContainer" style={tiltStyle} />;
  }, [tiltRotation, tiltSkew, tiltColor]);
  const buttonClass: string = useMemo(() => {
    return showTiltOn === 'hover' ? 'Button tiltHover' : 'Button';
  }, [showTiltOn]);

  return (
    <div className={buttonClass}>
      <div className="inner">
        {hasTilt ? generateTilt : <></>}

        <div className="loading">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
  );
};

export default LoadingButton;
