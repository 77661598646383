import './App.scss';
import Header from './Components/Headers/Header';
import Footer from './Components/Footers/Footer';
import React, { useRef, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import RouterHandler from './Utilities/RouteHandler';
import { LoadingProvider } from './Context/loadingContext';
import { BrowserRouter as Router } from 'react-router-dom';
import NewsletterPopUp from './Components/PopUps/NewsletterPopUp';
import TermsConditions from './Components/Footers/TermsConditions';
import { initialiseSmoothScroll, uninitialisedSmoothScroll } from './Utilities/Functions/handleSmoothScroll';

const App: React.FC = () => {
  const smoothTarget: React.MutableRefObject<null> = useRef(null);

  useEffect(() => {
    const smoothScroller = initialiseSmoothScroll();

    return () => {
      if (smoothScroller) uninitialisedSmoothScroll(smoothScroller);
    };
  }, []);

  return (
    <HelmetProvider>
      <div className="App">
        <Router>
          <LoadingProvider>
            <header id="stickyHeader" className="show">
              <Header />
            </header>

            <div id="smooth-wrapper" ref={smoothTarget}>
              <div id="smooth-content">
                <main>
                  <RouterHandler />
                </main>

                <footer>
                  <Footer />
                  <TermsConditions />
                </footer>
              </div>
            </div>

            <NewsletterPopUp />
          </LoadingProvider>
        </Router>
      </div>
    </HelmetProvider>
  );
};

export default App;
