interface ServiceBasicContentData {
  children: Array<React.ReactNode> | React.ReactNode | Array<JSX.Element> | JSX.Element;
}

const BasicContentSection: React.FC<ServiceBasicContentData> = props => {
  return (
    <section className="BasicContentSection">
      <div className="outer">
        <div className="inner">{props.children}</div>
      </div>
    </section>
  );
};

export default BasicContentSection;
