import './NewsletterPopUp.scss';
import Button from '../Button/Button';
import { useLocation } from 'react-router-dom';
import LoadingButton from '../Button/LoadingButton';
import React, { useEffect, useState, useRef } from 'react';
import ImageContainer from '../ImageContainers/ImageContainer';
import { getIsValidEmail } from '../../Utilities/Functions/formValidationFunctions';
import sendNewsletterFormEmail, {
  sendNewsletterFormEmailProps,
} from '../../Utilities/Functions/sendNewsletterFormEmail';

interface NewsletterSession {
  updatedAt: number;
  formSubmitted: boolean;
  showPopUp: boolean;
}

const NewsletterSessionModel: NewsletterSession = {
  updatedAt: Date.now(),
  formSubmitted: false,
  showPopUp: true,
};

const NewsletterPopUp: React.FC = () => {
  const location = useLocation();
  const [pathName, setPathName] = useState<string>(location.pathname);
  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  const [emailHasError, setEmailHasError] = useState<boolean>(false);
  const [emailValue, setEmailValue] = useState<string>('');
  const [formLoading, setFormLoading] = useState<boolean>(true);
  const [formSent, setFormSent] = useState<boolean>(false);
  const [formSentSuccessful, setFormSentSuccessful] = useState<boolean>(false);
  const maxDiffMinutes: number = 30;
  const formRef: React.MutableRefObject<HTMLFormElement> = useRef<any>(null);
  const emailInputRef: React.MutableRefObject<HTMLInputElement> = useRef<any>(null);
  const EffectTriggerRef: React.MutableRefObject<boolean> = useRef<boolean>(false);

  const handleNewsletterSession: (maxDiffMinutes: number) => void = (maxDiffMinutes = 30) => {
    if (localStorage.getItem('AMNewsletter')) {
      setShowPopUp(false);

      return;
    }

    if (!sessionStorage.AMNewsletter) {
      setShowPopUp(true);
      sessionStorage.setItem('AMNewsletter', JSON.stringify(NewsletterSessionModel));

      return;
    }

    const session = JSON.parse(sessionStorage.AMNewsletter);
    const now: number = Date.now();
    const timeDiff: number = now - session.updatedAt;
    const diffMinutes: number = Math.ceil(timeDiff / 1000 / 60);
    const showPopUp: boolean = diffMinutes >= maxDiffMinutes ? true : false;

    if (showPopUp) {
      session.updatedAt = now;
      session.showPopUp = showPopUp;
      sessionStorage.setItem('AMNewsletter', JSON.stringify(session));
      setShowPopUp(showPopUp);
    }
  };
  const validationSuccess: () => void = () => {
    setFormLoading(false);
    setFormSent(true);
    setFormSentSuccessful(true);
  };
  const validationError: () => void = () => {
    setFormLoading(false);
    setFormSent(true);
    setFormSentSuccessful(false);
  };
  const validateForm: () => void = () => {
    setFormLoading(true);

    const isValidEmail: boolean = getIsValidEmail(emailValue);

    if (!isValidEmail) setEmailHasError(true);

    if (isValidEmail) {
      const sendNewsletterFormEmailData: sendNewsletterFormEmailProps = {
        form: formRef.current,
        successCallback: validationSuccess,
        errorCallback: validationError,
      };

      sendNewsletterFormEmail(sendNewsletterFormEmailData);

      return;
    }

    setFormLoading(false);
  };
  const resetForm: () => void = () => {
    setFormLoading(false);
    setFormSent(false);
    setFormSentSuccessful(false);
  };

  useEffect(() => {
    if (location.search.indexOf('popup=true') !== -1) {
      setPathName(location.pathname);
      setShowPopUp(true);
    }
  }, [location]);

  return (
    <>
      {showPopUp ? (
        <div className="NewsletterPopUp">
          <div
            className="background"
            onClick={() => {
              setShowPopUp(false);
            }}
          />

          <div className="inner">
            <button
              onClick={() => {
                setShowPopUp(false);
              }}
            >
              <svg width="26" height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20.0906 19.4736L3.77668 3.15969C3.52217 2.90518 3.37919 2.55999 3.37919 2.20005C3.37919 1.84011 3.52217 1.49492 3.77668 1.24041C4.0312 0.985892 4.37639 0.842908 4.73633 0.842908C5.09626 0.842908 5.44146 0.985892 5.69597 1.24041L22.0099 17.5544C22.2644 17.8089 22.4074 18.1541 22.4074 18.514C22.4074 18.8739 22.2644 19.2191 22.0099 19.4736C21.7554 19.7282 21.4102 19.8711 21.0503 19.8711C20.6903 19.8711 20.3451 19.7282 20.0906 19.4736Z"
                  fill="#FAD762"
                />
                <path
                  d="M22.0093 3.15985L5.69534 19.4738C5.44082 19.7283 5.09563 19.8713 4.7357 19.8713C4.37576 19.8713 4.03056 19.7283 3.77605 19.4738C3.52154 19.2193 3.37855 18.8741 3.37855 18.5142C3.37855 18.1542 3.52154 17.809 3.77605 17.5545L20.09 1.24057C20.3445 0.986052 20.6897 0.843068 21.0496 0.843067C21.4096 0.843067 21.7548 0.986053 22.0093 1.24057C22.2638 1.49508 22.4068 1.84027 22.4068 2.20021C22.4068 2.56015 22.2638 2.90534 22.0093 3.15985Z"
                  fill="#FAD762"
                />
              </svg>
            </button>

            <div className="left">
              <ImageContainer data={{ imageSource: '/images/newsletterPopUpForm Image.webp' }} />
            </div>

            <div className="right">
              {formSent ? (
                formSentSuccessful ? (
                  <div className="successMessage">
                    <div className="inner">
                      <h3 className="clrMustard">You're in!</h3>
                    </div>
                  </div>
                ) : (
                  <div className="errorMessage">
                    <div className="inner">
                      <p className="font-h6 clrWhite">
                        Helpful error message:{' '}
                        <Button
                          data={{
                            buttonHasTilt: true,
                            buttonShowTiltOn: 'always',
                            buttonTiltColor: '#FAD762',
                            buttonTiltMaxRotation: 2,
                            buttonTiltMaxSkew: 2,
                            buttonCustomClass: 'font-h6 clrBlack cursor-default',
                            buttonLinkType: 'none',
                            buttonContent: 'something went wrong',
                          }}
                        />
                      </p>

                      <p className="clrWhite p-small">
                        Apparently, it’s all your fault (actually, it’s not).
                        <br />
                        <br />
                        <span
                          className="clrMustard cursor-pointer p-small"
                          onClick={() => {
                            resetForm();
                          }}
                        >
                          Please try again
                        </span>{' '}
                        and hopefully the connection will be better. And so will your day.
                      </p>
                    </div>
                  </div>
                )
              ) : (
                <>
                  <h5 className="clrMustard">Lend us your ears</h5>
                  <p className="font-xSmall clrMustard">(Well, actually your eyes)</p>
                  <p className="clrWhite font-small">
                    Get our latest and greatest articles as soon as they’re published. At the very least, we hope
                    they’ll start a rumbustious debate.
                  </p>

                  <form ref={formRef}>
                    <div id="newsletterEmail">
                      <input
                        ref={emailInputRef}
                        className={emailHasError ? 'error font-small' : 'font-small'}
                        name="email"
                        type="email"
                        placeholder="Email*"
                        value={emailValue}
                        onChange={() => {
                          setEmailValue(emailInputRef.current.value);
                          setEmailHasError(false);
                        }}
                      />

                      <span className="inputError font-xSmall">Valid email required</span>
                    </div>

                    {formLoading ? (
                      <LoadingButton
                        data={{
                          buttonHasTilt: true,
                          buttonShowTiltOn: 'always',
                          buttonTiltColor: '#FAD762',
                          buttonTiltMaxRotation: 2,
                          buttonTiltMaxSkew: 2,
                        }}
                      />
                    ) : (
                      <Button
                        data={{
                          buttonHasTilt: true,
                          buttonShowTiltOn: 'always',
                          buttonTiltColor: '#FAD762',
                          buttonTiltMaxRotation: 2,
                          buttonTiltMaxSkew: 2,
                          buttonCustomClass: 'font-small',
                          buttonLinkType: 'function',
                          buttonContent: 'Go on then',
                          buttonLinkFunction: () => {
                            validateForm();
                          },
                        }}
                      />
                    )}
                  </form>
                </>
              )}
            </div>

            <div className="quoteContainer">
              <p className="font-display font-weight-bold">
                <span>“You don’t write because you want to say something,</span>
                <span>you write because you have something to say.”</span>
              </p>
              <p className="font-xSmall clrWhite">Scott F. Fitzgerald</p>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default NewsletterPopUp;
