import { gsap } from 'gsap';
import { useLayoutEffect } from 'react';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Button from '../../../Components/Button/Button';
import PartnerCarousel from '../../../Components/Carousels/PartnerCarousel';
import ImageContainer from '../../../Components/ImageContainers/ImageContainer';

gsap.registerPlugin(ScrollTrigger);

interface SectionClientItem {
  item_image: string;
  image_alt?: string;
  image_link?: string;
}
interface ServiceClientCarouselSectionData {
  clientItems: SectionClientItem[];
  titleBackgroundColor: string;
}

const ClientCarouselSection: React.FC<ServiceClientCarouselSectionData> = ({ clientItems, titleBackgroundColor }) => {
  useLayoutEffect(() => {
    var scrollingTrigger: globalThis.ScrollTrigger = ScrollTrigger.create({
      trigger: '.clientCarousel',
      start: 'top 0',
      end: '+=' + window.innerHeight * 2 + 'px',
      pin: true,
      pinSpacing: false,
    });

    return () => {
      scrollingTrigger.kill();
    };
  }, []);

  return (
    <section className="clientCarousel">
      <div className="background"></div>

      <div className="outer">
        <div className="inner">
          <h4 className="font-h1 clrWhite">
            Where we’ve{' '}
            <Button
              data={{
                buttonHasTilt: true,
                buttonShowTiltOn: 'always',
                buttonTiltColor: titleBackgroundColor,
                buttonTiltMaxRotation: 2,
                buttonTiltMaxSkew: 2,
                buttonCustomClass: 'font-h1 clrBlack',
                buttonLinkType: 'none',
                buttonContent: 'added value',
              }}
            />
          </h4>

          <PartnerCarousel data={{ carouselHasArrows: true, carouselArrowColor: titleBackgroundColor }}>
            {clientItems.map((item: SectionClientItem, key: number) => {
              return (
                <div key={key}>
                  {item.image_link && (
                    <a href={item.image_link} className="linkWrapper">
                      {item.image_alt}
                    </a>
                  )}
                  <ImageContainer
                    data={{ imageSource: item.item_image, imageAlt: item.image_alt, imageIsLazy: true }}
                  />
                </div>
              );
            })}
          </PartnerCarousel>
        </div>
      </div>
    </section>
  );
};

export default ClientCarouselSection;
