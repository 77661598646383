import axios from 'axios';
import './ServicePage.scss';
import USPSection from './Sections/USPSection';
import GridSection from './Sections/GridSection';
import React, { useState, useEffect } from 'react';
import IntroSection from './Sections/IntroSection';
import Spacer from '../../Components/Spacer/Spacer';
import PageHead from '../../Components/misc/PageHead';
import OurWorkSection from './Sections/OurWorkSection';
import JoinItUpSection from './Sections/JoinItUpSection';
import { ServiceGridSectionData } from './Sections/GridSection';
import ClientCarouselSection from './Sections/ClientCarouselSection';
import PartnerCarouselSection from './Sections/PartnerCarouselSection';
import LoadingContainer from '../../Components/LoadingContainers/LoadingContainer';
import { useLoadingRunContext, useToggleLoadingRunContext } from '../../Context/loadingContext';
import { initialiseSmoothScroll, uninitialisedSmoothScroll } from '../../Utilities/Functions/handleSmoothScroll';

const pageCanonicalHref: string = 'https://www.addmustard.com/brand/';
const pageTitle: string = 'Award-winning Brand Agency for Entrepreneurs | addmustard';
const pageDescription: string =
  'We create value in brands. For entrepreneurs. And those who think like entrepreneurs. ';
const pageThemeColor: string = '#f0bec9';
const pageRobots: string = 'all';
const gridSectionData: ServiceGridSectionData = {
  title: 'How we do it',
  content:
    'Everything we do in brand is underpinned by one framework: MAN-MESSAGE-MEDIA. In other words; who are you talking to? What are you saying to them? Where and how are you saying it? Our research team speaks directly with your customers and target audiences. We analyse and develop qualitative and quantitative insights that inform your messaging strategy - all of the nuances of your tone of voice, USPs and key brand messages that set you apart from your competitors. Translate all of that into a creative identity, market it in the right place and the right time, and you get a brand that not only looks and sounds the part, but delivers real, tangible value that ultimately drives customer loyalty, conversion and sales.',
  gridItems: [
    {
      imageSrc: '../images/brand-brandCommunication.webp',
      link: `/brand/brand-communication/`,
      title: 'Brand Communication',
    },
    {
      imageSrc: '../images/brand-brandDevelopment.webp',
      link: `/brand/brand-development/`,
      title: 'Brand Development',
    },
    {
      imageSrc: '../images/brand-brandPlanning.webp',
      link: `/brand/brand-planning/`,
      title: 'Brand Planning',
    },
  ],
  className: 'brand',
};

const ServiceBrand: React.FC = () => {
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false);
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [pageOpacity, setPageOpacity] = useState<number>(0);
  const [postDetails, setPostDetails] = useState<any>({});
  const toggleLoadingRun: () => void = useToggleLoadingRunContext();
  const loadingRun: boolean = useLoadingRunContext();

  useEffect(() => {
    const setPage: () => void = () => {
      setPageLoaded(true);
      setPageOpacity(1);
      window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    };

    if (loadingRun) {
      setPage();
      return;
    }

    var stateCheck = setInterval(() => {
      if (document.readyState === 'complete') {
        clearInterval(stateCheck);
        setPage();
        setTimeout(() => {
          setLoadingComplete(true);
          toggleLoadingRun();
        }, 300);
      }
    }, 1700);

    return () => {};
  }, [loadingRun, toggleLoadingRun]);

  useEffect(() => {
    axios.get('https://www.addmustard.com/wp-json/acf/v3/options/brand').then(response => {
      const postDetails = response.data.acf.post_details;

      setPostDetails(postDetails);
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 500);
    });
  }, []);

  useEffect(() => {
    const stickyHeader = document.getElementById('stickyHeader');

    if (!stickyHeader) return;

    stickyHeader.classList.remove('active');
  }, []);

  useEffect(() => {
    const smoothScroller = initialiseSmoothScroll();
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 500);

    return () => {
      if (smoothScroller) uninitialisedSmoothScroll(smoothScroller);
    };
  }, []);

  return (
    <>
      <PageHead
        canonical={pageCanonicalHref}
        description={pageDescription}
        robots={pageRobots}
        themeColor={pageThemeColor}
        title={pageTitle}
      />

      {loadingComplete || loadingRun ? <></> : <LoadingContainer loadingComplete={pageLoaded} />}

      <div className="pageBackground" style={{ backgroundColor: '#f0bec9' }}></div>

      <div
        id="ServicePage"
        style={{
          opacity: pageOpacity,
          transition: 'opacity 300ms ease',
          backgroundColor: '#f0bec9',
        }}
      >
        <div className="outer">
          <div className="inner">
            <Spacer minHeight={220} maxHeight={300} desiredHeight={30} backgroundColor="#f0bec9" />

            <IntroSection
              image={{
                imageSrc: postDetails?.intro?.image || '',
                imageAlt: postDetails?.intro?.imageAlt || '',
                isLazy: false,
              }}
              title={postDetails?.intro?.title || ''}
              content={postDetails?.intro?.copy || ''}
              viewAllButton={{
                copy: 'View work',
                link: 'https://www.addmustard.com/our-work/',
                function: () => {
                  const windowHeight: number = window.innerHeight;
                  const windowWidth: number = window.innerWidth;
                  const footerSectionTop: number =
                    document.getElementsByClassName('Footer')[0]?.getBoundingClientRect().top || 0;
                  var ourWorkSectionTop: number = document.querySelector('#our-work')?.getBoundingClientRect().top || 0;

                  if (windowWidth < 768) ourWorkSectionTop = footerSectionTop;

                  try {
                    window.scrollTo({
                      top: ourWorkSectionTop,
                      behavior: 'smooth',
                    });
                  } catch (error) {
                    console.log(error);
                  }
                },
              }}
              quote={postDetails?.intro?.quote || ''}
              quoteAuthor={postDetails?.intro?.quoteAuthor || ''}
            />

            <Spacer minHeight={100} maxHeight={350} desiredHeight={30} backgroundColor="#f0bec9" />

            <USPSection backgroundImageSrc={postDetails?.usp?.image || ''} USPs={postDetails?.usp?.items || []} />

            <Spacer minHeight={150} maxHeight={200} desiredHeight={30} backgroundColor="#f0bec9" />

            <GridSection
              className={gridSectionData.className}
              content={gridSectionData.content}
              gridItems={gridSectionData.gridItems}
              title={gridSectionData.title}
            />

            <Spacer minHeight={100} maxHeight={200} desiredHeight={30} backgroundColor="#f0bec9" />

            <JoinItUpSection
              backgroundImageSrc={postDetails?.services_overview?.image || '../images/joinItUp.webp'}
              title={postDetails?.services_overview?.title || ''}
              titleBackgroundColor="#F0BEC9"
              content={postDetails?.services_overview?.copy || ''}
              buttons={[
                {
                  content: 'Brand',
                  link: `/brand/`,
                  tiltColor: '#F0BEC9',
                  isActive: true,
                },
                {
                  content: 'Marketing',
                  link: `/marketing/`,
                  tiltColor: '#F27250',
                  isActive: false,
                },
                {
                  content: 'Technology',
                  link: `/technology/`,
                  tiltColor: '#84A9B5',
                  isActive: false,
                },
              ]}
            />

            <ClientCarouselSection
              clientItems={postDetails?.client_carousel?.items || []}
              titleBackgroundColor="#f0bec9"
            />

            <PartnerCarouselSection
              partnerItems={postDetails?.partner_carousel?.items || []}
              titleBackgroundColor="#f0bec9"
            />

            <Spacer minHeight={50} maxHeight={150} desiredHeight={30} backgroundColor="#f0bec9" />

            <div id="our-work" />
            <OurWorkSection backgroundColor="#f0bec9" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceBrand;
