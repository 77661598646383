import React from 'react';
import Button from '../../../Components/Button/Button';
import { caseStudies } from '../../../Data/caseStudies';
import SlideCarousel from '../../../Components/Carousels/SlideCarousel';
import SlideCarouselBlogCard from '../../../Components/Carousels/Cards/SlideCarouselBlogCard';

const OurWorkSection: React.FC = () => {
  return (
    <section className="OurWorkSection">
      <div className="background" />

      <div className="inner">
        <div className="titleContainer">
          <div className="inner">
            <h2>
              Our{' '}
              <Button
                data={{
                  buttonHasTilt: true,
                  buttonTiltColor: '#13121C',
                  buttonTiltMaxRotation: 2,
                  buttonTiltMaxSkew: 2,
                  buttonCustomClass: 'clrMustard font-h2',
                  buttonShowTiltOn: 'always',
                  buttonLinkType: 'none',
                  buttonContent: 'Work',
                }}
              />
            </h2>
          </div>
        </div>

        <SlideCarousel
          data={{
            carouselHasArrows: true,
            carouselHasDots: true,
            carouselViewAllCopy: 'Find out more',
            carouselViewAllType: 'external',
            carouselViewAllHref: 'https://www.addmustard.com/our-work/',
          }}
        >
          {caseStudies.map((caseStudy: any, key: number) => {
            return (
              <SlideCarouselBlogCard
                key={key}
                data={{
                  cardLogoImage: caseStudy.featureImage,
                  cardImage: caseStudy.coverImage,
                  cardLinkType: caseStudy.linkType,
                  cardLinkHref: caseStudy.linkHref,
                  cardBackgroundColor: caseStudy.backgroundColor,
                }}
              >
                {caseStudy.excerpt ? (
                  <p
                    dangerouslySetInnerHTML={{ __html: caseStudy.excerpt }}
                    className="font-h5 clrWhite font-weight-regular"
                  />
                ) : (
                  <></>
                )}
              </SlideCarouselBlogCard>
            );
          })}
        </SlideCarousel>
      </div>
    </section>
  );
};

export default OurWorkSection;
