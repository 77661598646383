import emailjs from '@emailjs/browser';

export interface sendNewsletterFormEmailProps {
  form: any;
  successCallback: () => void;
  errorCallback: () => void;
}

const sendNewsletterFormEmail: (data: sendNewsletterFormEmailProps) => void = ({
  form,
  successCallback,
  errorCallback,
}) => {
  const serviceID: string = 'service_eob3gds';
  const publicKey: string = 'ZwXsa15DovvlpjehR';
  const templateID: string = 'template_n38kv88';

  emailjs
    .sendForm(serviceID, templateID, form, publicKey)
    .then(result => {
      successCallback();
      localStorage.setItem('AMNewsletter', JSON.stringify({ formSubmitted: true }));
    })
    .catch(error => {
      errorCallback();
    });
};

export default sendNewsletterFormEmail;
