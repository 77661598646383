import React from 'react';
import Tilt from '../../TiltContainer/TiltContainer';
import { iSlideTestimonialCard } from '../CarouselInterfaces';
import ImageContainer from '../../ImageContainers/ImageContainer';

const quotationMarkLeft: string = require('../../../Images/quotationOpen.svg').default;
const quotationMarkRight: string = require('../../../Images/quotationClose.svg').default;

const SlideTestimonialCard: React.FC<iSlideTestimonialCard> = (props: iSlideTestimonialCard) => {
  const cardClass: string | undefined = props.data.cardClass;
  const cardTitle: string | undefined = props.data.cardTitle;
  const cardCopy: string | undefined = props.data.cardCopy;
  const cardAuthor: string | undefined = props.data.cardAuthor;
  const cardBackgroundColor: string | undefined = props.data.cardBackgroundColor || '';
  const StackTestimonialCardClass: string = cardClass ? 'SlideTestimonialCard ' + cardClass : 'SlideTestimonialCard';

  return (
    <div className={StackTestimonialCardClass}>
      <div className="inner">
        <Tilt data={{ maxRotation: 2, maxSkew: 2, backgroundColor: cardBackgroundColor }} />

        <div className="backgroundContainer">
          <div className="inner">
            <ImageContainer data={{ imageSource: quotationMarkLeft }} />

            <ImageContainer data={{ imageSource: quotationMarkRight }} />
          </div>
        </div>

        <div className="contentContainer">
          <div className="inner">
            {cardTitle ? <h4>{cardTitle}</h4> : <></>}

            {cardCopy ? <p>{cardCopy}</p> : <></>}
          </div>
        </div>

        <div className="cardAuthorContainer">
          <div className="inner">
            <Tilt data={{ maxRotation: 2, maxSkew: 3, backgroundColor: '#ffffff' }} />

            <p className="font-weight-bold">{cardAuthor}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideTestimonialCard;
