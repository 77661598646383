import './Carousel.scss';
import Button from '../Button/Button';
import Tilt from '../TiltContainer/TiltContainer';
import { iPartnerCarousel } from './CarouselInterfaces';
import React, { useEffect, Children, useState, useRef, useMemo, useCallback } from 'react';

const PartnerCarousel: React.FC<iPartnerCarousel> = (props: iPartnerCarousel) => {
  const childrenCount: number = Children.count(props.children);
  const carouselClass: string | undefined = props.data.carouselClass;
  const carouselHasArrows: boolean | undefined = props.data.carouselHasArrows;
  const carouselHasDots: boolean | undefined = props.data.carouselHasDots;
  const carouselViewAllCopy: string | undefined = props.data.carouselViewAllCopy;
  const carouselViewAllType: 'internal' | 'external' | 'function' | 'none' = props.data.carouselViewAllType || 'none';
  const carouselViewAllHref: string | undefined = props.data.carouselViewAllHref;
  const carouselArrowColor: string | undefined = props.data.carouselArrowColor;
  const [activeStackCount, setActiveStackCount] = useState<number>(0);
  const [leftArrowIsActive, setLeftArrowIsActive] = useState<boolean>(true);
  const [rightArrowIsActive, setRightArrowIsActive] = useState<boolean>(true);
  const [carouselInnerTranslateXValue, setCarouselInnerTranslateXValue] = useState<number>(0);
  const [carouselTouchStartValue, setCarouselTouchStartValue] = useState<number>(0);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const EffectTriggerRef: React.MutableRefObject<boolean> = useRef(false);
  const CarouselInnerRef: React.MutableRefObject<any> = useRef(null);

  const handleIncreaseActiveStackCount: () => void = useCallback(() => {
    const carouselInner: any = CarouselInnerRef.current;

    if (!carouselInner) return;

    const firstChild: any = carouselInner.firstElementChild;

    if (!firstChild) return;

    const translationAmount: number = firstChild.clientWidth;
    const translationBufferAmount: number = parseInt(getComputedStyle(carouselInner).gridGap);
    var newActiveStackCount: number = activeStackCount + 1;

    if (isMobile) {
      if (activeStackCount + 1 >= Math.floor(childrenCount / 2)) newActiveStackCount = 0;
    } else {
      if (activeStackCount + 1 >= childrenCount) newActiveStackCount = 0;
    }

    const newCarouselInnerTranslateXValue: number =
      newActiveStackCount * translationAmount + newActiveStackCount * translationBufferAmount;

    setActiveStackCount(newActiveStackCount);
    setCarouselInnerTranslateXValue(newCarouselInnerTranslateXValue);
  }, [activeStackCount, childrenCount, isMobile]);

  const handleDecreaseActiveStackCount: () => void = useCallback(() => {
    const carouselInner: any = CarouselInnerRef.current;

    if (!carouselInner) return;

    const firstChild: any = carouselInner.firstElementChild;

    if (!firstChild) return;

    const translationAmount: number = firstChild.clientWidth;
    const translationBufferAmount: number = parseInt(getComputedStyle(carouselInner).gridGap);
    var newActiveStackCount: number = activeStackCount - 1;

    if (isMobile) {
      if (activeStackCount - 1 <= -1) newActiveStackCount = Math.ceil(childrenCount / 2) - 2;
    } else {
      if (activeStackCount - 1 <= -1) newActiveStackCount = childrenCount - 1;
    }

    const newCarouselInnerTranslateXValue: number =
      newActiveStackCount * translationAmount + newActiveStackCount * translationBufferAmount;

    setActiveStackCount(newActiveStackCount);
    setCarouselInnerTranslateXValue(newCarouselInnerTranslateXValue);
  }, [activeStackCount, childrenCount, isMobile]);

  const handleCarouselTouchStart: (event: any) => void = useCallback((event: any) => {
    const touchXPosition: number = event.touches[0].clientX;

    setCarouselTouchStartValue(touchXPosition);
  }, []);

  const handleCarouselTouchEnd: (event: any) => void = useCallback(
    (event: any) => {
      const touchXPosition: number = event.changedTouches[0].clientX;

      if (touchXPosition >= carouselTouchStartValue) {
        handleDecreaseActiveStackCount();
      } else if (touchXPosition < carouselTouchStartValue) {
        handleIncreaseActiveStackCount();
      }
    },
    [carouselTouchStartValue, handleDecreaseActiveStackCount, handleIncreaseActiveStackCount],
  );

  const carouselContent: any = useMemo(() => {
    return childrenCount > 0 ? (
      Children.map(props.children, (child: React.ReactNode | React.ReactElement, key: number) => {
        const childClass: string = key <= activeStackCount ? 'item active' : 'item';

        return (
          <div className={childClass} key={key}>
            {child}
          </div>
        );
      })
    ) : (
      <></>
    );
  }, [activeStackCount, childrenCount, props.children]);

  const leftArrow: any = useMemo(() => {
    const leftArrowClass: string = leftArrowIsActive ? 'arrowContainer left active' : 'arrowContainer left';

    return carouselHasArrows && childrenCount > 4 ? (
      <div className={leftArrowClass}>
        <div className="inner">
          <div className="arrow" onClick={handleDecreaseActiveStackCount}>
            <Tilt data={{ maxRotation: 4, maxSkew: 4, backgroundColor: carouselArrowColor || '#fff' }} />

            <div className="inner" />
          </div>
        </div>
      </div>
    ) : (
      <></>
    );
  }, [carouselHasArrows, childrenCount, leftArrowIsActive, handleDecreaseActiveStackCount, carouselArrowColor]);

  const rightArrow: any = useMemo(() => {
    const rightArrowClass: string = rightArrowIsActive ? 'arrowContainer right active' : 'arrowContainer right';

    return carouselHasArrows && childrenCount > 4 ? (
      <div className={rightArrowClass}>
        <div className="inner">
          <div className="arrow" onClick={handleIncreaseActiveStackCount}>
            <Tilt data={{ maxRotation: 4, maxSkew: 4, backgroundColor: carouselArrowColor || '#fff' }} />

            <div className="inner" />
          </div>
        </div>
      </div>
    ) : (
      <></>
    );
  }, [carouselHasArrows, childrenCount, rightArrowIsActive, handleIncreaseActiveStackCount, carouselArrowColor]);

  const getGridTemplateColumns: () => string = () => {
    if (isMobile) {
      return `repeat(${Math.ceil(childrenCount / 2)}, calc(50% - (50px)))`;
    } else {
      return `repeat(${childrenCount}, calc(25% - (75px)))`;
    }
  };

  var PartnerCarouselClass: string = carouselClass ? 'PartnerCarousel ' + carouselClass : 'PartnerCarousel';
  PartnerCarouselClass = carouselHasArrows ? PartnerCarouselClass + ' hasArrows' : PartnerCarouselClass;
  PartnerCarouselClass = carouselHasDots ? PartnerCarouselClass + ' hasDots' : PartnerCarouselClass;
  PartnerCarouselClass = childrenCount < 4 ? PartnerCarouselClass + ' centered' : PartnerCarouselClass;

  useEffect(() => {
    if (EffectTriggerRef.current) {
      return;
    }

    const handleResize: () => void = () => {
      const carouselInner: any = CarouselInnerRef.current;

      if (!carouselInner) return;

      const firstChild: any = carouselInner.firstElementChild;

      if (!firstChild) return;

      const translationAmount: number = firstChild.clientWidth;
      const translationBufferAmount: number = parseInt(getComputedStyle(carouselInner).gridGap);
      const newCarouselInnerTranslateXValue: number =
        activeStackCount * translationAmount + activeStackCount * translationBufferAmount;

      setCarouselInnerTranslateXValue(newCarouselInnerTranslateXValue);
    };

    EffectTriggerRef.current = true;
    window.addEventListener('resize', handleResize);
    window.addEventListener('load', () => {
      if (window.innerWidth <= 1080) {
        setIsMobile(true);
      }
    });
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 1080) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    });

    return () => {
      EffectTriggerRef.current = false;
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', () => {});
      window.removeEventListener('resize', () => {});
    };
  }, [activeStackCount]);

  return (
    <div className={PartnerCarouselClass}>
      <div className="outer">
        {leftArrow}

        <div
          ref={CarouselInnerRef}
          className="inner"
          style={{
            transform: 'translateX(-' + carouselInnerTranslateXValue + 'px)',
            gridTemplateColumns: getGridTemplateColumns(),
          }}
          onTouchStart={handleCarouselTouchStart}
          onTouchEnd={handleCarouselTouchEnd}
          onTouchCancel={handleCarouselTouchEnd}
        >
          {carouselContent}
        </div>

        {rightArrow}

        {carouselViewAllCopy ? (
          <Button
            data={{
              buttonHasTilt: true,
              buttonShowTiltOn: 'always',
              buttonTiltColor: '#13121C',
              buttonTiltMaxRotation: 3,
              buttonTiltMaxSkew: 3,
              buttonCustomClass: 'clrMustard',
              buttonLinkType: carouselViewAllType,
              buttonLinkHref: carouselViewAllHref,
              buttonContent: carouselViewAllCopy,
            }}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default PartnerCarousel;
