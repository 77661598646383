import './ImageContainer.scss';
import React, { useState, useMemo } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { iImageContainer } from './ImageContainerInterfaces';

const ImageContainer: React.FC<iImageContainer> = (props: iImageContainer) => {
  const imageSource: string = props.data.imageSource;
  const imageAlt: string | undefined = props.data.imageAlt;
  const imageHrefType: string | undefined = props.data.imageHrefType;
  const imageHref: string | LinkProps['to'] = props.data.imageHref || '';
  const imageIsLazy: boolean | undefined = props.data.imageIsLazy || false;
  const [imageOpacity, setImageOpacity] = useState(1);

  const image: JSX.Element = useMemo(() => {
    const imageLoading: 'eager' | 'lazy' = imageIsLazy ? 'lazy' : 'eager';
    const imageStyle: any = { opacity: imageOpacity, transition: 'opacity 0ms ease' };
    const imageElement: () => JSX.Element = () => {
      return <img src={imageSource} alt={imageAlt} loading={imageLoading} style={imageStyle} />;
    };

    if (imageHref) {
      if (imageHrefType === 'internalLink') {
        return <Link to={imageHref}>{imageElement()}</Link>;
      } else if (imageHrefType === 'externalLink') {
        return <a href={imageHref}>{imageElement()}</a>;
      }
    }

    return <>{imageElement()}</>;
  }, [imageAlt, imageHref, imageHrefType, imageOpacity, imageSource, imageIsLazy]);

  return (
    <div className="ImageContainer">
      <div className="inner">{image}</div>
    </div>
  );
};

export default ImageContainer;
