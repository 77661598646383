import { gsap } from 'gsap';
import { useLayoutEffect } from 'react';
import ImageContainer from '../../../Components/ImageContainers/ImageContainer';

interface ServiceUSP {
  icon: string;
  title: string;
  copy: string;
}
interface ServiceUSPSectionData {
  backgroundImageSrc: string;
  USPs: ServiceUSP[];
}

const USPSection: React.FC<ServiceUSPSectionData> = props => {
  useLayoutEffect(() => {
    const uspsAnimation = gsap.to('.USPListUSPSection', {
      scrollTrigger: {
        toggleActions: 'restart none none none',
      },
      opacity: 1,
      x: 0,
      duration: 3,
    });

    return () => {
      uspsAnimation.kill();
    };
  }, []);

  return (
    <section className="USPSection">
      <div className="background" style={{ backgroundColor: '#13121C' }}></div>

      <div className="backgroundImage" data-speed="1.1" data-lag="0.1">
        <ImageContainer
          data={{
            imageSource: props.backgroundImageSrc,
          }}
        />
      </div>

      <div className="outer">
        <div className="inner">
          <ul className="USPListUSPSection" data-speed="1" data-lag="0" style={{ transform: 'translateX(-100%)' }}>
            {props.USPs.map((USP: ServiceUSP, key: number) => {
              return (
                <li key={key}>
                  <ImageContainer data={{ imageSource: USP.icon }} />

                  <h4 dangerouslySetInnerHTML={{ __html: USP.title }} />

                  <p className="clrWhite p-small" dangerouslySetInnerHTML={{ __html: USP.copy }} />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default USPSection;
