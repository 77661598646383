import '../Carousel.scss';
import React, { useCallback } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import Tilt from '../../TiltContainer/TiltContainer';
import { iSlideCarouselBlogCard } from '../CarouselInterfaces';
import ImageContainer from '../../ImageContainers/ImageContainer';

const SlideCarouselBlogCard: React.FC<iSlideCarouselBlogCard> = (props: iSlideCarouselBlogCard) => {
  const cardClass: string = props.data.cardClass || '';
  const cardLogoImage: string = props.data.cardLogoImage || '';
  const cardImage: string = props.data.cardImage || '';
  const cardImageAlt: string = props.data.cardImageAlt || '';
  const cardLinkType: 'internal' | 'external' | 'function' | 'none' = props.data.cardLinkType || 'none';
  const cardLinkHref: string | LinkProps['to'] = props.data.cardLinkHref || '';
  const cardBackgroundColor: string = props.data.cardBackgroundColor || '#13121C';

  const generateBlogCardContent: () => JSX.Element = useCallback(() => {
    return (
      <>
        <div className="contentContainer">
          <div className="imageParent">
            {cardLogoImage ? (
              <div className="logoContainer">
                <ImageContainer data={{ imageSource: cardLogoImage }} />
              </div>
            ) : (
              <></>
            )}

            {cardImage ? (
              <ImageContainer
                data={{ imageSource: cardImage, imageAlt: cardImageAlt, imageHrefType: 'externalLink', imageHref: '' }}
              />
            ) : (
              <></>
            )}
          </div>

          <div className="inner">
            <Tilt data={{ maxRotation: 1, maxSkew: 2, backgroundColor: cardBackgroundColor }} />

            {props.children}
          </div>
        </div>
      </>
    );
  }, [cardBackgroundColor, cardImage, cardImageAlt, cardLogoImage, props.children]);
  const generateBlogCardInterior: () => JSX.Element = useCallback(() => {
    const innerClassName: string = cardImage ? 'inner withImage' : 'inner';

    if (cardLinkHref) {
      if (cardLinkType === 'internal') {
        return (
          <Link className={innerClassName} to={cardLinkHref}>
            {generateBlogCardContent()}
          </Link>
        );
      } else if (cardLinkType === 'external') {
        return (
          <a className={innerClassName} href={cardLinkHref}>
            {generateBlogCardContent()}
          </a>
        );
      }
    }

    return <div className={innerClassName}>{generateBlogCardContent()}</div>;
  }, [cardLinkHref, cardLinkType, cardImage, generateBlogCardContent]);

  const SlideCarouselBlogCardClass: string = cardClass ? 'SlideCarouselBlogCard ' + cardClass : 'SlideCarouselBlogCard';

  return <div className={SlideCarouselBlogCardClass}>{generateBlogCardInterior()}</div>;
};

export default SlideCarouselBlogCard;
