import React, { useRef, useMemo } from 'react';
import Button from '../../../Components/Button/Button';
import { caseStudies } from '../../../Data/caseStudies';
import SlideCarousel from '../../../Components/Carousels/SlideCarousel';
import SlideCarouselBlogCard from '../../../Components/Carousels/Cards/SlideCarouselBlogCard';

interface OurWorkSectionProps {
  backgroundColor: string;
}

const OurWorkSection: React.FC<OurWorkSectionProps> = ({ backgroundColor }) => {
  const OurWorkSectionRef: React.MutableRefObject<any> = useRef(null);

  const content: JSX.Element[] = useMemo(() => {
    return caseStudies.map((caseStudy: any, key: number) => {
      return (
        <SlideCarouselBlogCard
          key={key}
          data={{
            cardLogoImage: caseStudy.featureImage,
            cardImage: caseStudy.coverImage,
            cardLinkType: caseStudy.linkType,
            cardLinkHref: caseStudy.linkHref,
            cardBackgroundColor: caseStudy.backgroundColor,
          }}
        >
          {caseStudy.excerpt ? (
            <p
              dangerouslySetInnerHTML={{ __html: caseStudy.excerpt }}
              className="font-h5 clrWhite font-weight-regular"
            />
          ) : (
            <></>
          )}
        </SlideCarouselBlogCard>
      );
    });
  }, []);

  return (
    <section
      ref={OurWorkSectionRef}
      className="OurWorkSection"
      style={{ backgroundColor: backgroundColor || '', position: 'relative' }}
    >
      <div
        className="background"
        style={{
          height: '110%',
          width: '300vw',
          position: 'absolute',
          top: 0,
          left: '-100vw',
          backgroundColor: backgroundColor || '',
          maxWidth: 'unset',
        }}
      ></div>

      <div className="inner">
        <div className="titleContainer">
          <h2>
            Our{' '}
            <Button
              data={{
                buttonHasTilt: true,
                buttonTiltColor: '#13121C',
                buttonTiltMaxRotation: 2,
                buttonTiltMaxSkew: 2,
                buttonCustomClass: 'clrWhite font-h2',
                buttonShowTiltOn: 'always',
                buttonLinkType: 'none',
                buttonContent: 'Work',
              }}
            />
          </h2>
        </div>

        <SlideCarousel data={{ carouselHasArrows: true, carouselHasDots: false, carouselArrowColor: backgroundColor }}>
          {content}
        </SlideCarousel>
      </div>
    </section>
  );
};

export default OurWorkSection;
