import './Carousel.scss';
import Button from '../Button/Button';
import Tilt from '../TiltContainer/TiltContainer';
import { iSlideCarousel } from './CarouselInterfaces';
import React, { useEffect, Children, useState, useRef, useCallback, useMemo } from 'react';

const SlideCarousel: React.FC<iSlideCarousel> = (props: iSlideCarousel) => {
  const childrenCount: number = Children.count(props.children);
  const carouselClass: string | undefined = props.data.carouselClass;
  const carouselHasArrows: boolean | undefined = props.data.carouselHasArrows;
  const carouselHasDots: boolean | undefined = props.data.carouselHasDots;
  const carouselViewAllCopy: string | undefined = props.data.carouselViewAllCopy;
  const carouselViewAllType: 'internal' | 'external' | 'function' | 'none' = props.data.carouselViewAllType || 'none';
  const carouselViewAllHref: string | undefined = props.data.carouselViewAllHref;
  const carouselArrowColor: string | undefined = props.data.carouselArrowColor;
  const [activeStackCount, setActiveStackCount] = useState<number>(0);
  const [leftArrowIsActive, setLeftArrowIsActive] = useState<boolean>(true);
  const [rightArrowIsActive, setRightArrowIsActive] = useState<boolean>(true);
  const [carouselInnerTranslateXValue, setCarouselInnerTranslateXValue] = useState<number>(0);
  const [carouselTouchStartValue, setCarouselTouchStartValue] = useState<number>(0);
  const EffectTriggerRef: React.MutableRefObject<boolean> = useRef(false);
  const CarouselInnerRef: React.MutableRefObject<any> = useRef(null);

  const handleIncreaseActiveStackCount: () => void = useCallback(() => {
    const translationAmount: number = CarouselInnerRef.current
      ? CarouselInnerRef.current.firstElementChild.clientWidth
      : 0;
    const translationBufferAmount: number = CarouselInnerRef.current
      ? parseInt(getComputedStyle(CarouselInnerRef.current).gridGap)
      : 0;
    var newActiveStackCount: number = activeStackCount + 1;

    if (newActiveStackCount >= childrenCount) newActiveStackCount = 0;

    const newCarouselInnerTranslateXValue: number =
      newActiveStackCount * translationAmount + newActiveStackCount * translationBufferAmount;

    setActiveStackCount(newActiveStackCount);
    setCarouselInnerTranslateXValue(newCarouselInnerTranslateXValue);
  }, [activeStackCount, childrenCount]);
  const handleDecreaseActiveStackCount: () => void = useCallback(() => {
    const translationAmount: number = CarouselInnerRef.current
      ? CarouselInnerRef.current.firstElementChild.clientWidth
      : 0;
    const translationBufferAmount: number = CarouselInnerRef.current
      ? parseInt(getComputedStyle(CarouselInnerRef.current).gridGap)
      : 0;
    var newActiveStackCount: number = activeStackCount - 1;

    if (activeStackCount - 1 <= -1) newActiveStackCount = childrenCount - 1;

    const newCarouselInnerTranslateXValue: number =
      newActiveStackCount * translationAmount + newActiveStackCount * translationBufferAmount;

    setActiveStackCount(newActiveStackCount);
    setCarouselInnerTranslateXValue(newCarouselInnerTranslateXValue);
  }, [activeStackCount, childrenCount]);
  const handleCarouselTouchStart: (event: any) => void = useCallback(event => {
    const touchXPosition: number = event.touches[0].clientX;

    setCarouselTouchStartValue(touchXPosition);
  }, []);
  const handleCarouselTouchEnd: (event: any) => void = useCallback(
    event => {
      const touchXPosition: number = event.changedTouches[0].clientX;

      if (touchXPosition >= carouselTouchStartValue) {
        handleDecreaseActiveStackCount();
      } else if (touchXPosition < carouselTouchStartValue) {
        handleIncreaseActiveStackCount();
      }
    },
    [carouselTouchStartValue, handleDecreaseActiveStackCount, handleIncreaseActiveStackCount],
  );

  const carouselContent: JSX.Element | JSX.Element[] | null | undefined = useMemo(() => {
    return childrenCount > 0 ? (
      Children.map(props.children, (child: React.ReactNode | React.ReactElement, key: number) => {
        const childClass: string = key <= activeStackCount ? 'item active' : 'item';

        return (
          <div className={childClass} key={key}>
            {child}
          </div>
        );
      })
    ) : (
      <></>
    );
  }, [activeStackCount, props.children, childrenCount]);
  const leftArrow: JSX.Element = useMemo(() => {
    const leftArrowClass: string = leftArrowIsActive ? 'arrowContainer left active' : 'arrowContainer left';

    return carouselHasArrows && childrenCount > 1 ? (
      <div className={leftArrowClass}>
        <div className="inner">
          <div className="arrow" onClick={handleDecreaseActiveStackCount}>
            <Tilt data={{ maxRotation: 4, maxSkew: 4, backgroundColor: '#13121C' }} />

            <div className="inner" style={{ borderRightColor: carouselArrowColor || '#fad762' }} />
          </div>
        </div>
      </div>
    ) : (
      <></>
    );
  }, [carouselHasArrows, childrenCount, handleDecreaseActiveStackCount, leftArrowIsActive, carouselArrowColor]);
  const rightArrow: JSX.Element = useMemo(() => {
    const rightArrowClass: string = rightArrowIsActive ? 'arrowContainer right active' : 'arrowContainer right';

    return carouselHasArrows && childrenCount > 1 ? (
      <div className={rightArrowClass}>
        <div className="inner">
          <div className="arrow" onClick={handleIncreaseActiveStackCount}>
            <Tilt data={{ maxRotation: 4, maxSkew: 4, backgroundColor: '#13121C' }} />

            <div className="inner" style={{ borderLeftColor: carouselArrowColor || '#fad762' }} />
          </div>
        </div>
      </div>
    ) : (
      <></>
    );
  }, [rightArrowIsActive, carouselHasArrows, childrenCount, handleIncreaseActiveStackCount, carouselArrowColor]);
  var SlideCarouselClass: string = carouselClass ? 'SlideCarousel ' + carouselClass : 'SlideCarousel';
  SlideCarouselClass = carouselHasArrows ? SlideCarouselClass + ' hasArrows' : SlideCarouselClass;
  SlideCarouselClass = carouselHasDots ? SlideCarouselClass + ' hasDots' : SlideCarouselClass;

  useEffect(() => {
    if (EffectTriggerRef.current) return;

    const handleResize: () => void = () => {
      const translationAmount: number = CarouselInnerRef.current
        ? CarouselInnerRef.current.firstElementChild.clientWidth
        : 0;
      const translationBufferAmount: number = CarouselInnerRef.current
        ? parseInt(getComputedStyle(CarouselInnerRef.current).gridGap)
        : 0;
      const newCarouselInnerTranslateXValue: number =
        activeStackCount * translationAmount + activeStackCount * translationBufferAmount;

      setCarouselInnerTranslateXValue(newCarouselInnerTranslateXValue);
    };

    EffectTriggerRef.current = true;
    window.addEventListener('resize', handleResize);

    return () => {
      EffectTriggerRef.current = false;
      window.removeEventListener('resize', handleResize);
    };
  }, [activeStackCount]);

  return (
    <div className={SlideCarouselClass}>
      <div className="outer">
        {leftArrow}

        <div
          ref={CarouselInnerRef}
          className="inner"
          style={{
            transform: 'translateX(-' + carouselInnerTranslateXValue + 'px)',
            gridTemplateColumns: 'repeat(' + childrenCount + ', auto)',
          }}
          onTouchStart={handleCarouselTouchStart}
          onTouchEnd={handleCarouselTouchEnd}
          onTouchCancel={handleCarouselTouchEnd}
        >
          {carouselContent}
        </div>

        {rightArrow}

        {carouselViewAllCopy ? (
          <Button
            data={{
              buttonHasTilt: true,
              buttonShowTiltOn: 'always',
              buttonTiltColor: '#13121C',
              buttonTiltMaxRotation: 3,
              buttonTiltMaxSkew: 3,
              buttonCustomClass: 'clrMustard',
              buttonLinkType: carouselViewAllType,
              buttonLinkHref: carouselViewAllHref,
              buttonContent: carouselViewAllCopy,
            }}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default SlideCarousel;
