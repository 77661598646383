import { gsap } from 'gsap';
import '../WhatWeDoPage.scss';
import React, { useLayoutEffect } from 'react';
import ScrollTrigger from 'gsap/ScrollTrigger';

const AboutSection: React.FC = () => {
  const triggerEnd: string = '+=' + 4 * window.innerHeight + 'px';

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    var scrollingTrigger: globalThis.ScrollTrigger = ScrollTrigger.create({
      trigger: '.AboutSectionStatic',
      start: 'top 0',
      end: triggerEnd,
      pin: true,
      pinSpacing: false,
    });

    var cardOneTrigger: globalThis.ScrollTrigger = ScrollTrigger.create({
      trigger: '.AboutSectionCard-1',
      start: '-50%',
      end: '50%',
      onEnter: () => {
        gsap.to('.AboutSectionStaticBackground', { backgroundColor: '#F0BEC9' });
        gsap.to('.AboutSectionImage-1', { opacity: 1, delay: 0 });
      },
      onEnterBack: () => {
        gsap.to('.AboutSectionStaticBackground', { backgroundColor: '#F0BEC9' });
        gsap.to('.AboutSectionImage-1', { opacity: 1, delay: 0 });
      },
      onLeave: () => {
        gsap.to('.AboutSectionImage-1', { opacity: 0, delay: 0 });
      },
      onLeaveBack: () => {
        gsap.to('.AboutSectionImage-1', { opacity: 0, delay: 0 });
      },
    });

    var cardTwoTrigger: globalThis.ScrollTrigger = ScrollTrigger.create({
      trigger: '.AboutSectionCard-2',
      start: '-50%',
      end: '50%',
      onEnter: () => {
        gsap.to('.AboutSectionStaticBackground', { backgroundColor: '#F27350' });
        gsap.to('.AboutSectionImage-2', { opacity: 1, delay: 0 });
      },
      onEnterBack: () => {
        gsap.to('.AboutSectionStaticBackground', { backgroundColor: '#F27350' });
        gsap.to('.AboutSectionImage-2', { opacity: 1, delay: 0 });
      },
      onLeave: () => {
        gsap.to('.AboutSectionImage-2', { opacity: 0, delay: 0 });
      },
      onLeaveBack: () => {
        gsap.to('.AboutSectionImage-2', { opacity: 0, delay: 0 });
      },
    });

    var cardThreeTrigger: globalThis.ScrollTrigger = ScrollTrigger.create({
      trigger: '.AboutSectionCard-3',
      start: '-50%',
      end: '50%',
      onEnter: () => {
        gsap.to('.AboutSectionStaticBackground', { backgroundColor: '#84A9B5' });
        gsap.to('.AboutSectionImage-3', { opacity: 1, delay: 0 });
      },
      onEnterBack: () => {
        gsap.to('.AboutSectionStaticBackground', { backgroundColor: '#84A9B5' });
        gsap.to('.AboutSectionImage-3', { opacity: 1, delay: 0 });
      },
      onLeave: () => {
        gsap.to('.AboutSectionImage-3', { opacity: 0, delay: 0 });
      },
      onLeaveBack: () => {
        gsap.to('.AboutSectionImage-3', { opacity: 0, delay: 0 });
      },
    });

    var lastCardTrigger = ScrollTrigger.create({
      trigger: '.lastcard',
      start: 'center center',
      end: '100%',
      pin: true,
      pinSpacing: false,
    });

    return () => {
      scrollingTrigger.kill();
      cardOneTrigger.kill();
      cardTwoTrigger.kill();
      cardThreeTrigger.kill();
      lastCardTrigger.kill();
    };
  }, [triggerEnd]);

  return (
    <section className="AboutSection">
      <div className="AboutSectionStatic">
        <div className="AboutSectionStaticBackground" />

        <div className="inner">
          <div className="imageParent">
            <div className="inner">
              <div className="ImageContainer ImageContainer-1">
                <div className="imageCover" />

                <div className="inner AboutSectionImageContainer" />

                <img
                  className="AboutSectionImage-1"
                  src="../images/whatWeDo_aboutSectionImage_1.webp"
                  alt=""
                  style={{ opacity: 1 }}
                />

                <img className="AboutSectionImage-2" src="../images/whatWeDo_aboutSectionImage_2.webp" alt="" />

                <img className="AboutSectionImage-3" src="../images/whatWeDo_aboutSectionImage_3.webp" alt="" />
              </div>
            </div>
          </div>

          <div className="contentContainer" />
        </div>
      </div>

      <div className="inner">
        <div className="TabbedAboutCard AboutSectionCard-1">
          <div className="inner">
            <div className="imageParent" />

            <div className="contentContainer">
              <div className="inner">
                <div className="TiltContainer" data-speed="1" data-lag="0.2">
                  <div className="tilt">
                    <div
                      className="inner"
                      style={{ backgroundColor: 'rgb(255, 255, 255)', transform: 'rotate(1deg) skewX(-1deg)' }}
                    ></div>
                  </div>
                </div>

                <div className="title" data-speed="1" data-lag="0.2">
                  <div className="inner">
                    <div className="Button">
                      <div className="inner">
                        <div
                          className="TiltContainer buttonTilt"
                          style={{ backgroundColor: 'rgb(19, 18, 28)', transform: 'rotate(0deg) skewX(1deg)' }}
                        ></div>

                        <h2 className="font-h1 clrWhite"> Brand</h2>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="copy" data-speed="1" data-lag="0.2">
                  <div className="inner">
                    <p className="p-small">
                      Creating a brand, or building one, we start in the same place. Laser-focused customer profiles,
                      built from the nuts and bolts of audience and competitor research. We transform this into a
                      powerful creative strategy and a brand story that resonates. Defining – or redefining - your
                      proposition, values, and messaging hierarchy. Add to this a media plan that allows you to execute
                      your message, flawlessly, resulting in the creation of astonishing brand value and performance.
                    </p>

                    <div className="Button">
                      <div className="inner">
                        <a className="p-small" href={`/brand/`}>
                          {' '}
                          Find out more
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="TabbedAboutCard AboutSectionCard-2">
          <div className="inner">
            <div className="imageParent" />

            <div className="contentContainer">
              <div className="inner">
                <div className="TiltContainer" data-speed="1" data-lag="0.2">
                  <div className="tilt">
                    <div
                      className="inner"
                      style={{ backgroundColor: 'rgb(255, 255, 255)', transform: 'rotate(-1deg) skewX(0deg)' }}
                    ></div>
                  </div>
                </div>

                <div className="title" data-speed="1" data-lag="0.2">
                  <div className="inner">
                    <div className="Button">
                      <div className="inner">
                        <div
                          className="TiltContainer buttonTilt"
                          style={{ backgroundColor: 'rgb(19, 18, 28)', transform: 'rotate(-1deg) skewX(2deg)' }}
                        ></div>

                        <h2 className="font-h1 clrWhite"> Marketing</h2>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="copy" data-speed="1" data-lag="0.2">
                  <div className="inner">
                    <p className="p-small">
                      We get straight to the important stuff. Like planning your budgets and investing your money
                      Wisely. From campaign planning, set up, testing, production, and execution to reporting, trading
                      and optimisation. Our experience spans all forms of offline and online media, incorporating social
                      and community building. All joined up by what really matters: numbers, performance and brand
                      consistency.
                    </p>

                    <div className="Button">
                      <div className="inner">
                        <a className="p-small" href={`/marketing/`}>
                          {' '}
                          Find out more
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="TabbedAboutCard AboutSectionCard-3 lastcard">
          <div className="inner">
            <div className="imageParent" />

            <div className="contentContainer">
              <div className="inner">
                <div className="TiltContainer" data-speed="1" data-lag="0.0">
                  <div className="tilt">
                    <div
                      className="inner"
                      style={{ backgroundColor: 'rgb(255, 255, 255)', transform: 'rotate(2deg) skewX(2deg)' }}
                    ></div>
                  </div>
                </div>

                <div className="title" data-speed="1" data-lag="0.0">
                  <div className="inner">
                    <div className="Button">
                      <div className="inner">
                        <div
                          className="TiltContainer buttonTilt"
                          style={{ backgroundColor: 'rgb(19, 18, 28)', transform: 'rotate(0deg) skewX(2deg)' }}
                        ></div>

                        <h2 className="font-h1 clrWhite"> Technology</h2>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="copy" data-speed="1" data-lag="0.0">
                  <div className="inner">
                    <p className="p-small">
                      Our world-class design and technology team provide beautifully crafted and engineered solutions.
                      On time. On budget. Delivering an online customer experience that utilises your brand to delight
                      and convert. We understand the importance of technology and how it needs to join up with brand and
                      marketing. And be robust and secure. Maximising its ROI and impact on your business.{' '}
                    </p>

                    <div className="Button">
                      <div className="inner">
                        <a className="p-small" href={`/technology/`}>
                          {' '}
                          Find out more
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
