import { gsap } from 'gsap';
import { useLayoutEffect } from 'react';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Button from '../../../Components/Button/Button';

gsap.registerPlugin(ScrollTrigger);

interface SectionJoinItUpButton {
  content: string;
  link: string;
  tiltColor: string;
  isActive: boolean;
}
interface ServiceJoinItUpSectionData {
  backgroundImageSrc: string;
  title: string;
  titleBackgroundColor: string;
  content: string;
  buttons: SectionJoinItUpButton[];
}

const JoinItUpSection: React.FC<ServiceJoinItUpSectionData> = props => {
  useLayoutEffect(() => {
    var scrollingTrigger: globalThis.ScrollTrigger = ScrollTrigger.create({
      trigger: '.joinItUpSection',
      start: 'top 0',
      end: '+=' + window.innerHeight * 2 + 'px',
      pin: true,
      pinSpacing: false,
    });

    return () => {
      scrollingTrigger.kill();
    };
  }, []);

  return (
    <section className="joinItUpSection">
      <div
        className="background"
        style={{
          backgroundImage: `url(${props.backgroundImageSrc})`,
        }}
      />

      <div className="outer" style={{ backgroundImage: 'linear-gradient(90deg, rgba(0,0,0,0.4), transparent)' }}>
        <div className="inner">
          <h3>
            <Button
              data={{
                buttonHasTilt: true,
                buttonShowTiltOn: 'always',
                buttonTiltColor: props.titleBackgroundColor,
                buttonTiltMaxRotation: 4,
                buttonTiltMaxSkew: 4,
                buttonCustomClass: 'font-h4',
                buttonLinkType: 'none',
                buttonContent: props.title,
              }}
            />
          </h3>

          <p className="p-small clrWhite" dangerouslySetInnerHTML={{ __html: props.content }} />

          <div>
            <div className="inner">
              {props.buttons.map((button: SectionJoinItUpButton, key: number) => {
                return (
                  <div key={key} className={button.isActive ? 'active' : ''}>
                    <Button
                      data={{
                        buttonHasTilt: true,
                        buttonShowTiltOn: 'always',
                        buttonTiltColor: button.tiltColor,
                        buttonTiltMaxRotation: 4,
                        buttonTiltMaxSkew: 4,
                        buttonCustomClass: 'p-small font-weight-bold',
                        buttonLinkType: 'internal',
                        buttonContent: button.content,
                        buttonLinkHref: button.link,
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JoinItUpSection;
