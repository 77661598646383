import { gsap } from 'gsap';
import { useLayoutEffect } from 'react';
import ScrollTrigger from 'gsap/ScrollTrigger';
import PartnerCarousel from '../../../Components/Carousels/PartnerCarousel';
import ImageContainer from '../../../Components/ImageContainers/ImageContainer';
gsap.registerPlugin(ScrollTrigger);

interface SectionPartnerItem {
  image: string;
  image_alt?: string;
}
interface ServicePartnerCarouselSectionData {
  partnerItems: SectionPartnerItem[];
  titleBackgroundColor: string;
}

const PartnerCarouselSection: React.FC<ServicePartnerCarouselSectionData> = ({
  partnerItems,
  titleBackgroundColor,
}) => {
  useLayoutEffect(() => {
    var scrollingTrigger: globalThis.ScrollTrigger = ScrollTrigger.create({
      trigger: '.partnerCarousel',
      start: 'top 0',
      end: '+=' + window.innerHeight * 2 + 'px',
      pin: true,
      pinSpacing: false,
    });

    return () => {
      scrollingTrigger.kill();
    };
  }, []);

  return (
    <section className="clientCarousel partnerCarousel">
      <div className="background"></div>

      <div className="outer">
        <div className="inner">
          <PartnerCarousel data={{ carouselHasArrows: true, carouselArrowColor: titleBackgroundColor }}>
            {partnerItems.map((item: SectionPartnerItem, key: number) => {
              return (
                <div key={key}>
                  <ImageContainer data={{ imageSource: item.image, imageAlt: item.image_alt, imageIsLazy: true }} />
                </div>
              );
            })}
          </PartnerCarousel>
        </div>
      </div>
    </section>
  );
};

export default PartnerCarouselSection;
