import './Footer.scss';
import Button from '../Button/Button';
import React, { useState, useEffect } from 'react';
import ImageContainer from '../ImageContainers/ImageContainer';
import { Router, useLocation } from 'react-router-dom';

const googlePartnerLogo: string = require('../../Images/googlePartner_logo.svg').default;
const bingPartnerLogo: string = require('../../Images/bingPartner_logo.svg').default;
const whatsAppIcon: string = require('../../Images/whatsApp_icon.svg').default;
const emailIcon: string = require('../../Images/email_icon.svg').default;

interface iFooterNavigationStyle {
  gridTemplateColumns?: string;
}



type footerType = 'full' | 'reduced';

const baseNavigationData: Array<any> = [
  {
    buttonHasTilt: false,
    buttonCustomClass: 'clrWhite font-small',
    buttonLinkType: 'external',
    buttonLinkHref: `/how-can-we-help/`,
    buttonContent: 'How can we help?',
  },
  {
    buttonHasTilt: false,
    buttonCustomClass: 'clrWhite font-small',
    buttonLinkType: 'external',
    buttonLinkHref: 'https://www.linkedin.com/company/2933120/',
    buttonContent: 'LinkedIn',
    buttonOpensInNewTab: true,
  },
  {
    buttonHasTilt: false,
    buttonCustomClass: 'clrWhite font-small',
    buttonLinkType: 'external',
    buttonLinkHref: 'https://www.glassdoor.co.uk/Overview/Working-at-addmustard-EI_IE1027262.11,21.htm',
    buttonContent: 'GlassDoor',
    buttonOpensInNewTab: true,
  },
];
const imageNavigationData: Array<any> = [
  {
    imageSource: bingPartnerLogo,
    imageAlt: 'Bing Partner Logo',
    imageHrefType: '',
    imageHref: '',
  },
  {
    imageSource: googlePartnerLogo,
    imageAlt: 'Google Partner Logo',
    imageHrefType: '',
    imageHref: '',
  },
];

const Footer: React.FC = () => {
  const [footerType, setFooterType] = useState<footerType>('full');
  const [currentWhatsAppIcon, setCurrentWhatsAppIcon] = useState<string>(whatsAppIcon);
  const [currentEmailIcon, setCurrentEmailIcon] = useState<string>(emailIcon);

  const location = useLocation();

  useEffect(() => {
    // execute on location change
    if(location.pathname == '/how-can-we-help/'){
      setFooterType('reduced');
    }else{
      setFooterType('full');
    }
  }, [location]);

  const footerNavigationData: Array<any> = [
    {
      buttonHasTilt: true,
      buttonShowTiltOn: 'always',
      buttonTiltColor: '#FFFFFF',
      buttonTiltMaxRotation: 3,
      buttonTiltMaxSkew: 3,
      buttonCustomClass: 'clrWhite contact-whatsapp',
      buttonLinkType: 'external',
      buttonLinkHref: 'https://wa.me/447457404143',
      buttonContent: 'WhatsApp',
      buttonIcon: currentWhatsAppIcon,
      buttonOpensInNewTab: true,
    },
    {
      buttonHasTilt: true,
      buttonShowTiltOn: 'always',
      buttonTiltColor: '#FFFFFF',
      buttonTiltMaxRotation: 3,
      buttonTiltMaxSkew: 3,
      buttonCustomClass: 'clrWhite',
      buttonLinkType: 'internal',
      buttonLinkHref: `/how-can-we-help/?openEmail=true`,
      buttonContent: 'Email',
      buttonIcon: currentEmailIcon,
    },
  ];
  const childrenCount: number = footerNavigationData.length;
  const navigationStyle: iFooterNavigationStyle = { gridTemplateColumns: 'repeat(' + childrenCount + ', 1fr)' };

  return (
    <section className="Footer">
      <div className="outer">
        <div className="inner">
          {footerType !== 'full' ? (
            <div className="upper" style={{ padding: '0px' }}></div>
          ) : (
            <div className="upper">
              <div className="inner">
                <nav>
                  <div className="inner" style={navigationStyle}>
                    {footerNavigationData.map((item: any, key: number) => {
                      return <Button data-lag="1.2" key={key} data={item} />;
                    })}
                  </div>
                </nav>
              </div>
            </div>
          )}

          <div className="lower">
            <div className="inner">
              <div className="left">
                <div className="inner">
                  {imageNavigationData.map((item: any, key: number) => {
                    return <ImageContainer key={key} data={item} />;
                  })}
                </div>
              </div>

              <div className="right">
                <div className="inner">
                  <nav>
                    <div className="inner">
                      {baseNavigationData.map((item: any, key: number) => {
                        return <Button key={key} data={item} />;
                      })}
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
