import React from 'react';
import '../WhatWeDoPage.scss';
import Button from '../../../Components/Button/Button';
import ImageContainer from '../../../Components/ImageContainers/ImageContainer';

const introSectionData: any = {
  backgroundImage: '../images/whatWeDoPage_introSectionImage_1.webp',
  title: 'What we do',
  copy: 'We get business. We create value in brands. For entrepreneurs. And those who think like entrepreneurs. Then we add some proper, grown-up marketing nous. To take your brand through every conceivable touch point, utilising world-class creativity, incisive data insight and mind-blowing customer technology. Ultimately what we do, is join everything up.',
};

const IntroSection: React.FC = () => {
  return (
    <div className="IntroSection">
      <div className="inner">
        <div className="topContainer">
          <div className="inner">
            <div className="backgroundImage">
              <div className="inner">
                <ImageContainer data={{ imageSource: introSectionData.backgroundImage, imageIsLazy: true }} />
              </div>
            </div>

            <div className="titleContainer">
              <div className="inner">
                <h1>
                  <Button
                    data={{
                      buttonHasTilt: true,
                      buttonTiltColor: '#13121C',
                      buttonShowTiltOn: 'always',
                      buttonTiltMaxRotation: 2,
                      buttonTiltMaxSkew: 2,
                      buttonContent: introSectionData.title,
                      buttonCustomClass: 'font-h1 clrMustard',
                      buttonLinkType: 'none',
                    }}
                  />
                </h1>
              </div>
            </div>
          </div>
        </div>

        <div className="bottomContainer">
          <div className="inner">
            <p className="font-xLarge">{introSectionData.copy}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default IntroSection;
