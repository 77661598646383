import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from '../Pages/HomePage/HomePage';
import Seo from '../Pages/ServicePage/MarketingChildren/Seo';
import ServiceBrand from '../Pages/ServicePage/ServiceBrand';
import WhatWeDoPage from '../Pages/WhatWeDoPage/WhatWeDoPage';
import Email from '../Pages/ServicePage/MarketingChildren/Email';
import ServiceMarketing from '../Pages/ServicePage/ServiceMarketing';
import ServiceTechnology from '../Pages/ServicePage/ServiceTechnology';
import Creative from '../Pages/ServicePage/MarketingChildren/Creative';
import UxDesign from '../Pages/ServicePage/TechnologyChildren/UxDesign';
import PaidMedia from '../Pages/ServicePage/MarketingChildren/PaidMedia';
import HowCanWeHelpPage from '../Pages/HowCanWeHelpPage/HowCanWeHelpPage';
import SocialMedia from '../Pages/ServicePage/MarketingChildren/SocialMedia';
import BrandPlanning from '../Pages/ServicePage/BrandChildren/BrandPlanning';
import DataAnalytics from '../Pages/ServicePage/MarketingChildren/DataAnalytics';
import MediaPlanning from '../Pages/ServicePage/MarketingChildren/MediaPlanning';
import BrandDevelopment from '../Pages/ServicePage/BrandChildren/BrandDevelopment';
import BrandCommunication from '../Pages/ServicePage/BrandChildren/BrandCommunication';
import TechnologyDevelopment from '../Pages/ServicePage/TechnologyChildren/TechnologyDevelopment';
import ConversionRateOptimisation from '../Pages/ServicePage/MarketingChildren/ConversionRateOptimisation';

const RouterHandler: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />

      <Route path="/what-we-do/" element={<WhatWeDoPage />} />

      <Route path="/how-can-we-help/" element={<HowCanWeHelpPage />} />

      <Route path="/marketing/" element={<ServiceMarketing />} />

      <Route path="/marketing/conversion-rate-optimisation/" element={<ConversionRateOptimisation />} />

      <Route path="/marketing/data-analytics/" element={<DataAnalytics />} />

      <Route path="/marketing/media-planning/" element={<MediaPlanning />} />

      <Route path="/marketing/paid-media/" element={<PaidMedia />} />

      <Route path="/marketing/social-media/" element={<SocialMedia />} />

      <Route path="/marketing/creative/" element={<Creative />} />

      <Route path="/marketing/email/" element={<Email />} />

      <Route path="/marketing/seo/" element={<Seo />} />

      <Route path="/brand/" element={<ServiceBrand />} />

      <Route path="/brand/brand-planning/" element={<BrandPlanning />} />

      <Route path="/brand/brand-communication/" element={<BrandCommunication />} />

      <Route path="/brand/brand-development/" element={<BrandDevelopment />} />

      <Route path="/technology/" element={<ServiceTechnology />} />

      <Route path="/technology/ux-design/" element={<UxDesign />} />

      <Route path="/technology/technology-development/" element={<TechnologyDevelopment />} />
    </Routes>
  );
};

export default RouterHandler;
